import {cartsHaveLoadedSelector} from '../../data/carts.js'
import {
  hasOrdersCreatedSelector,
  orderCountsHaveLoadedSelector,
} from '../../data/orderCounts.js'
import {shippersHaveLoadedSelector} from '../../data/shippers.js'
import {warehousesHaveLoadedSelector} from '../../data/warehouses.js'
import {packingListsHaveLoadedSelector} from '../../data/packingLists.js'

export const DASHBOARD_URI_COMPONENT = 'dashboard'

export function hasDashboardLoadedSelector(state) {
  const warehousesHaveLoaded = warehousesHaveLoadedSelector(state)
  const packingListsHaveLoaded = packingListsHaveLoadedSelector(state)
  const cartsHaveLoaded = cartsHaveLoadedSelector(state)
  const shippersHaveLoaded = shippersHaveLoadedSelector(state)
  const orderCountsHaveLoaded = orderCountsHaveLoadedSelector(state)

  return (
    warehousesHaveLoaded &&
    packingListsHaveLoaded &&
    cartsHaveLoaded &&
    shippersHaveLoaded &&
    orderCountsHaveLoaded
  )
}

export function hasSetUpEnoughSelector(state) {
  const hasOrdersCreated = hasOrdersCreatedSelector(state)

  return hasOrdersCreated
}
