export const SETTINGS = 'settings'

export const ACCOUNTING = 'accounting'
export const API_KEYS = 'api_key'
export const BARCODE_SCANNERS = 'barcode_scanners'
export const BILLING = 'billing'
export const GENERAL = 'general'
export const PACKING_LISTS = 'profiles'
export const PAYMENTS = 'payments'
export const PRESETS = 'presets'
export const PRINTING = 'printing'
export const REPORTS = 'reports'
export const RETURN_ORDER_CODES = 'return_order_codes'
export const RULES = 'rules'
export const SALES_CHANNELS = 'saleschannels'
export const SCALES = 'scales'
export const SHIPPERS = 'shipper'
export const SHIPPING_INSURANCE = 'shipping_insurance'
export const SUPPLIERS = 'supplier'
export const THREE_P_L = '3PL'
export const USERS = 'user'
export const WAREHOUSES = 'warehouse'

export const NEW_ID = 'new'
export const EDIT = 'edit'

export const ACCOUNTING_SETTINGS_LINK = `#/${SETTINGS}/${ACCOUNTING}`
export const USER_SETTINGS_LINK = `#/${SETTINGS}/${USERS}`
export const SALES_CHANNEL_SETTINGS_LINK = `#/${SETTINGS}/${SALES_CHANNELS}`
export const WAREHOUSE_SETTINGS_LINK = `#/${SETTINGS}/${WAREHOUSES}`
export const SUPPLIER_SETTINGS_LINK = `#/${SETTINGS}/${SUPPLIERS}`
export const PACKING_LIST_SETTINGS_LINK = `#/${SETTINGS}/${PACKING_LISTS}`
