import {createSelector} from 'reselect'

import {formsSelector, getState, setForm, updateForm} from '../../../store.js'
import {NEW_ID} from '../../../common/constants/index.js'
import {isPositiveNumeric, isPresent} from '../../../common/utils.js'
import {companyIDSelector, companySelector} from '../../../data/company.js'
import {
  getPackingListLogoURL,
  getPackingLists,
  packingListSelector,
} from '../../../data/packingLists.js'
import abode from '../../../common/abode.js'
import api from '../../../common/api.js'
import {navigate} from '../../../common/location.js'
import {locationSelector} from '../../../redux/selectors/ui/location.js'
import {
  PACKING_LIST_SETTINGS_LINK,
  PACKING_LISTS,
  SETTINGS,
} from '../../../common/constants/SettingsPanels.js'

export const SETTINGS_PACKING_LISTS = 'settings_packing_lists'

export const PROFILES_GENERAL = 'general'
export const PROFILES_PACKING_LIST = 'packing_list'
export const PROFILES_RETURN_ORDER = 'return_order'
export const PROFILES_SHIP_FROM_ALIAS = 'ship_from_alias'
export const PROFILES_BRANDED_TRACKING = 'branded_tracking_page'

const initialForm = {
  name: '',
  customer_notes_label: '',
  custom_text: '',
  email: '',
  phone: '',
  website: '',
  footer: '',
  is_default: false,
  original_is_default: false,
  logo_hash: '',
  return_email_subject: '',
  return_email_content: '',
  ship_from_alias_name: '',
  hasBrandedTracking: false,
  branded_tracking__phone: '',
  branded_tracking__email: '',
  branded_tracking__return_policy_url: '',
  branded_tracking__additional_info: '',
  branded_tracking__show_images: true,
  branded_tracking__show_prices: true,
  branded_tracking__show_ordoro_product_name: false,
  branded_tracking__show_zero_quantity_lines: true,
  branded_tracking__social_links: [],
  branded_tracking__logo_hash: null,
  isSaving: false,
  isUploading: false,
  serverError: null,
}

export function setupPackingListsForm() {
  return {
    formName: SETTINGS_PACKING_LISTS,
    initialForm,
  }
}

export function packingListsFormSelector(state) {
  return (
    formsSelector(state)[SETTINGS_PACKING_LISTS] ||
    packingListsFormSelector.default
  )
}
packingListsFormSelector.default = {}

export const errorsSelector = createSelector(
  packingListsFormSelector,
  ({name, hasBrandedTracking, branded_tracking__email}) => {
    const errors = {}

    if (!isPresent(name)) {
      errors.name = 'Name is required'
      errors.preventSave = true
    }

    if (hasBrandedTracking && !isPresent(branded_tracking__email)) {
      errors.branded_tracking__email = 'Email is required'
      errors.preventSave = true
    }

    return errors
  },
)

export const settingsPackingListsIDSelector = createSelector(
  locationSelector,
  ({pathComponents}) => {
    if (
      pathComponents[0] === SETTINGS &&
      pathComponents[1] === PACKING_LISTS &&
      isPositiveNumeric(pathComponents[3])
    ) {
      return Number(pathComponents[3])
    }

    return null
  },
)

export const settingsPackingListsAddSelector = createSelector(
  locationSelector,
  ({pathComponents}) => {
    return (
      pathComponents[0] === SETTINGS &&
      pathComponents[1] === PACKING_LISTS &&
      pathComponents[3] === NEW_ID
    )
  },
)

export function selectedProfileTabSelector(state) {
  const {pathComponents} = locationSelector(state)

  return pathComponents[2] || PROFILES_GENERAL
}

export const packingListsHashSelector = createSelector(
  settingsPackingListsIDSelector,
  settingsPackingListsAddSelector,
  selectedProfileTabSelector,
  (packingListID, isNew, selectedProfileTab) => {
    if (isNew) {
      return `${PACKING_LIST_SETTINGS_LINK}/${selectedProfileTab}/${NEW_ID}`
    }

    if (packingListID) {
      return `${PACKING_LIST_SETTINGS_LINK}/${selectedProfileTab}/${packingListID}`
    }

    return `${PACKING_LIST_SETTINGS_LINK}/${PROFILES_GENERAL}`
  },
)

export function currentPackingListLogoURLSelector(state) {
  const packingListID = settingsPackingListsIDSelector(state)
  const packingList = packingListSelector(state, {packingListID})
  const {logo_hash} = packingListsFormSelector(state)

  const companyID = companyIDSelector(state)

  return getPackingListLogoURL({...packingList, logo_hash}, companyID)
}

export function goToPackingListSettings() {
  return navigate([SETTINGS, PACKING_LISTS, PROFILES_GENERAL])
}

export function setupForEdit() {
  const editID = settingsPackingListsIDSelector(getState())
  const packingList = packingListSelector(getState(), {packingListID: editID})

  if (!packingList) {
    return
  }

  setForm(SETTINGS_PACKING_LISTS, {
    ...initialForm,
    name: packingList.name,
    customer_notes_label: packingList.customer_notes_label || '',
    custom_text: packingList.custom_text || '',
    email: packingList.email || '',
    phone: packingList.phone || '',
    website: packingList.website || '',
    footer: packingList.footer || '',
    is_default: packingList.is_default || false,
    original_is_default: packingList.is_default || false,
    _linklogo: packingList._linklogo,
    logo_hash: packingList.logo_hash,
    return_email_subject: packingList.return_email_subject || '',
    return_email_content: packingList.return_email_content || '',
    ship_from_alias_name: packingList.ship_from_alias_name || '',
    hasBrandedTracking: !!packingList.branded_tracking,
    branded_tracking__phone: packingList.branded_tracking?.phone || '',
    branded_tracking__email: packingList.branded_tracking?.email || '',
    branded_tracking__return_policy_url:
      packingList.branded_tracking?.return_policy_url || '',
    branded_tracking__additional_info:
      packingList.branded_tracking?.additional_info || '',
    branded_tracking__social_links:
      packingList.branded_tracking?.social_links || [],
    branded_tracking__show_images:
      packingList.branded_tracking?.show_images || true,
    branded_tracking__show_prices:
      packingList.branded_tracking?.show_prices || true,
    branded_tracking__show_ordoro_product_name:
      packingList.branded_tracking?.show_ordoro_product_name || false,
    branded_tracking__show_zero_quantity_lines:
      packingList.branded_tracking?.show_zero_quantity_lines || true,
    branded_tracking__logo_hash:
      packingList.branded_tracking?.logo_hash || null,
  })
}

export function setupForAdding() {
  const company = companySelector(getState())
  const form = {...initialForm}

  if (company.email) {
    form.email = company.email
  }

  if (company.phone) {
    form.phone = company.phone
  }

  setForm(SETTINGS_PACKING_LISTS, form)
}

export function resetPackingListsForm() {
  setForm(SETTINGS_PACKING_LISTS, initialForm)
}

export function updatePackingListsForm(...args) {
  updateForm(SETTINGS_PACKING_LISTS, ...args)
}

export function toggleBrandedTracking() {
  const form = packingListsFormSelector(getState())
  const hasBrandedTracking = !form.hasBrandedTracking

  const updates = {
    hasBrandedTracking,
  }

  if (hasBrandedTracking) {
    if (!form.branded_tracking__email && form.email) {
      updates.branded_tracking__email = form.email
    }

    if (!form.branded_tracking__phone && form.phone) {
      updates.branded_tracking__phone = form.phone
    }

    if (!form.branded_tracking__logo_hash && form.logo_hash) {
      updates.branded_tracking__logo_hash = form.logo_hash
    }
  }

  updatePackingListsForm(updates)
}

export function getLogoData(dataURL) {
  if (!dataURL) {
    return null
  }

  const match = dataURL.match(/^data:(image\/(?:jpg|jpeg|png|gif));base64,(.*)/)

  if (match && match.length === 3) {
    return {mime_type: match[1], data: match[2]}
  }

  return null
}

export async function savePackingList() {
  const {
    name,
    customer_notes_label,
    custom_text,
    email,
    phone,
    website,
    footer,
    is_default,
    logo_hash,
    return_email_subject,
    return_email_content,
    ship_from_alias_name,
    hasBrandedTracking,
    branded_tracking__phone,
    branded_tracking__email,
    branded_tracking__return_policy_url,
    branded_tracking__additional_info,
    branded_tracking__social_links,
    branded_tracking__show_images,
    branded_tracking__show_prices,
    branded_tracking__show_ordoro_product_name,
    branded_tracking__show_zero_quantity_lines,
    branded_tracking__logo_hash,
  } = packingListsFormSelector(getState())
  const id = settingsPackingListsIDSelector(getState())

  const params = {
    name,
    customer_notes_label,
    custom_text,
    email,
    phone,
    website,
    footer,
    logo_hash,
    return_email_subject,
    return_email_content,
    ship_from_alias_name,
  }

  if (hasBrandedTracking) {
    params.branded_tracking = {
      phone: branded_tracking__phone,
      email: branded_tracking__email,
      return_policy_url: branded_tracking__return_policy_url,
      additional_info: branded_tracking__additional_info,
      social_links: branded_tracking__social_links,
      show_images: branded_tracking__show_images,
      show_prices: branded_tracking__show_prices,
      show_ordoro_product_name: branded_tracking__show_ordoro_product_name,
      show_zero_quantity_lines: branded_tracking__show_zero_quantity_lines,
      logo_hash: branded_tracking__logo_hash,
    }
  } else {
    params.branded_tracking = null
  }

  updatePackingListsForm({isSaving: true, serverError: null})

  try {
    if (id) {
      await api.put(`/packing_list/${id}/`, params)

      if (is_default) {
        await api.post(`/packing_list/${id}/make_default/`)
      }
    } else {
      const {json} = await api.post('/packing_list/', params)

      if (is_default) {
        await api.post(`/packing_list/${json.id}/make_default/`)
      }
    }

    goToPackingListSettings()

    await getPackingLists()
  } catch (err) {
    updatePackingListsForm({serverError: err.error_message || err.message})
  }

  updatePackingListsForm({isSaving: false})
}

export function packingListLogoInfoSelector(state, {property}) {
  const form = packingListsFormSelector(state)
  const companyID = companyIDSelector(state)

  const input = {logo_hash: form[property]}

  if (property === 'logo_hash') {
    input._linklogo = form._linklogo
  }

  return {
    logoURL: getPackingListLogoURL(input, companyID),
    isUploading: form[`${property}__isUploading`],
    error: form[`${property}__error`],
  }
}

export function loadFileInForm(file) {
  const formData = new FormData()

  formData.append('image', file)

  return formData
}

export async function handleLogoFile(file, property) {
  updatePackingListsForm({
    [`${property}__isUploading`]: true,
    [`${property}__error`]: null,
  })

  try {
    const formData = loadFileInForm(file)

    const imageInfo = await abode.json('/image/file', {
      method: 'POST',
      body: formData,
    })

    if (imageInfo.error_message) {
      throw new Error(imageInfo.error_message)
    }

    updatePackingListsForm({[property]: imageInfo.hash})
  } catch (err) {
    updatePackingListsForm({
      [`${property}__error`]: err.error_message || err.message,
    })
  }

  updatePackingListsForm({[`${property}__isUploading`]: false})
}
