import {useEffect, useState} from 'react'
import {useSelector as useRealSelector} from 'react-redux'
import isFunction from 'lodash/isFunction.js'

import {
  setForm as reduxSetForm,
  updateForm as reduxUpdateForm,
  removeForm as reduxRemoveForm,
  setFormValue as reduxSetFormValue,
  removeFormValue as reduxRemoveFormValue,
  updateFormObject as reduxUpdateFormObject,
  addFormArrayElement as reduxAddFormArrayElement,
  removeFormArrayElement as reduxRemoveFormArrayElement,
  updateForms as reduxUpdateForms,
} from './redux/actions/ui/forms.js'
import {
  formsSelector as reduxFormsSelector,
  formSelector as reduxFormSelector,
} from './redux/selectors/ui/forms.js'
import {useSelector as useZtoreSelector} from './ztore.js'

export function getStore() {
  return window.store
}

export function getState() {
  return window.store ? window.store.getState() : null
}

export function dispatch(action) {
  return window.store ? window.store.dispatch(action) : null
}

export function setForm(key, form, meta) {
  dispatch(reduxSetForm(key, form, meta))
}

export function updateForm(key, form, meta) {
  dispatch(reduxUpdateForm(key, form, meta))
}

export function setFormValue(key, path, value, meta) {
  dispatch(reduxSetFormValue(key, path, value, meta))
}

export function removeFormValue(key, path, meta) {
  dispatch(reduxRemoveFormValue(key, path, meta))
}

export function updateFormObject(key, path, value, meta) {
  dispatch(reduxUpdateFormObject(key, path, value, meta))
}

export function addFormArrayElement(key, path, element, meta) {
  dispatch(reduxAddFormArrayElement(key, path, element, meta))
}

export function removeFormArrayElement(key, path, index, meta) {
  dispatch(reduxRemoveFormArrayElement(key, path, index, meta))
}

export function updateForms(forms) {
  dispatch(reduxUpdateForms(forms))
}

export function removeForm(key) {
  dispatch(reduxRemoveForm(key))
}

export const formsSelector = reduxFormsSelector

export function useSelector(...args) {
  if (window.use_ztore) {
    return useZtoreSelector(...args)
  }

  return useRealSelector(...args)
}

export function formSelector(state, {formName}) {
  return reduxFormSelector(state, {key: formName})
}

export function useForm(formName) {
  return useSelector((state) => formsSelector(state)[formName])
}

// Putting reference counting here instead of in redux store since we have no way of knowing who
// cares about the existence of a form unless there is explicit usage. We have explicit usage in
// `formConnectAuto()`, so we count references here.
// Reference counting isn't required for the 99% of forms that are used by a single component.
// For forms that are used by multiple components, the form is never removed (like RMA list).
// We have reference counting here because a specific AbodeForm form instance is used in multiple
// spots at the same time (in the side panel and in a modal).
// If this reference counting doesn't work out well, we need to stop removing that particular form.
const referenceCounts = {}

export function useAutoForm(formName, initialForm = {}) {
  const [localInitialForm] = useState(() => {
    if (isFunction(initialForm)) {
      return initialForm(getState())
    } else {
      return initialForm
    }
  })

  useEffect(() => {
    referenceCounts[formName] = (referenceCounts[formName] || 0) + 1

    setForm(formName, localInitialForm)

    return () => {
      referenceCounts[formName] = (referenceCounts[formName] || 0) - 1

      if (referenceCounts[formName] < 1) {
        delete referenceCounts[formName]

        removeForm(formName)
      }
    }
  }, [])

  const form = useForm(formName)

  return form || localInitialForm
}

export function onlyIfAutoForm(
  Component,
  formSetupFunction,
  ElseComponent = null,
) {
  return function OnlyIfAutoForm(props) {
    const {formName, initialForm} = formSetupFunction(props)

    useAutoForm(formName, initialForm)

    const form = useForm(formName)

    if (!form) {
      return ElseComponent ? (
        <ElseComponent formName={formName} {...props} />
      ) : null
    }

    return <Component formName={formName} form={form} {...props} />
  }
}

export function onlyIf(Component, conditionalSelector, ElseComponent = null) {
  return function OnlyIf(props) {
    const isTrue = useSelector((state) => conditionalSelector(state, props))

    if (!isTrue) {
      return ElseComponent ? <ElseComponent {...props} /> : null
    }

    return <Component {...props} />
  }
}

export function onlyIfForm(Component, formSelector, ElseComponent = null) {
  return function OnlyIfForm(props) {
    const form = useSelector(formSelector)

    if (!form) {
      return ElseComponent ? <ElseComponent {...props} /> : null
    }

    return <Component form={form} {...props} />
  }
}
