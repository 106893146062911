import keyBy from 'lodash/keyBy.js'
import sortBy from 'lodash/sortBy.js'
import {createSelector} from 'reselect'

import {fetchAllAPI} from '../common/fetchAll.js'
import {showGlobalError} from '../ordoro/GlobalErrorMessage.js'
import {formsSelector, setForm, updateForm} from '../store.js'
import {companyIDSelector} from './company.js'

export const PACKING_LISTS = 'packing_lists'

export function setPackingLists(packingLists) {
  setForm(PACKING_LISTS, keyBy(packingLists, 'id'))
}

export function setPackingList(packingList) {
  updateForm(PACKING_LISTS, {[packingList.id]: packingList})
}

export async function getPackingLists() {
  try {
    const packingLists = await fetchAllAPI('/packing_list/', 'packing_list')

    setPackingLists(packingLists)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting packing lists.',
        details: err.message || err.error_message,
      },
      err,
    )

    setPackingLists([])
  }
}

export const packingListsSelector = createSelector(
  formsSelector,
  (forms) => forms[PACKING_LISTS] || packingListsSelector.default,
)
packingListsSelector.default = {}

export function packingListsHaveLoadedSelector(state) {
  return !!formsSelector(state)[PACKING_LISTS]
}

export function packingListSelector(state, {packingListID}) {
  return packingListsSelector(state)[packingListID]
}

export function getPackingListName(packingList) {
  return packingList ? packingList.name : ''
}

export function packingListNameSelector(state, {packingListID}) {
  return getPackingListName(packingListSelector(state, {packingListID}))
}

export const defaultPackingListSelector = createSelector(
  packingListsSelector,
  (packingLists) =>
    Object.values(packingLists).find((packingList) => packingList.is_default),
)

export const defaultPackingListIDSelector = createSelector(
  defaultPackingListSelector,
  (packingList) => (packingList ? packingList.id : null),
)

export const sortedPackingListsByIDSelector = createSelector(
  packingListsSelector,
  (packingLists) =>
    sortBy(packingLists, 'id').filter(
      (packingList) => !packingList.archive_date,
    ),
)

export const atLeastOneLogoHasBeenSetupSelector = createSelector(
  sortedPackingListsByIDSelector,
  (packingLists) =>
    !!packingLists.find((packingList) => getPackingListLogoURL(packingList)),
)

export const firstPackingListSelector = createSelector(
  sortedPackingListsByIDSelector,
  (packingLists) => packingLists[0],
)

export function getPackingListLogoURL({logo_hash, _linklogo}, companyID) {
  if (logo_hash) {
    return `${window.ORDORO_BOOTSTRAP.env.PAPPY_ENV_ABODE_URL}/image/${companyID}/${logo_hash}?width=300&type=png`
  }

  if (_linklogo) {
    return _linklogo
  }

  return null
}

export const packingListOptionsSelector = createSelector(
  sortedPackingListsByIDSelector,
  (packingLists) =>
    packingLists.map((packingList) => ({
      value: packingList.id,
      display: packingList.name,
    })),
)

export function packingListLogoURLSelector(state, {packingListID}) {
  const packingList = packingListSelector(state, {packingListID})
  const companyID = companyIDSelector(state)

  if (!packingList) {
    return null
  }

  return getPackingListLogoURL(packingList, companyID)
}

export function packingListsWithLogoURLsSelector(state) {
  const packingLists = sortedPackingListsByIDSelector(state)
  const companyID = companyIDSelector(state)

  return packingLists.map((packingList) => ({
    ...packingList,
    logoURL: getPackingListLogoURL(packingList, companyID),
  }))
}
