import round from 'lodash/round.js'
import {createSelector} from 'reselect'
import omitBy from 'lodash/omitBy.js'
import compact from 'lodash/compact.js'
import get from 'lodash/get.js'
import reduce from 'lodash/reduce.js'
import mergeWith from 'lodash/mergeWith.js'
import isArray from 'lodash/isArray.js'
import snakeCase from 'lodash/snakeCase.js'
import pick from 'lodash/pick.js'

import {formSelector, getState, updateForm} from '../../store.js'
import {isNonZeroPositiveNumeric, isEmptyValue} from '../../common/utils.js'
import {
  STANDARD_LAYOUT,
  KIT_VIEW_PARENTS,
  ABODE_FORM_DEFAULTS,
} from '../../common/constants/Abode.js'
import {formatV3APIURL} from '../../common/apiverson.js'
import {stringify} from '../../common/querystring.js'
import {abodeRenderPOST, formatAbodeURL} from '../../common/abode.js'
import {
  canUseLogosOnLabelsSelector,
  usesInventorySelector,
  useOrdoroProductNameInAbodePackingListsSelector,
  useKittingSelector,
  companySelector,
  useAbodeRenderLabelsSelector,
} from '../../data/company.js'
import {
  allOrdersSelector,
  defaultPackingListIDFromOrderNumbersSelector,
  orderSelector,
} from '../../data/orders.js'
import {printConfigSelector} from '../../data/printConfigs.js'
import {packingListsSelector} from '../../data/packingLists.js'
import {defaultPackingListIDFromRMAsSelector} from '../../redux/selectors/data/returnOrders.js'
import {
  sendToPrintNode,
  startSendingToPrintNode,
} from '../../common/components/Button/ButtonSendToPrinter.js'
import {LAYOUT_LABEL, layoutsByTypeSortedByName} from '../../data/layout.js'
import html from '../../common/html.js'

export const LABEL_VIEW_ABODE_FORM = 'LABEL_VIEW_ABODE_FORM'
export const RMA_LABEL_VIEW_ABODE_FORM = 'RMA_LABEL_VIEW_ABODE_FORM'
export const BATCH_LABEL_VIEW_ABODE_FORM = 'BATCH_LABEL_VIEW_ABODE_FORM'
export const BATCH_PICK_PACK_ABODE_FORM = 'BATCH_PICK_PACK_ABODE_FORM'

export function labelViewFormAbodeSelector(state, {formName}) {
  return formSelector(state, {formName}) || labelViewFormAbodeSelector.default
}
labelViewFormAbodeSelector.default = {}

export function atLeastOneSplitOrderSelector(state, {orderNumbers}) {
  const orders = allOrdersSelector(state)

  return orderNumbers.reduce(
    (prev, orderNumber) =>
      prev || get(orders, [orderNumber, 'parent_order_number'], null) !== null,
    false,
  )
}

export const pickOrPackIsSelectedSelector = createSelector(
  labelViewFormAbodeSelector,
  (abode) => !!(abode.showPickList || abode.showPackingList),
)

export const abodeCustomLayoutParamsSelector = createSelector(
  labelViewFormAbodeSelector,
  (abode) => {
    if (!abode.useCustomLayoutSettings) {
      return {}
    }
    return omitBy(
      {
        label_top_margin: abode.label_top_margin,
        label_left_margin: abode.label_left_margin,
        label_width: abode.label_width,
        label_height: abode.label_height,
      },
      isEmptyValue,
    )
  },
)

export function printConfigLayoutSelector(state, {formName}) {
  const {printConfigID, showFullPage, size} = labelViewFormAbodeSelector(
    state,
    {
      formName,
    },
  )

  const layout = get(
    printConfigSelector(state, {printConfigID}),
    'settings.layout',
  )

  if (
    !layout ||
    printConfigID === STANDARD_LAYOUT ||
    layout === STANDARD_LAYOUT
  ) {
    if (size === 'desktop') {
      return showFullPage ? 'fullpage' : 'two_per_page'
    }
    return size
  }

  return layout
}

export function allowedParamsSelector(
  state,
  {formName, docTypes, includeSplitQuantitiesCheckbox},
) {
  const {printConfigID, showPackingList, showPickList} =
    labelViewFormAbodeSelector(state, {formName})
  const useAbodeRenderLabels = useAbodeRenderLabelsSelector(state)
  const printConfigLayout = printConfigLayoutSelector(state, {formName})
  const useKitting = useKittingSelector(state)
  const canUseLogosOnLabels = canUseLogosOnLabelsSelector(state)
  const hasLayouts = !!layoutsByTypeSortedByName(state)[LAYOUT_LABEL]?.length
  const includeLabel = docTypes.includes('label')
  const includePackingList = docTypes.includes('pack')
  const includePickList = docTypes.includes('pick')

  const standardAllowedParams = {
    printConfigID: true,
    layout: true,
    templateID: true,
    showFullPage: true,
    breakPageOnOrder: true,
    size: true,
    layout_id: false,

    showOrderTray:
      (includePackingList && showPackingList) ||
      (includePickList && showPickList),
    showLogoOnLabel: includeLabel && canUseLogosOnLabels,
    labelPosition: includeLabel && printConfigLayout === 'two_per_page',
    useCustomLayoutSettings: includeLabel,

    showPackingList: includePackingList,
    packingListLayout: includePackingList,
    packingListSort: includePackingList,
    showPrice: includePackingList,
    showBarcode: includePackingList,
    showPackingListImages: includePackingList,
    packingListKitViewMode: includePackingList && useKitting,
    showSiblingOrderQuantities: !!(
      includePackingList && includeSplitQuantitiesCheckbox
    ),
    showZeroQuantityLines: includePackingList,
    highlight_pack_line_qty: includePackingList,
    showShippingInfo: includePackingList,
    showWarehouseLocation: includePackingList,
    showBillTo: includePackingList,
    showPackSerialNumbers: includePackingList,
    showCustomerNotes: includePackingList,
    show_packing_list_details: includePackingList,
    show_pack_sku_barcode: includePackingList,

    showPickList: includePickList,
    pickListPrintLayout: includePickList,
    pickListSort: includePickList,
    showPickListImages: includePickList,
    showPickListTotalItems: includePickList,
    showPickListTotalSKUs: includePickList,
    showPickListTotalOrders: includePickList,
    highlight_pick_line_qty: includePickList,
    show_pick_list_poh: includePickList,
    show_pick_sku_barcode: includePickList,
    showPickSerialNumbers: includePickList,
    pickListKitViewMode: includePickList && useKitting,
  }

  if (printConfigID === STANDARD_LAYOUT) {
    return standardAllowedParams
  }

  const printConfig = printConfigSelector(state, {printConfigID})

  if (!printConfig) {
    return standardAllowedParams
  }

  const allowedParams = printConfig.settings.allowed_params || []

  // special hidden dropdown unless we want to see it
  if (
    allowedParams.includes('layout_id') &&
    useAbodeRenderLabels &&
    hasLayouts
  ) {
    standardAllowedParams.layout_id = true

    if (allowedParams.length === 1) {
      // we want to show all other params in this case
      return standardAllowedParams
    }
  }

  return reduce(
    standardAllowedParams,
    (prev, isAllowed, param) => {
      prev[param] = isAllowed === false ? false : allowedParams.includes(param)

      return prev
    },
    {},
  )
}

function allowedParamsForOrderNumbersSelector(
  state,
  {formName, orderNumbers, docTypes},
) {
  const includeSplitQuantitiesCheckbox = orderNumbers
    ? atLeastOneSplitOrderSelector(state, {
        orderNumbers,
      })
    : false

  return allowedParamsSelector(state, {
    formName,
    docTypes,
    includeSplitQuantitiesCheckbox,
  })
}

export function defaultsSelector(state, {formName}) {
  const {printConfigID} = labelViewFormAbodeSelector(state, {formName})

  const defaults = {...ABODE_FORM_DEFAULTS}

  if (printConfigID === STANDARD_LAYOUT) {
    return defaults
  }

  const printConfig = printConfigSelector(state, {printConfigID})

  if (!printConfig) {
    return defaults
  }

  return {
    ...defaults,
    ...printConfig.settings.defaults,
  }
}

export const abodeSharedParamsSelector = createSelector(
  labelViewFormAbodeSelector,
  (state, props) => props.utcOffset,
  (state, {templateIDs}) => templateIDs,
  printConfigLayoutSelector,
  defaultsSelector,
  allowedParamsForOrderNumbersSelector,
  (form, utcOffset, templateIDs, layout, defaults, allowedParams) => {
    const {templateID, breakPageOnOrder} = form

    const params = {
      layout,
      utcOffset,
      breakPageOnOrder,
    }

    // If templateID is not zero then that is the packing_list_id to use for all items (orders)
    // Otherwise if we passed in an array of packing_list_ids then use them. Their count should
    // match the item count
    if (templateID) {
      // If you pass in a single packing list id abode will expand it out to match the item count
      params.template = [templateID]
    } else if (templateIDs) {
      // If all the template IDs are the same then we can just send one template ID
      // abode will expand it out to match the item count
      const firstTemplateID = templateIDs[0]
      const allTheSame = templateIDs.reduce((prev, tID) => {
        if (prev === false) {
          return prev
        }

        if (firstTemplateID !== tID) {
          prev = false
        }

        return prev
      }, true)

      params.template =
        allTheSame && firstTemplateID ? [firstTemplateID] : templateIDs
    }

    const optionalParams = ['layout_id'].reduce((prev, param) => {
      const value = allowedParams[param] ? form[param] : defaults[param]

      if (value) {
        prev[param] = value
      }

      return prev
    }, {})

    return {...params, ...optionalParams}
  },
)

export const abodeLabelParamsSelector = createSelector(
  (state, {docTypes}) => docTypes,
  labelViewFormAbodeSelector,
  canUseLogosOnLabelsSelector,
  abodeCustomLayoutParamsSelector,
  (docTypes, form, canUseLogosOnLabels, abodeCustomLayoutParams) => {
    if (!docTypes.includes('label')) {
      return {}
    }

    const {showLogoOnLabel, labelPosition} = form

    return {
      showLogoOnLabel: canUseLogosOnLabels && showLogoOnLabel,
      labelLeftAlways: labelPosition === 'left',
      labelRightAlways: labelPosition === 'right',
      ...abodeCustomLayoutParams,
    }
  },
)

export const abodePickPackParamsSelector = createSelector(
  (state, {docTypes}) => docTypes,
  usesInventorySelector,
  useOrdoroProductNameInAbodePackingListsSelector,
  labelViewFormAbodeSelector,
  defaultsSelector,
  allowedParamsForOrderNumbersSelector,
  (
    docTypes,
    usesInventory,
    showOrdoroProductName,
    form,
    defaults,
    allowedParams,
  ) => {
    if (!docTypes.includes('pack') && !docTypes.includes('pick')) {
      return {}
    }

    const showPackingList = allowedParams.showPackingList
      ? form.showPackingList
      : defaults.showPackingList

    const showPickList = allowedParams.showPickList
      ? form.showPickList
      : defaults.showPickList

    const optionalParams = [
      ...(showPackingList
        ? [
            'showPrice',
            'showBarcode',
            'showPackingListImages',
            'packingListSort',
            'showSiblingOrderQuantities',
            'showZeroQuantityLines',
            'highlight_pack_line_qty',
            'showShippingInfo',
            'showWarehouseLocation',
            'showBillTo',
            'showPackSerialNumbers',
            'showCustomerNotes',
            'packingListLayout',
            'showOrderTray',
            'show_packing_list_details',
            'show_pack_sku_barcode',
          ]
        : []),
      ...(showPickList
        ? [
            'showPickListImages',
            'showPickListTotalItems',
            'showPickListTotalSKUs',
            'showPickListTotalOrders',
            'highlight_pick_line_qty',
            'show_pick_list_poh',
            'showPickSerialNumbers',
            'pickListSort',
            'pickListLayout',
            'showOrderTray',
            'show_pick_sku_barcode',
          ]
        : []),
    ].reduce((prev, param) => {
      const value = allowedParams[param] ? form[param] : defaults[param]

      if (value) {
        prev[param] = value
      }

      return prev
    }, {})

    const packingListKitViewMode = allowedParams.packingListKitViewMode
      ? form.packingListKitViewMode
      : defaults.packingListKitViewMode
    const pickListKitViewMode = allowedParams.pickListKitViewMode
      ? form.pickListKitViewMode
      : defaults.pickListKitViewMode

    return {
      packingListKitViewMode:
        showPackingList && packingListKitViewMode !== KIT_VIEW_PARENTS
          ? packingListKitViewMode
          : undefined,
      pickListKitViewMode:
        showPickList && pickListKitViewMode !== KIT_VIEW_PARENTS
          ? pickListKitViewMode
          : undefined,
      showOrdoroProductName: showOrdoroProductName || undefined,
      docs: compact([
        showPackingList ? 'packing' : undefined,
        showPickList ? 'pick' : undefined,
      ]),
      ...optionalParams,
    }
  },
)

function removeFalseValues(obj) {
  return omitBy(obj, (value) => isEmptyValue(value) || value === false)
}

export const abodeParamsSelector = createSelector(
  (state, {orderNumbers}) => orderNumbers,
  (state, {referenceIDs}) => referenceIDs,
  (state, {docs}) => docs,
  abodeSharedParamsSelector,
  abodeLabelParamsSelector,
  abodePickPackParamsSelector,
  (
    orderNumbers,
    referenceIDs,
    docs,
    abodeSharedParams,
    abodeLabelParams,
    abodePickPackParams,
  ) =>
    removeFalseValues(
      mergeWith(
        {
          ...(orderNumbers ? {order: orderNumbers} : undefined),
          ...(referenceIDs ? {referenceID: referenceIDs} : undefined),
          docs,
        },
        abodeSharedParams,
        abodeLabelParams,
        abodePickPackParams,
        (objValue, srcValue) => {
          if (isArray(objValue)) {
            return objValue.concat(srcValue)
          }
        },
      ),
    ),
)

export const abodeLinkSelector = function (
  state,
  {formName, orderNumbers, labelType, utcOffset, docTypes},
) {
  if (!formSelector(state, {formName})) {
    return ''
  }

  const templateIDs = defaultPackingListIDFromOrderNumbersSelector(state, {
    orderNumbers,
  })

  const docs = [`${labelType}label`]

  const query = stringify(
    abodeParamsSelector(state, {
      formName,
      orderNumbers,
      templateIDs,
      docs,
      utcOffset,
      docTypes,
    }),
  )

  return formatAbodeURL(`/label?${query}`)
}

const ORDER_CONTEXT_FIELDS = [
  'order_number',
  'barcode',
  'order_placed_date',
  'shipping_address',
  'billing_address',
  'notes_from_customer',
  'requested_shipping_method',
  'shipping_info',
  'dropshipping_info',
  'parent_order_number',
  'lines',
  'financial',
  'warehouse',
]

export function abodeRenderPayloadForLPPSelector(
  state,
  {formName, orderNumbers, labelType, utcOffset, docTypes},
) {
  if (!formSelector(state, {formName})) {
    return
  }

  const templateIDs = defaultPackingListIDFromOrderNumbersSelector(state, {
    orderNumbers,
  })

  const abodeParams = abodeParamsSelector(state, {
    formName,
    orderNumbers,
    templateIDs,
    docs: labelType ? [`${labelType}label`] : [],
    utcOffset,
    docTypes,
  })
  const packingListIDs = abodeParams.template
  const docs = abodeParams.docs
  const layout_id = abodeParams.layout_id
  delete abodeParams.template
  delete abodeParams.order
  delete abodeParams.docs

  const props = Object.entries(abodeParams).reduce((prev, [key, value]) => {
    if (key === 'showPickListTotalSKUs') {
      prev.show_pick_list_total_skus = value
    } else {
      prev[snakeCase(key)] = value
    }

    return prev
  }, {})

  const includesShippingLabel = docs.includes('shippinglabel')
  const includesReturnLabel = docs.includes('returnlabel')
  props.include_labels = includesShippingLabel || includesReturnLabel
  props.include_packing_list = docs.includes('packing')
  props.include_pick_list = docs.includes('pick')
  props.side_by_side = 'two_per_page' === abodeParams.layout

  const filenameDocs = compact([
    includesShippingLabel && 'Shipping label',
    includesReturnLabel && 'Return label',
    props.include_packing_list && 'Packing list',
    props.include_pick_list && 'Pick list',
  ]).join(' + ')
  const filenameOrders =
    orderNumbers.length === 1
      ? orderNumbers[0]
      : `${orderNumbers.length} orders`
  const pdf_name = `${filenameOrders} - ${filenameDocs}.pdf`

  const render = ['LPPSpotLayout', {$: ['var', 'state.lpp_options']}]

  const _state = {
    utc_offset: props.utc_offset,
    lpp_options: props,
    orders: orderNumbers.map((orderNumber, index) => {
      const packing_list_id = packingListIDs[index] || packingListIDs[0]
      const order = orderSelector(state, {orderNumber})
      return {
        ...pick(order, ORDER_CONTEXT_FIELDS),
        shipping_info:
          labelType === 'return'
            ? order.return_shipping_info
            : order.shipping_info,
        packing_list_id,
      }
    }),
    company: companySelector(state),
    products_by_sku: {
      $: [
        'getProductsBySKUForLPP',
        {$: ['var', 'state.orders']},
        {$: ['var', 'state.lpp_options']},
      ],
    },
    linked_orders_by_order_number: props.show_sibling_order_quantities
      ? {
          $: ['getLinkedOrders', {$: ['var', 'state.orders']}],
        }
      : null,
    packing_lists_by_id: Object.entries(packingListsSelector(state)).reduce(
      (prev, [packingListID, packingList]) => {
        if (packingListIDs.includes(Number(packingListID))) {
          prev[packingListID] = packingList
        }

        return prev
      },
      {},
    ),
  }

  return {
    pdf_name,
    render,
    state: _state,
    ...(layout_id ? {layout_id} : null),
  }
}

export async function openAbodeTab({
  formName,
  orderNumbers,
  labelType,
  utcOffset,
  docTypes,
  tab,
}) {
  const payload = abodeRenderPayloadForLPPSelector(getState(), {
    formName,
    orderNumbers,
    labelType,
    utcOffset,
    docTypes,
  })

  const file = await abodeRenderPOST(payload)

  openFileInTab(file, tab)
}

export async function sendAbodeRenderToPrintNode({
  formName,
  orderNumbers,
  labelType,
  utcOffset,
  docTypes,
  printButtonFormKey,
  printerID,
  documentCount,
}) {
  startSendingToPrintNode(printButtonFormKey)

  const payload = abodeRenderPayloadForLPPSelector(getState(), {
    formName,
    orderNumbers,
    labelType,
    utcOffset,
    docTypes,
  })

  const file = await abodeRenderPOST(payload)

  await sendToPrintNode({
    formKey: printButtonFormKey,
    pdfFile: file,
    pdfName: payload.pdf_name,
    printerID,
    documentCount,
  })
}

export function openSyncLoadingTab() {
  const tab = window.open()

  const isDarkMode = document
    .querySelector('html')
    .classList.contains('dark-mode')

  tab.document.title = 'Loading Document'

  if (isDarkMode) {
    tab.document.querySelector('html').classList.add('dark-mode')
  }

  tab.document.body.innerHTML = html`<div>
    <style>
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(359deg);
        }
      }

      body {
        background: #eee;
        color: #111;
      }

      .wrap--loading {
        margin: auto;
        position: absolute;
        text-align: center;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 15rem;
        height: 10rem;
      }

      .loading {
        border: 0.5rem solid rgba(0, 0, 0, 0.3);
        border-left: 0.5rem solid black;
        opacity: 1;
        width: 3.75rem;
        height: 3.75rem;
        border-radius: 100%;
        vertical-align: middle;
        animation: spin 0.65s infinite linear;
        display: inline-block;
      }

      .text--loading {
        font-size: 1rem;
        line-height: 1.2;
        text-align: center;
        margin-top: 1rem;
        font-family: sans-serif;
        color: #666;
      }

      html.dark-mode body {
        background: #111;
        color: #eee;
      }

      html.dark-mode .loading {
        border-color: rgba(255, 255, 255, 0.6);
        border-left-color: white;
      }

      html.dark-mode .text--loading {
        color: #999;
      }
    </style>
    <div class="wrap--loading">
      <div class="loading"></div>
      <div class="text--loading">Loading your file...</div>
    </div>
  </div>`

  return tab
}

function openFileInTab(file, tab) {
  const objURL = URL.createObjectURL(file)

  tab.location = objURL
  tab.document.title = file.name

  const setTitle = () => {
    tab.removeEventListener('load', setTitle)

    setTimeout(() => {
      tab.document.title = file.name
    }, 500)
  }

  tab.addEventListener('load', setTitle)

  checkAndKillObjURL(tab, objURL)
}

function checkAndKillObjURL(tab, objURL) {
  let isClosed = null

  try {
    isClosed = tab.closed
  } catch (err) {
    isClosed = true
  }

  if (isClosed) {
    URL.revokeObjectURL(objURL)
  } else {
    setTimeout(() => checkAndKillObjURL(tab, objURL), 5000)
  }
}

export const abodePickPackLinkSelector = function (
  state,
  {formName, orderNumbers, utcOffset, docTypes},
) {
  if (!formSelector(state, {formName})) {
    return ''
  }

  const templateIDs = defaultPackingListIDFromOrderNumbersSelector(state, {
    orderNumbers,
  })

  const query = stringify(
    abodeParamsSelector(state, {
      formName,
      orderNumbers,
      templateIDs,
      docs: [],
      utcOffset,
      docTypes,
    }),
  )

  return formatAbodeURL(`/label?${query}`)
}

export function additionalDocumentsLinkSelector(
  state,
  {orderNumbers, labelType},
) {
  return orderNumbers.length
    ? formatV3APIURL(
        '/label',
        removeFalseValues({
          o: orderNumbers,
          pdf_type: 'additional_docs',
          return: labelType === 'return',
        }),
      )
    : ''
}

export function canadaPostDocumentsLinkSelector(
  state,
  {orderNumbers, labelType},
) {
  return orderNumbers.length
    ? formatV3APIURL(
        '/label',
        removeFalseValues({
          o: orderNumbers,
          pdf_type: 'canada_pdf',
          return: labelType === 'return',
        }),
      )
    : ''
}

export const abodeRMALabelLinkSelector = function (
  state,
  {formName, referenceIDs, utcOffset, docTypes},
) {
  if (!formSelector(state, {formName})) {
    return ''
  }

  const templateIDs = defaultPackingListIDFromRMAsSelector(state, {
    referenceIDs,
  })

  const query = stringify(
    abodeParamsSelector(state, {
      formName,
      referenceIDs,
      templateIDs,
      docs: [],
      utcOffset,
      docTypes,
    }),
  )

  return formatAbodeURL(`/rma_label?${query}`)
}

export function setShowOrderTray(formName, showOrderTray) {
  updateForm(formName, {showOrderTray}, {stickyProps: ['showOrderTray']})
}

export function setShowPackingList(formName, showPackingList) {
  updateForm(formName, {showPackingList}, {stickyProps: ['showPackingList']})
}

export function setPackingListSort(formName, packingListSort) {
  updateForm(formName, {packingListSort}, {stickyProps: ['packingListSort']})
}

export function setShowPickList(formName, showPickList) {
  updateForm(formName, {showPickList}, {stickyProps: ['showPickList']})
}

export function setPickListSort(formName, pickListSort) {
  updateForm(formName, {pickListSort}, {stickyProps: ['pickListSort']})
}

export function setTemplateID(formName, templateID) {
  updateForm(formName, {templateID}, {stickyProps: ['templateID']})
}

export function setShowPrice(formName, showPrice) {
  updateForm(formName, {showPrice}, {stickyProps: ['showPrice']})
}

export function setShowBarcode(formName, showBarcode) {
  updateForm(formName, {showBarcode}, {stickyProps: ['showBarcode']})
}

export function setShowLogoOnLabel(formName, showLogoOnLabel) {
  updateForm(formName, {showLogoOnLabel}, {stickyProps: ['showLogoOnLabel']})
}

export function setLabelPosition(formName, labelPosition) {
  updateForm(formName, {labelPosition}, {stickyProps: ['labelPosition']})
}

export function setShowFullPage(formName, showFullPage) {
  updateForm(formName, {showFullPage}, {stickyProps: ['showFullPage']})
}

export function setBreakPageOnOrder(formName, breakPageOnOrder) {
  updateForm(formName, {breakPageOnOrder}, {stickyProps: ['breakPageOnOrder']})
}

export function setShowPackingListImages(formName, showPackingListImages) {
  updateForm(
    formName,
    {showPackingListImages},
    {stickyProps: ['showPackingListImages']},
  )
}

export function setShowPickListImages(formName, showPickListImages) {
  updateForm(
    formName,
    {showPickListImages},
    {stickyProps: ['showPickListImages']},
  )
}

export function setShowPickListTotalItems(formName, showPickListTotalItems) {
  updateForm(
    formName,
    {showPickListTotalItems},
    {stickyProps: ['showPickListTotalItems']},
  )
}

export function setShowPickListTotalSKUs(formName, showPickListTotalSKUs) {
  updateForm(
    formName,
    {showPickListTotalSKUs},
    {stickyProps: ['showPickListTotalSKUs']},
  )
}

export function setShowPickListTotalOrders(formName, showPickListTotalOrders) {
  updateForm(
    formName,
    {showPickListTotalOrders},
    {stickyProps: ['showPickListTotalOrders']},
  )
}

export function setHighlightPickLineQty(formName, highlight_pick_line_qty) {
  updateForm(
    formName,
    {highlight_pick_line_qty},
    {stickyProps: ['highlight_pick_line_qty']},
  )
}

export function setPackingListKitViewMode(formName, packingListKitViewMode) {
  updateForm(
    formName,
    {packingListKitViewMode},
    {stickyProps: ['packingListKitViewMode']},
  )
}

export function setShowSiblingOrderQuantities(
  formName,
  showSiblingOrderQuantities,
) {
  updateForm(
    formName,
    {showSiblingOrderQuantities},
    {stickyProps: ['showSiblingOrderQuantities']},
  )
}

export function setShowZeroQuantityLines(formName, showZeroQuantityLines) {
  updateForm(
    formName,
    {showZeroQuantityLines},
    {stickyProps: ['showZeroQuantityLines']},
  )
}

export function setHighlightPackLineQty(formName, highlight_pack_line_qty) {
  updateForm(
    formName,
    {highlight_pack_line_qty},
    {stickyProps: ['highlight_pack_line_qty']},
  )
}

export function setShowPickListPOH(formName, show_pick_list_poh) {
  updateForm(
    formName,
    {show_pick_list_poh},
    {stickyProps: ['show_pick_list_poh']},
  )
}

export function setShowPackSKUBarcode(formName, show_pack_sku_barcode) {
  updateForm(
    formName,
    {show_pack_sku_barcode},
    {stickyProps: ['show_pack_sku_barcode']},
  )
}

export function setShowPickSKUBarcode(formName, show_pick_sku_barcode) {
  updateForm(
    formName,
    {show_pick_sku_barcode},
    {stickyProps: ['show_pick_sku_barcode']},
  )
}

export function setShowPackingListDetails(formName, show_packing_list_details) {
  updateForm(
    formName,
    {show_packing_list_details},
    {stickyProps: ['show_packing_list_details']},
  )
}

export function setShowShippingInfo(formName, showShippingInfo) {
  updateForm(formName, {showShippingInfo}, {stickyProps: ['showShippingInfo']})
}

export function setShowWarehouseLocation(formName, showWarehouseLocation) {
  updateForm(
    formName,
    {showWarehouseLocation},
    {stickyProps: ['showWarehouseLocation']},
  )
}

export function setShowBillTo(formName, showBillTo) {
  updateForm(formName, {showBillTo}, {stickyProps: ['showBillTo']})
}

export function setShowPackSerialNumbers(formName, showPackSerialNumbers) {
  updateForm(
    formName,
    {showPackSerialNumbers},
    {stickyProps: ['showPackSerialNumbers']},
  )
}

export function setShowPickSerialNumbers(formName, showPickSerialNumbers) {
  updateForm(
    formName,
    {showPickSerialNumbers},
    {stickyProps: ['showPickSerialNumbers']},
  )
}

export function setShowCustomerNotes(formName, showCustomerNotes) {
  updateForm(
    formName,
    {showCustomerNotes},
    {stickyProps: ['showCustomerNotes']},
  )
}

export function setPickListKitViewMode(formName, pickListKitViewMode) {
  updateForm(
    formName,
    {pickListKitViewMode},
    {stickyProps: ['pickListKitViewMode']},
  )
}

export function setUseCustomLayoutSettings(formName, useCustomLayoutSettings) {
  updateForm(
    formName,
    {useCustomLayoutSettings},
    {stickyProps: ['useCustomLayoutSettings']},
  )
}

export function setLabelTopMargin(formName, label_top_margin) {
  updateForm(formName, {label_top_margin}, {stickyProps: ['label_top_margin']})
}

export function setLabelLeftMargin(formName, label_left_margin) {
  updateForm(
    formName,
    {label_left_margin},
    {stickyProps: ['label_left_margin']},
  )
}

export function setLabelDimensions(formName, label_width, label_height) {
  updateForm(
    formName,
    {label_width, label_height},
    {stickyProps: ['label_width', 'label_height']},
  )
}

export function setLabelWidth(formName, label_width) {
  let {label_height} = labelViewFormAbodeSelector(getState(), {formName})

  if (!isNonZeroPositiveNumeric(label_width)) {
    label_height = null
    label_width = null
  } else {
    label_height = String(round(Number(label_width) * (6 / 4), 2))
  }

  setLabelDimensions(formName, label_width, label_height)
}

export function setLabelHeight(formName, label_height) {
  let {label_width} = labelViewFormAbodeSelector(getState(), {formName})

  if (!isNonZeroPositiveNumeric(label_height)) {
    label_width = null
    label_height = null
  } else {
    label_width = String(round(label_height * (4 / 6), 2))
  }

  setLabelDimensions(formName, label_width, label_height)
}

export function setPrintConfigID(formName, printConfigID) {
  updateForm(formName, {printConfigID}, {stickyProps: ['printConfigID']})
}

export function setLayoutID(formName, layout_id) {
  updateForm(formName, {layout_id}, {stickyProps: ['layout_id']})
}

export function setSize(formName, size) {
  updateForm(formName, {size}, {stickyProps: ['size']})
}
