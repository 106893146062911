import {PACKING_LISTS} from '../../../common/constants/SettingsPanels.js'
import {packingListsHaveLoadedSelector} from '../../../data/packingLists.js'
import {setupPackingListsForm} from './packingListsFunctions.js'
import {settingsPermissionsSelector} from '../../../redux/selectors/ui/settings/index.js'
import SettingsPanel from '../SettingsPanel.js'
import {onlyIfAutoForm, useSelector} from '../../../store.js'
import List from './List.js'

function PackingLists() {
  const hasLoaded = useSelector(packingListsHaveLoadedSelector)
  const hasPermission = useSelector(settingsPermissionsSelector)[PACKING_LISTS]

  return (
    <SettingsPanel header="Profile Settings" hasPermission={hasPermission}>
      <div className="row">{hasLoaded && <List />}</div>
    </SettingsPanel>
  )
}

export default onlyIfAutoForm(PackingLists, setupPackingListsForm)
