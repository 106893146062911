import {useEffect} from 'react'

import {useSelector} from '../../../store.js'
import {sortedPackingListsByIDSelector} from '../../../data/packingLists.js'
import {
  settingsPackingListsIDSelector,
  settingsPackingListsAddSelector,
  setupForEdit,
  setupForAdding,
  resetPackingListsForm,
} from './packingListsFunctions.js'

import EditForm from './EditForm.js'
import NewForm from './NewForm.js'

export default function List() {
  const packingLists = useSelector(sortedPackingListsByIDSelector)

  const settingsPackingListID = useSelector(settingsPackingListsIDSelector)
  useEffect(() => {
    if (!settingsPackingListID) {
      return
    }

    setupForEdit()
  }, [settingsPackingListID])

  const settingsPackingListAdd = useSelector(settingsPackingListsAddSelector)
  useEffect(() => {
    if (!settingsPackingListAdd) {
      return
    }

    setupForAdding()
  }, [settingsPackingListAdd])

  useEffect(() => {
    if (settingsPackingListID || settingsPackingListAdd) {
      return
    }

    resetPackingListsForm()
  }, [settingsPackingListID, settingsPackingListAdd])

  return (
    <ul className="settings-list medium-12 columns">
      {packingLists.map((packingList) => (
        <EditForm key={packingList.id} packingList={packingList} />
      ))}
      <NewForm />
    </ul>
  )
}
