import {NEW_ID} from '../../../common/constants/index.js'
import Form from './Form.js'
import {useSelector} from '../../../store.js'
import {
  PROFILES_GENERAL,
  settingsPackingListsAddSelector,
} from './packingListsFunctions.js'
import {PACKING_LIST_SETTINGS_LINK} from '../../../common/constants/SettingsPanels.js'
import className from '../../../common/className.js'

export default function NewForm() {
  const isNew = useSelector(settingsPackingListsAddSelector)

  return (
    <li
      className={className`settings-list-item add-item add-sales-channel clearfix ${{expanded: isNew}}`}
    >
      <div className="medium-12 columns button-wrap">
        <p>
          <a
            href={`${PACKING_LIST_SETTINGS_LINK}/${PROFILES_GENERAL}/${NEW_ID}`}
            title="Create a packing list"
            className="inline-text-button no-underline"
          >
            <span
              className="icon icon-add icon-block x-sm margin-right-5"
              aria-hidden="true"
            />
            <span>Create a new profile</span>
          </a>
        </p>
      </div>
      {isNew && <Form />}
    </li>
  )
}
