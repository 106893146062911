export const usersHaveLoadedSelector = (state) => !!state.data.hasLoaded.users

export const apiKeysHaveLoadedSelector = (state) =>
  !!state.data.hasLoaded.apiKeys

export const returnOrderCodesHaveLoadedSelector = (state) =>
  !!state.data.hasLoaded.returnOrderCodes

export const productsHaveLoadedSelector = (state) =>
  !!state.data.hasLoaded.products
