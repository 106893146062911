import React, {useState, useEffect, useContext} from 'react'
import fpSet from 'lodash/fp/set.js'
import get from 'lodash/get.js'

// redux-less store. Copied from lynn. We might never get to use
// this in main "app" but it can be used by lesser "app" in pappy
// it is called ztore because it would be easy to rename to "store" later

export const StoreContext = React.createContext()
let state
let subscribers

export function resetStore() {
  state = {id: 0}
  subscribers = []
}

resetStore()

export function setForm(key, form) {
  state = {
    ...state,
    id: state.id + 1,
    [key]: form,
  }

  notify()
}

export function updateForm(key, form) {
  const oldForm = state[key]

  if (oldForm) {
    state = {
      ...state,
      id: state.id + 1,
      [key]: {
        ...oldForm,
        ...form,
      },
    }

    notify()
  }
}

export function updateFormByPath(key, path, updates) {
  const oldForm = state[key]

  if (oldForm) {
    const oldValue = get(oldForm, path)
    const newForm = fpSet(path, {...oldValue, ...updates}, oldForm)

    state = {
      ...state,
      id: state.id + 1,
      [key]: newForm,
    }

    notify()
  }
}

export function setFormValueByPath(key, path, value) {
  const oldForm = state[key]

  if (oldForm) {
    const newForm = fpSet(path, value, oldForm)

    state = {
      ...state,
      id: state.id + 1,
      [key]: newForm,
    }

    notify()
  }
}

export function removeForm(key) {
  const {...newState} = state

  delete newState[key]

  newState.id = newState.id + 1
  state = newState

  notify()
}

export function getState() {
  return state
}

export function subscribe(func) {
  subscribers.push(func)

  return () => {
    const index = subscribers.indexOf(func)

    subscribers.splice(index, 1)
  }
}

export function notify() {
  for (const func of subscribers) {
    func()
  }
}

export function StoreProvider({children}) {
  const [state, setState] = useState({})

  useEffect(() => {
    window.use_ztore = true

    const unsubscribe = subscribe(() => {
      setState(getState())
    })

    notify()

    return unsubscribe
  }, [])

  return (
    <StoreContext.Provider value={state}>
      {state.id === undefined ? null : children}
    </StoreContext.Provider>
  )
}

export function useSelector(selector, ...args) {
  const state = useContext(StoreContext)

  return selector(state, ...args)
}

export function useForm(formName) {
  return useSelector((state) => state[formName])
}

export function useAutoForm(formName, initialForm = {}) {
  useEffect(() => {
    setForm(formName, initialForm)

    return () => {
      removeForm(formName)
    }
  }, [])

  const form = useForm(formName)

  return form || initialForm
}

export function onlyIfAutoForm(
  Component,
  formSetupFunction,
  ElseComponent = null,
) {
  return function OnlyIfAutoForm(props) {
    const {formName, initialForm} = formSetupFunction(props)

    useAutoForm(formName, initialForm)

    const form = useForm(formName)

    if (!form) {
      return ElseComponent ? (
        <ElseComponent formName={formName} {...props} />
      ) : null
    }

    return <Component formName={formName} form={form} {...props} />
  }
}

export function onlyIf(Component, conditionalSelector, ElseComponent = null) {
  return function OnlyIf(props) {
    const isTrue = useSelector((state) => conditionalSelector(state, props))

    if (!isTrue) {
      return ElseComponent ? <ElseComponent {...props} /> : null
    }

    return <Component {...props} />
  }
}

export function onlyIfForm(Component, formSelector, ElseComponent = null) {
  return function OnlyIfForm(props) {
    const form = useSelector(formSelector)

    if (!form) {
      return ElseComponent ? <ElseComponent {...props} /> : null
    }

    return <Component form={form} {...props} />
  }
}

const store = {
  getState,
}

window.store = store

export default store
