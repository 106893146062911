import {all, takeEvery} from 'redux-saga/effects'

import apiKeys from './apiKeys.js'
import shippers from './shippers/index.js'
import users from './users.js'
import {
  SAVE_RETURN_ORDER_CODE,
  saveReturnOrderCodeWorker,
} from '../../../../ordoro/settings/ReturnOrderCodes/ReturnOrderCodeForm.js'

export default function* settingsSaga() {
  yield all([
    apiKeys(),
    shippers(),
    users(),
    takeEvery(SAVE_RETURN_ORDER_CODE, saveReturnOrderCodeWorker),
  ])
}
