import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {createSelector} from 'reselect'
import {all, call, select, put} from 'redux-saga/effects'

import formConnect from '../../../common/formConnect.js'
import {PackingListShape} from '../../../common/PropTypes.js'
import ConfirmModal from '../../../common/components/Modal/ConfirmModal.js'
import Select from '../../../common/components/Select.js'
import apiverson from '../../../common/apiverson.js'
import {showMessageToast} from '../../Header/Toast/index.js'
import {setReturnOrder} from '../../../redux/actions/data/returnOrders.js'
import {
  setForm,
  updateForm,
  removeForm,
} from '../../../redux/actions/ui/forms.js'
import {sortedPackingListsByIDSelector} from '../../../data/packingLists.js'
import {formsSelector} from '../../../redux/selectors/ui/forms.js'
import {refreshReturnOrderList} from '../returnOrderListActions.js'
const MODAL_FORM = 'EMAIL_RETURN_ORDERS_MODAL_FORM'
export const EMAIL_RETURN_ORDERS = 'EMAIL_RETURN_ORDERS'

export function showEmailReturnOrdersModal(referenceIDs) {
  return setForm(MODAL_FORM, {
    referenceIDs,
    packingListID: 0,
    isSaving: false,
    serverError: null,
  })
}

export function updateModalForm(updates) {
  return updateForm(MODAL_FORM, updates)
}

export function removeModalForm() {
  return removeForm(MODAL_FORM)
}

function emailReturnOrders() {
  return {
    type: EMAIL_RETURN_ORDERS,
  }
}

export const modalFormSelector = createSelector(
  formsSelector,
  (forms) => forms[MODAL_FORM],
)

export function* emailReturnOrder(id) {
  const {packingListID} = yield select(modalFormSelector)

  const {json} = yield call(
    apiverson.post,
    `/return_order/${encodeURIComponent(id)}/email`,
    packingListID ? {packing_list_id: packingListID} : undefined,
  )

  yield put(setReturnOrder(json))
}

export function* emailReturnOrdersWorker() {
  try {
    yield put(updateModalForm({isSaving: true}))

    const {referenceIDs} = yield select(modalFormSelector)
    const roCount = referenceIDs.length

    yield all(referenceIDs.map((id) => call(emailReturnOrder, id)))

    yield put(refreshReturnOrderList())

    yield call(
      showMessageToast,
      `${roCount} return label${roCount !== 1 ? 's were' : ' was'} emailed`,
    )

    yield put(removeModalForm())
  } catch (err) {
    yield put(
      updateModalForm({
        serverError: `Error emailing return label: ${
          err.message || err.error_message
        }`,
      }),
    )
  } finally {
    yield put(updateModalForm({isSaving: false}))
  }
}

function EmailReturnOrdersModal({form, packingLists, ...props}) {
  const roCount = form.referenceIDs.length

  return (
    <ConfirmModal
      title="Email Return Label"
      onConfirm={() => props.emailReturnOrders()}
      onCancel={() => props.removeModalForm()}
      isSaving={form.isSaving}
      error={form.serverError}
    >
      <p className="fs-01 margin-bottom-0">
        Are you sure you want to email {roCount} return label
        {roCount !== 1 ? 's' : ''}?
      </p>
      <div className="divider--top">
        <Select
          label="Profile to be used for email template:"
          id="packing_list_id"
          value={form.packingListID}
          onChange={(value) =>
            props.updateModalForm({packingListID: Number(value)})
          }
        >
          <option value="0">Use sales channel default</option>
          <option disabled>---------------------------</option>
          {packingLists.map((packingList) => (
            <option value={packingList.id} key={packingList.id}>
              {!packingList.website
                ? packingList.name
                : `${packingList.name} - ${packingList.website}`}
            </option>
          ))}
        </Select>
      </div>
    </ConfirmModal>
  )
}

EmailReturnOrdersModal.propTypes = {
  form: PropTypes.shape({
    referenceIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
    packingListID: PropTypes.number.isRequired,
    isSaving: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
  }),
  packingLists: PropTypes.arrayOf(PackingListShape),
  emailReturnOrders: PropTypes.func.isRequired,
  removeModalForm: PropTypes.func.isRequired,
  updateModalForm: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    packingLists: sortedPackingListsByIDSelector(state),
  }
}

const mapDispatchToProps = {
  emailReturnOrders,
  updateModalForm,
  removeModalForm,
}

export default formConnect(
  connect(mapStateToProps, mapDispatchToProps)(EmailReturnOrdersModal),
  modalFormSelector,
)
