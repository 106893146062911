import {ensureArray} from '../../common/ensure.js'
import {
  SingleAddressLayouts,
  MultipleAddressLayouts,
} from '../../common/constants/AddressLabelLayouts.js'
import Select from '../../common/components/Form/Select.js'
import NumberInput from '../../common/components/Form/NumberInput.js'
import ButtonLink from '../../common/components/Button/ButtonLink.js'
import Address from '../../common/components/Order/Address.js'
import {packingListsWithLogoURLsSelector} from '../../data/packingLists.js'
import {showEditMockAddressDataModal} from './Modals/EditMockAddressDataModal.js'
import {showEditMockAddressIDsModal} from './Modals/EditMockAddressIDsModal.js'
import {
  mockAbodeUpdateResourceQuery,
  querySelector,
} from './mockAbodeFunctions.js'
import {useSelector} from '../../store.js'

export default function MockAbodeAddressOptions() {
  const query = useSelector(querySelector)
  const packingListsWithLogoURLs = useSelector(packingListsWithLogoURLsSelector)
  const addressIDs = ensureArray(query.addressIDs)
  const addressData = JSON.parse(query.addressData || '[]')

  return (
    <>
      <div>
        <Address
          address={addressData[0] || {}}
          onEdit={() => showEditMockAddressDataModal()}
        />
      </div>
      <div>
        <ButtonLink onClick={() => showEditMockAddressIDsModal()}>
          {addressIDs.length === 0
            ? 'No Address IDs'
            : addressIDs.length === 1
              ? addressIDs[0]
              : `${addressIDs.length} address IDs`}
        </ButtonLink>
      </div>
      <Select
        label="Layout"
        name="mock_abode__layout"
        value={query.layout}
        onChange={(value) => mockAbodeUpdateResourceQuery({layout: value})}
        className="margin-bottom-20"
      >
        {[...SingleAddressLayouts, ...MultipleAddressLayouts].map(
          ({value, display}) => (
            <option key={value} value={value}>
              {display}
            </option>
          ),
        )}
      </Select>
      <Select
        label="Logo"
        name="mock_abode__logoLink"
        value={query.logoLink}
        onChange={(value) =>
          mockAbodeUpdateResourceQuery({logoLink: value || undefined})
        }
        className="margin-bottom-20"
      >
        <option value="">No Logo</option>
        <option disabled>-- Packing List Logos --</option>
        {packingListsWithLogoURLs.map(({id, logoURL, name}) => (
          <option key={id} value={logoURL}>
            {name}
          </option>
        ))}
        <option disabled>-- Mock Logos --</option>
        <option value="https://abode.ordoro-dev.com/image/-1/lucky_logo?width=300&type=png">
          Lucky
        </option>
        <option value="https://abode.ordoro-dev.com/image/-1/turtle_logo?width=300&type=png">
          Turtle
        </option>
        <option value="https://abode.ordoro-dev.com/image/-1/jacks_logo?width=300&type=png">
          Jack’s
        </option>
      </Select>
      <div>
        <label htmlFor="mock_abode__copy_count">
          <span>Copy Count</span>
        </label>
        <NumberInput
          id="mock_abode__copy_count"
          value={query.copyCount}
          min={1}
          onChange={(value) => mockAbodeUpdateResourceQuery({copyCount: value})}
        />
      </div>
    </>
  )
}
