import {combineReducers} from 'redux'

import INITIAL_STATE from '../../INITIAL_STATE.js'

import {NAVIGATE} from '../../../actions/ui/index.js'
import * as PANELS from '../../../../common/constants/SettingsPanels.js'

import users from './users.js'

import shippers from './shippers/index.js'
import returnOrderCodes from './returnOrderCodes.js'
import payments from './payments.js'

function currentSettingsPanel(
  state = INITIAL_STATE.ui.settings.currentSettingsPanel,
  action = {},
) {
  if (action.type === NAVIGATE) {
    const {pathComponents} = action.payload

    if (pathComponents[0] === 'settings') {
      return Object.values(PANELS).includes(pathComponents[1])
        ? pathComponents[1]
        : INITIAL_STATE.ui.settings.currentSettingsPanel
    }
  }

  return state
}

export default combineReducers({
  users,
  shippers,
  returnOrderCodes,
  payments,
  currentSettingsPanel,
})
