import {createSelector} from 'reselect'
import get from 'lodash/get.js'
import sortBy from 'lodash/sortBy.js'
import moment from 'moment'

import {ADDRESS_COMMENT} from '../../../common/constants/Comments.js'
import {formatAPIURL} from '../../../common/api.js'
import {hasReturnOrderPermissionSelector} from '../../../data/me.js'
import {
  hasUseReturnOrdersFeatureSelector,
  showFeatureLocksSelector,
} from '../../../data/company.js'
import {
  defaultPackingListSelector,
  firstPackingListSelector,
} from '../../../data/packingLists.js'
import {
  warehousesSelector,
  warehouseAddressSelector,
} from '../../../data/warehouses.js'
import {productsSelector} from '../../../data/products.js'
import {
  shipperTypeSelector,
  shipperNameSelector,
  shipperCurrencySymbolSelector,
} from '../../../data/shippers.js'
import {orderSelector} from '../../../data/orders.js'
import {cartSelector} from '../../../data/carts.js'
import {
  shippingMethodNameSelector,
  boxShapeNameSelector,
} from '../../../data/shipperOptions.js'
import {dimensionsAreRequired} from '../../../data/labelInfos/index.js'

export const returnOrdersSelector = createSelector(
  (state) => state.data.returnOrders,
  (returnOrders) => returnOrders,
)

export function returnOrderSelector(state, {referenceID}) {
  return returnOrdersSelector(state)[referenceID]
}

export const createReturnOrderSelector = (referenceID) =>
  createSelector(
    returnOrdersSelector,
    (returnOrders) => returnOrders[referenceID],
  )

export const canUseReturnOrdersSelector = createSelector(
  hasReturnOrderPermissionSelector,
  hasUseReturnOrdersFeatureSelector,
  (hasPermission, hasUseReturnOrdersFeature) =>
    hasPermission && hasUseReturnOrdersFeature,
)

export const showReturnOrdersNavSelector = createSelector(
  hasReturnOrderPermissionSelector,
  hasUseReturnOrdersFeatureSelector,
  showFeatureLocksSelector,
  (hasReturnOrderPermission, hasUseReturnOrdersFeature, showFeatureLocks) =>
    hasReturnOrderPermission && (hasUseReturnOrdersFeature || showFeatureLocks),
)

export const createReturnOrderOriginalOrderNumberSelector = (
  returnOrderSelector,
) => createSelector(returnOrderSelector, (ro) => ro && ro.original_order_number)

export function returnOrderStatusSelector(state, {referenceID}) {
  const ro = returnOrderSelector(state, {referenceID})

  return ro && ro.status
}

export const createReturnOrderStatusSelector = (returnOrderSelector) =>
  createSelector(returnOrderSelector, (ro) => ro && ro.status)

export function linesSelector(state, {referenceID}) {
  const ro = returnOrderSelector(state, {referenceID})

  return get(ro, 'lines', [])
}

export function returnOrderLineSelector(state, {referenceID, lineID}) {
  const lines = linesSelector(state, {referenceID})

  return lines.find(({line_id}) => line_id === lineID)
}

export function hasShippingInfoSelector(state, {referenceID}) {
  const ro = returnOrderSelector(state, {referenceID})

  return get(ro, 'has_shipping_info', false)
}

export function returnOrderCustomerAddressSelector(state, {referenceID}) {
  const ro = returnOrderSelector(state, {referenceID})

  return get(ro, 'customer_address', {})
}

export function commentsSelector(state, {referenceID}) {
  const ro = returnOrderSelector(state, {referenceID})

  return get(ro, 'comments', [])
}

export function parseComment(comment) {
  if (comment.text.match(/^Updated address /)) {
    return {
      ...comment,
      type: ADDRESS_COMMENT,
    }
  }

  return comment
}

export function getCommentGroups(ro) {
  const comments = get(ro, 'comments', [])
  const daysIndex = {}

  return sortBy(comments, 'date')
    .reverse()
    .map(parseComment)
    .reduce((days, comment) => {
      const day = moment(comment.date).format('MMM D, YYYY')

      if (daysIndex[day]) {
        daysIndex[day].comments.push(comment)
      } else {
        daysIndex[day] = {
          day,
          comments: [comment],
        }

        days.push(daysIndex[day])
      }

      return days
    }, [])
}

export const createReturnOrderCommentGroupsSelector = (returnOrderSelector) =>
  createSelector(returnOrderSelector, (ro) => getCommentGroups(ro))

function getWarehouseID(ro) {
  return get(ro, 'warehouse_id', null)
}

export function returnOrderWarehouseIDSelector(state, {referenceID}) {
  const ro = returnOrderSelector(state, {referenceID})

  return getWarehouseID(ro)
}

function getReturnToAddress(ro) {
  return get(ro, 'return_to_address', null)
}

export function returnToAddressSelector(state, {referenceID}) {
  const ro = returnOrderSelector(state, {referenceID})

  const returnToAddress = getReturnToAddress(ro)

  if (returnToAddress) {
    return returnToAddress
  }

  const warehouseID = getWarehouseID(ro)

  if (warehouseID) {
    return warehouseAddressSelector(state, {warehouseID})
  }

  return null
}

export function returnOrderShipToAddressSelector(state, {referenceID}) {
  return returnToAddressSelector(state, {referenceID}) || {}
}

export const createReturnOrderLinesSelector = (returnOrderSelector) =>
  createSelector(returnOrderSelector, (ro) => {
    return get(ro, 'lines', [])
  })

export const createReturnOrderTotalSKUCountSelector = (returnOrderSelector) =>
  createSelector(returnOrderSelector, (ro) => {
    const lines = get(ro, 'lines', [])

    return Object.keys(
      lines.reduce((prev, {sku}) => {
        prev[sku] = true

        return prev
      }, {}),
    ).length
  })

export const createReturnOrderTotalLineQuantitySelector = (
  returnOrderSelector,
) =>
  createSelector(returnOrderSelector, (ro) => {
    const lines = get(ro, 'lines', [])

    return lines.reduce(
      (prev, {expected_quantity}) => prev + expected_quantity,
      0,
    )
  })

export function createReturnOrderCustomerAddressSelector(returnOrderSelector) {
  return createSelector(returnOrderSelector, (ro) =>
    get(ro, 'customer_address', {}),
  )
}

export function createReturnOrderCustomerNotesSelector(returnOrderSelector) {
  return createSelector(returnOrderSelector, (ro) => get(ro, 'customer_notes'))
}

export function createReturnOrderInternalNotesSelector(returnOrderSelector) {
  return createSelector(returnOrderSelector, (ro) => get(ro, 'internal_notes'))
}

export const createReturnOrderWarehouseSelector = (returnOrderSelector) =>
  createSelector(returnOrderSelector, warehousesSelector, (ro, warehouses) => {
    const warehouseID = get(ro, 'warehouse_id')

    return get(warehouses, warehouseID, {id: warehouseID, address: {}})
  })

export const createReturnOrderCreatedDateSelector = (returnOrderSelector) =>
  createSelector(returnOrderSelector, (ro) => get(ro, 'created_date', ''))

export const createReturnOrderUpdatedDateSelector = (returnOrderSelector) =>
  createSelector(returnOrderSelector, (ro) => get(ro, 'updated_date', ''))

export const createReturnOrderTagsSelector = (returnOrderSelector) =>
  createSelector(returnOrderSelector, (ro) => get(ro, 'tags', []))

export function returnOrderLabelURLSelector(state, {referenceID}) {
  const returnOrder = returnOrderSelector(state, {referenceID})

  const rawLink = get(returnOrder, 'shipping_infos.0.label_image_link')

  return rawLink ? formatAPIURL(rawLink) : null
}

export function getLabelInfoIDsFromReturnOrders(returnOrders) {
  return returnOrders.reduce((prev, returnOrder) => {
    const labelInfoIDs = returnOrder.label_infos || []

    labelInfoIDs.forEach((labelInfoID) => {
      if (!prev.includes(labelInfoID)) {
        prev.push(labelInfoID)
      }
    })

    return prev
  }, [])
}

export function getProductSKUsFromReturnOrders(returnOrders) {
  return Object.keys(
    returnOrders.reduce((prev, returnOrder) => {
      returnOrder.lines.forEach((line) => {
        prev[line.sku] = true
      })

      return prev
    }, {}),
  )
}

export function getOrderNumbersFromReturnOrders(returnOrders) {
  return Object.keys(
    returnOrders.reduce((prev, returnOrder) => {
      if (returnOrder.original_order_number) {
        prev[returnOrder.original_order_number] = true
      }

      return prev
    }, {}),
  )
}

export function returnOrderProductsSelector(state, {referenceID}) {
  const returnOrder = returnOrderSelector(state, {referenceID})

  if (!returnOrder) {
    return {}
  }

  const productSKUs = returnOrder.lines.map(({sku}) => sku)
  const products = productsSelector(state)

  return productSKUs.reduce((prev, sku) => {
    if (products[sku]) {
      return {
        ...prev,
        [sku]: products[sku],
      }
    }
    return prev
  }, {})
}

export function returnOrderTotalPriceSumSelector(state, {referenceID}) {
  const returnOrder = returnOrderSelector(state, {referenceID})

  return get(returnOrder, 'lines', []).reduce(
    (prev, {total_price}) => prev + total_price,
    0,
  )
}

export function defaultPackingListIDFromRMAsSelector(state, {referenceIDs}) {
  const ids = referenceIDs.map((referenceID) =>
    defaultPackingListIDFromRMASelector(state, {referenceID}),
  )

  // Since defaultPackingListIDFromOrderNumberSelector will provide a valid ID if packing lists exist
  // then we only need to check the first ID to see if all IDs will be valid
  if (ids[0]) {
    return ids
  }

  // Returning a null instead an empty array is easier to check for but do take care to check
  return null
}

export function defaultPackingListIDFromRMASelector(state, {referenceID}) {
  const returnOrder = returnOrderSelector(state, {referenceID})

  const shippingInfoPackingListID = get(
    returnOrder,
    'shipping_infos.0.packing_list_id',
  )

  if (shippingInfoPackingListID) {
    return shippingInfoPackingListID
  }

  const orderNumber = get(returnOrder, 'original_order_number')

  if (orderNumber) {
    const order = orderSelector(state, {orderNumber})

    const orderShippingInfoPackingListID = get(
      order,
      'shipping_info.packing_list_id',
    )

    if (orderShippingInfoPackingListID) {
      return orderShippingInfoPackingListID
    }

    const cart = cartSelector(state, {cartID: get(order, 'sales_channel.id')})

    if (cart && cart.default_packing_list_id) {
      return cart.default_packing_list_id
    }
  }

  const defaultPackingList = defaultPackingListSelector(state)

  if (defaultPackingList) {
    return defaultPackingList.id
  }

  const firstPackingList = firstPackingListSelector(state)

  if (firstPackingList) {
    return firstPackingList.id
  }

  return null
}

export function rmaShippingInfoSelector(state, {referenceID, index}) {
  index = index || 0

  const returnOrder = returnOrderSelector(state, {referenceID})

  return get(returnOrder, ['shipping_infos', index])
}

export function rmaShippedShipperIDSelector(state, {referenceID}) {
  const shippingInfo = rmaShippingInfoSelector(state, {referenceID})

  return get(shippingInfo, 'carrier.id')
}

export function rmaShippedShipperTypeSelector(state, {referenceID}) {
  const shipperID = rmaShippedShipperIDSelector(state, {referenceID})

  return shipperID ? shipperTypeSelector(state, {shipperID}) : null
}

export function rmaShippedShipperNameSelector(state, {referenceID}) {
  const shipperID = rmaShippedShipperIDSelector(state, {referenceID})

  return shipperID ? shipperNameSelector(state, {shipperID}) : null
}

export function rmaShippedCurrencySymbolSelector(state, {referenceID}) {
  const shipperID = rmaShippedShipperIDSelector(state, {referenceID})

  return shipperID ? shipperCurrencySymbolSelector(state, {shipperID}) : null
}

export function rmaShippedShippingMethodSelector(state, {referenceID}) {
  const shippingInfo = rmaShippingInfoSelector(state, {referenceID})
  const shipperType = rmaShippedShipperTypeSelector(state, {referenceID})
  const methodCode = get(shippingInfo, 'shipping_method')

  return shipperType && methodCode
    ? shippingMethodNameSelector(state, {shipperType, methodCode})
    : null
}

export function rmaShippedBoxShapeSelector(
  state,
  {referenceID, packagesIndex},
) {
  packagesIndex = packagesIndex || 0

  const shippingInfo = rmaShippingInfoSelector(state, {referenceID})
  const boxShape = get(shippingInfo, ['packages', packagesIndex, 'box_shape'])

  return boxShape || null
}

export function rmaShippedBoxShapeNameSelector(
  state,
  {referenceID, packagesIndex},
) {
  packagesIndex = packagesIndex || 0

  const shipperType = rmaShippedShipperTypeSelector(state, {referenceID})
  const boxShape = rmaShippedBoxShapeSelector(state, {
    referenceID,
    packagesIndex,
  })

  return shipperType && boxShape
    ? boxShapeNameSelector(state, {shipperType, boxShape})
    : null
}

export function rmaShippedIsMultibox(state, {referenceID}) {
  const shippingInfo = rmaShippingInfoSelector(state, {referenceID})

  return get(shippingInfo, 'packages.length') > 1
}

export function rmaShippedTotalWeightSelector(state, {referenceID}) {
  const shippingInfo = rmaShippingInfoSelector(state, {referenceID})

  return (get(shippingInfo, 'packages') || []).reduce(
    (prev, {weight}) => (prev += weight),
    0,
  )
}

export function rmaShippedDimensionsSelector(
  state,
  {referenceID, packagesIndex},
) {
  packagesIndex = packagesIndex || 0

  const shippingInfo = rmaShippingInfoSelector(state, {referenceID})
  const pack_age = get(shippingInfo, ['packages', packagesIndex])

  const {height, width, length} = pack_age || {}

  return pack_age ? {height, width, length} : null
}

export function showRMAShippedDimensionsSelector(
  state,
  {referenceID, packagesIndex},
) {
  packagesIndex = packagesIndex || 0

  const shipperType = rmaShippedShipperTypeSelector(state, {referenceID})
  const boxShape = rmaShippedBoxShapeSelector(state, {
    referenceID,
    packagesIndex,
  })

  return dimensionsAreRequired([shipperType], {[shipperType]: [boxShape]})
}
