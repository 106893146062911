import moment from 'moment'
import {TZDate} from '@date-fns/tz'
// Time is hard enough, so let's make mocking easier.

let mockGetDateValue
export function getDate() {
  return mockGetDateValue || moment()
}

export function getRealDate() {
  return mockGetDateValue || new Date()
}

export function mockGetDate(value) {
  mockGetDateValue = value
}

export function resetMockGetDate() {
  mockGetDateValue = undefined
}

export function getTimeZone() {
  /* eslint new-cap: 0 */
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function areDatesEqual(date1, date2) {
  return (date1 ? date1.getTime() : null) === (date2 ? date2.getTime() : null)
}

export function getUTCOffset() {
  return new Date().getTimezoneOffset() * -1
}

export function getTimeZonedDate(date, timeZone) {
  return new TZDate(date, timeZone)
}

export function formatDate(parts) {
  return `${parts.month} ${parts.day}, ${parts.year}`
}

export function formatTime(parts) {
  return `${parts.hour}:${parts.minute}${parts.dayPeriod.toLowerCase()}`
}

export function formatDateTime(parts) {
  return `${formatDate(parts)} at ${formatTime(parts)}`
}

export function formatDateTimeAndZone(parts) {
  return `${formatDateTime(parts)} ${parts.timeZoneName}`
}

export function formatISODate(parts) {
  return `${parts.year}-${parts.month}-${parts.day}`
}

export function formatISOTime(parts) {
  return `${parts.hour}:${parts.minute}:${parts.second}.${parts.fractionalSecond}`
}

export function formatISODateTime(parts) {
  return `${formatISODate(parts)}T${formatISOTime(parts)}`
}

export function formatWeekday(parts) {
  return parts.weekday
}

export function dateTimeFormat(
  date,
  format = formatDate,
  {
    year = 'numeric',
    month = 'short',
    day = 'numeric',
    hour = 'numeric',
    minute = '2-digit',
    second = '2-digit',
    hour12 = true,
    timeZoneName = 'short',
    weekday = 'long',
    timeZone,
    ...options
  } = {},
) {
  const formatter = new Intl.DateTimeFormat('en-US', {
    year,
    month,
    day,
    hour,
    minute,
    second,
    hour12,
    timeZoneName,
    timeZone,
    weekday,
    ...options,
  })

  date = date instanceof Date ? date : new Date(date)

  const parts = formatter.formatToParts(date).reduce((prev, {type, value}) => {
    prev[type] = value

    return prev
  }, {})

  return format(parts)
}

export function isoDateTimeFormat(date, format = formatISODate, options) {
  return dateTimeFormat(date, format, {
    month: '2-digit',
    day: '2-digit',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
    fractionalSecondDigits: 3,
    timeZoneName: 'long',
    ...options,
  })
}

export function getShortTimeZone(date, timezone) {
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    timeZoneName: 'short',
  })
  const parts = formatter.formatToParts(date)

  const timeZoneName = parts.find(({type}) => type === 'timeZoneName').value

  return timeZoneName
}
