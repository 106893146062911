import PropTypes from 'prop-types'
import {useEffect, useCallback} from 'react'

import {
  updateLabelConfig,
  labelConfigSelector,
} from '../../../data/labelInfos/index.js'
import {
  atLeastOneLogoHasBeenSetupSelector,
  sortedPackingListsByIDSelector,
} from '../../../data/packingLists.js'
import ConfigSelect from './ConfigSelect.js'
import {useSelector} from '../../../store.js'
import {useLabelConfigContext} from '../LabelConfigContext.js'

const LABEL_PROPERTY_PACKING_LIST_ID = 'packing_list_id'

export default function PackingList({showUnSetupPackingList = true}) {
  const {labelInfoID, onChange} = useLabelConfigContext()
  const packingListID = useSelector(
    (state) =>
      labelConfigSelector(state, {labelInfoID})[LABEL_PROPERTY_PACKING_LIST_ID],
  )
  const packingLists = useSelector((state) =>
    sortedPackingListsByIDSelector(state),
  )
  const atLeastOneLogoHasBeenSetup = useSelector(
    atLeastOneLogoHasBeenSetupSelector,
  )

  const onChangeLocal = useCallback(
    (value) => {
      updateLabelConfig(labelInfoID, {
        [LABEL_PROPERTY_PACKING_LIST_ID]: value ? Number(value) : null,
      })

      onChange && onChange()
    },
    [labelInfoID, onChange],
  )

  useEffect(() => {
    if (
      packingListID &&
      !packingLists.find(({id}) => id === packingListID) &&
      packingLists.length
    ) {
      onChangeLocal(packingLists[0].id)
    }
  }, [packingListID, packingLists])

  if (
    packingLists.length < 1 ||
    (!showUnSetupPackingList && !atLeastOneLogoHasBeenSetup)
  ) {
    return null
  }

  return (
    <ConfigSelect
      label="Profile"
      onChange={(value) => onChangeLocal(value)}
      noEmptyOption
      options={[
        {value: '', display: 'Use sales channel default'},
        {display: '---------------------------', disabled: true},
        ...packingLists.map(({id, name}) => ({value: id, display: name})),
      ]}
      labelProperty={LABEL_PROPERTY_PACKING_LIST_ID}
    />
  )
}

PackingList.propTypes = {
  showUnSetupPackingList: PropTypes.bool,
}
