import {combineReducers} from 'redux'

import hasLoaded from './hasLoaded.js'
import {currentDateReducer} from '../../../ordoro/CurrentDateListener.js'
import apiKeys from './apiKeys.js'
import commonAppMessage from './commonAppMessage.js'
import productKits from './productKits.js'
import tasks from './tasks.js'
import isRunningTasks from './isRunningTasks.js'
import serverInfo from './serverInfo.js'
import returnOrders from './returnOrders.js'
import returnOrderCodes from './returnOrderCodes.js'

export default combineReducers({
  hasLoaded,
  currentDate: currentDateReducer,
  apiKeys,
  commonAppMessage,
  productKits,
  tasks,
  isRunningTasks,
  serverInfo,
  returnOrders,
  returnOrderCodes,
})
