import className from '../../../common/className.js'
import {useSelector} from '../../../store.js'
import {
  packingListLogoInfoSelector,
  handleLogoFile,
} from './packingListsFunctions.js'

export default function LogoUpload({property}) {
  const {logoURL, error, isUploading} = useSelector((state) =>
    packingListLogoInfoSelector(state, {property}),
  )

  return (
    <div className="divider--top divider--bottom margin-bottom-20">
      <fieldset className="margin-bottom-0">
        <ul className="form-list margin-bottom-0">
          <li className="form-list-item margin-bottom-10">
            <label className="label--logo-upload" htmlFor={property}>
              Logo
            </label>
          </li>
          {logoURL && !error && (
            <li className="form-list-item bigger row margin-bottom-10">
              <div className="medium-12 columns logo-wrap">
                <div className="inner-wrap--logo">
                  <img alt="logo" src={logoURL} />
                </div>
              </div>
            </li>
          )}
          <li className="form-list-item row logo-upload-wrap">
            <div className={className`medium-12 columns end ${{error}}`}>
              <label
                className="file-upload-wrapper btn btn--primary btn--primary-ol btn--sm"
                htmlFor={property}
              >
                <span
                  className="icon icon-upload fs-01 v-align-middle margin-right-3"
                  aria-hidden="true"
                />
                <span>Upload {logoURL && 'Another '}Logo File</span>
                <input
                  type="file"
                  className={className`${{
                    disabled: isUploading,
                    submitting: isUploading,
                  }}`}
                  name="logo_upload"
                  id={property}
                  disabled={isUploading}
                  onChange={(event) =>
                    handleLogoFile(event.target.files[0], property)
                  }
                />
              </label>
              {error && <small className="error error-message">{error}</small>}
              <ul className="helper panel margin-bottom-0">
                <li className="fs-n0 margin-bottom-5">
                  <strong>Accepted image formats:</strong> .jpg, .png and .gif.
                </li>
                <li className="fs-n0 margin-bottom-5">
                  <strong>Rectangular logos:</strong> Should fit within a 300px
                  x 150px region
                </li>
                <li className="fs-n0 margin-bottom-0">
                  <strong>Square logos:</strong> 150px x 150px
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </fieldset>
    </div>
  )
}
