import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import toUpper from 'lodash/toUpper.js'
import get from 'lodash/get.js'
import pick from 'lodash/pick.js'
import reduce from 'lodash/reduce.js'
import intersection from 'lodash/intersection.js'
import omit from 'lodash/omit.js'
import isEqual from 'lodash/isEqual.js'
import chunk from 'lodash/chunk.js'
import uniq from 'lodash/uniq.js'
import uniqBy from 'lodash/uniqBy.js'
import isAfter from 'date-fns/isAfter'
import sortBy from 'lodash/sortBy.js'
import isNil from 'lodash/isNil.js'
import compact from 'lodash/compact.js'
import groupBy from 'lodash/groupBy.js'
import map from 'lodash/map.js'
import round from 'lodash/round.js'
import flatten from 'lodash/flatten.js'
import values from 'lodash/values.js'
import differenceInBusinessDays from 'date-fns/differenceInBusinessDays'
import parse from 'date-fns/parse'
import parseISO from 'date-fns/parseISO'
import isValid from 'date-fns/isValid'
import cloneDeep from 'lodash/cloneDeep.js'

import {
  getState,
  setForm,
  updateForm,
  updateFormObject,
  addFormArrayElement,
  removeFormArrayElement,
  removeForm,
  formsSelector,
} from '../../store.js'
import {combineToOz, formatWeight, splitOz} from '../../common/weight.js'
import apiverson from '../../common/apiverson.js'
import {
  OT_V3_ORDER,
  OT_RETURN_ORDER,
  OT_NONE,
} from '../../common/constants/OrderTypes.js'
import {
  AMAZON_SFP,
  AMAZON_SHIPPER,
  AUSTRALIA_POST,
  CANADA_POST,
  DHL,
  DHL_ECOMMERCE,
  ENDICIA,
  FEDEX,
  NEWGISTICS,
  PITNEY,
  PITNEY_CBDS,
  PITNEY_MERCHANT,
  PITNEY_PRESORT,
  SENDLE,
  UPS,
  VISIBLE_USPS,
  X_DELIVERY,
  SHIPPERS_WITH_POSTAGE_BALANCES,
  SHIPPER_VENDORS,
} from '../../common/constants/ShipperNames.js'
import {
  DEFAULT_BOX_SHAPES,
  CONTENTS_TYPE_MERCHANDISE,
  REASON_FOR_EXPORT_MERCHANDISE,
  SHIPPER_REFERENCE_NUMBER_MAX_LENGTH,
  SHIPPER_INVOICE_NUMBER_MAX_LENGTH,
} from '../../common/constants/LabelConfig.js'
import {
  isNumeric,
  isNonZeroPositiveNumeric,
  isPositiveNumeric,
  isPresent,
  isAddressComplete,
  toNumber,
  isEmptyValue,
  includesSome,
} from '../../common/utils.js'
import {showGlobalError} from '../../ordoro/GlobalErrorMessage.js'
import {
  canIgnoreAmazonPrimeShipperRestrictionSelector,
  canShipAllAmazonWithAmazonSelector,
  currencySymbolSelector,
  useOrderRateV3MultiShipperSelector,
} from '../../data/company.js'
import {
  returnOrdersSelector,
  returnOrderSelector,
  returnOrderCustomerAddressSelector,
  returnOrderShipToAddressSelector,
  defaultPackingListIDFromRMASelector,
} from '../../redux/selectors/data/returnOrders.js'
import {
  updateOrderWarehouse,
  allOrdersSelector,
  orderSelector,
  orderShippingAddressSelector,
  defaultPackingListIDFromOrderNumberSelector,
  isPrimeOrderSelector,
  arePrimeOrdersSelector,
  isAmazonOrderSelector,
  areAllAmazonOrdersSelector,
  orderWarehouseSelector,
  orderSupplierSelector,
  getWarehouseID,
  getSupplierID,
  getIsDropshipped,
} from '../../data/orders.js'
import {accountBalanceSelector} from '../account.js'
import {packingListSelector} from '../packingLists.js'
import ShipperOptions from '../shipperOptions.js'
import {getSupplierName, suppliersSelector} from '../suppliers.js'
import {
  warehousesSortedByNameSelector,
  getWarehouseName,
  warehousesSelector,
} from '../warehouses.js'
import {
  shippersSelector,
  approvedShippersSelector,
  getCanAddPostage,
  usesDiscountedRatesSelector,
  shipperTypeSelector,
  shipperCurrencySymbolSelector,
} from '../shippers.js'
import {shipperBalancesSelector} from '../shipperBalances.js'
import {getRates} from './rateRequest.js'
import {formatCurrency} from '../../common/components/Currency.js'
import {memOutcome} from '../../common/mem.js'

export const LABEL_INFOS = 'LABEL_INFOS'
export const BULK_LABEL_ID = 'BULK_LABEL_ID'
export const BATCH_UPDATE_LABEL_ID = 'BATCH_UPDATE_LABEL_ID'
export const EDIT_PRESET_LABEL_ID = 'EDIT_PRESET_LABEL_ID'
export const EDIT_ADDITIONAL_OPTIONS_LABEL_ID =
  'EDIT_ADDITIONAL_OPTIONS_LABEL_ID'

const CONST_LABEL_IDS = [
  BULK_LABEL_ID,
  BATCH_UPDATE_LABEL_ID,
  EDIT_PRESET_LABEL_ID,
  EDIT_ADDITIONAL_OPTIONS_LABEL_ID,
]

export function labelUseRateShoppingSelector(state, {labelInfoID, fromBulk}) {
  if (fromBulk || labelInfoID === BULK_LABEL_ID) {
    return false
  }

  const useOrderRateV3MultiShipper = useOrderRateV3MultiShipperSelector(state)

  if (!useOrderRateV3MultiShipper) {
    return false
  }

  const orderType = orderTypeSelector(state, {labelInfoID})
  const labelType = labelTypeSelector(state, {labelInfoID})

  return orderType === OT_V3_ORDER && labelType === 'shipping'
}

export function setLabelInfos(labelInfos, {override} = {}) {
  const currentLabelInfosByID = formsSelector(getState())[LABEL_INFOS]

  if (!currentLabelInfosByID || override) {
    const labelInfosByID = keyBy(labelInfos, 'id')
    setForm(LABEL_INFOS, labelInfosByID)
  } else {
    // We store temporary bulk rates info in label info that we want to keep
    const labelInfosByID = labelInfos.reduce((prev, labelInfo) => {
      labelInfo = {
        ...currentLabelInfosByID[labelInfo.id],
        ...labelInfo,
      }

      prev[labelInfo.id] = labelInfo

      return prev
    }, {})

    updateForm(LABEL_INFOS, labelInfosByID)
  }
}

export function removeLabelInfos() {
  removeForm(LABEL_INFOS)
}

export function labelInfosSelector(state) {
  return formsSelector(state)[LABEL_INFOS] || labelInfosSelector.default
}
labelInfosSelector.default = {}

export function labelInfoSelector(state, {labelInfoID}) {
  return labelInfosSelector(state)[labelInfoID] || labelInfoSelector.default
}
labelInfoSelector.default = {}

export function updateLabelInfo(labelInfoID, labelInfo) {
  updateFormObject(LABEL_INFOS, [labelInfoID], labelInfo)
}

export function updateLabelInfos(labelInfosByID) {
  for (const labelInfoID of Object.keys(labelInfosByID)) {
    updateFormObject(LABEL_INFOS, [labelInfoID], labelInfosByID[labelInfoID])
  }
}

export function removeLabelInfo(labelInfoID) {
  const labelInfos = {...labelInfosSelector(getState())}

  if (labelInfos[labelInfoID]) {
    delete labelInfos[labelInfoID]

    setForm(LABEL_INFOS, labelInfos)
  }
}

export function setLabelConfig(labelInfoID, config) {
  updateLabelInfo(labelInfoID, {config})
}

export function updateLabelConfig(labelInfoID, config) {
  updateFormObject(LABEL_INFOS, [labelInfoID, 'config'], config)
}

export function updateLabelConfigs(labelConfigsByLabelInfoID) {
  for (const labelInfoID of Object.keys(labelConfigsByLabelInfoID)) {
    updateFormObject(
      LABEL_INFOS,
      [labelInfoID, 'config'],
      labelConfigsByLabelInfoID[labelInfoID],
    )
  }
}

export function setCustomsInfo(labelInfoID, customs_info) {
  updateLabelConfig(labelInfoID, {customs_info})
}

export function addHazmatItem(labelInfoID, packageIndex, hazmatItem) {
  const hazmatItems = hazmatItemsSelector(getState(), {
    labelInfoID,
    packageIndex,
  })

  if (hazmatItems.length === 0) {
    updateParcel(labelInfoID, packageIndex, {hazmat_items: [hazmatItem]})
  } else {
    addFormArrayElement(
      LABEL_INFOS,
      [labelInfoID, 'config', 'packages', packageIndex, 'hazmat_items'],
      hazmatItem,
    )
  }
}

export function updateHazmatItem(
  labelInfoID,
  packageIndex,
  hazmatItemIndex,
  updates,
) {
  updateFormObject(
    LABEL_INFOS,
    [
      labelInfoID,
      'config',
      'packages',
      packageIndex,
      'hazmat_items',
      hazmatItemIndex,
    ],
    updates,
  )
}

export function removeHazmatItem(labelInfoID, packageIndex, hazmatItemIndex) {
  removeFormArrayElement(
    LABEL_INFOS,
    [labelInfoID, 'config', 'packages', packageIndex, 'hazmat_items'],
    hazmatItemIndex,
  )
}

export function setLabelRates(labelInfoID, rates) {
  updateLabelInfo(labelInfoID, {rates: rates ? {list: rates} : null})
}

export function setLabelErrorMessage(labelInfoID, errorMessage) {
  updateLabelInfo(labelInfoID, {error_message: errorMessage || null})
}

export function startUpdatingRates(labelInfoIDs) {
  updateLabelInfos(
    labelInfoIDs.reduce((prev, labelInfoID) => {
      prev[labelInfoID] = {
        rates_loading: true,
        rates_updated: false,
        departed_shipping_method: null,
      }

      return prev
    }, {}),
  )
}

export function defaultBulkLabelConfigSelector(state, {labelInfoIDs}) {
  const validShippers = validBulkShippersSelector(state, {labelInfoIDs})

  return {
    shipper_id: get(validShippers, '[0].id'),
    packages: [
      {
        ...DEFAULT_BOX_SHAPES,
      },
    ],
  }
}

export function setBulkLabelConfig(labelInfoIDs) {
  const labelConfig = defaultBulkLabelConfigSelector(getState(), {
    labelInfoIDs,
  })
  const labelType = commonLabelTypeFromLabelConfigsSelector(getState(), {
    labelInfoIDs,
  })

  setLabelInfos([{id: BULK_LABEL_ID, config: labelConfig, type: labelType}])
}

export function resetBulkRates(labelInfoIDs) {
  updateLabelInfos(
    labelInfoIDs.reduce((prev, labelInfoID) => {
      prev[labelInfoID] = {
        bulk_rates: null,
        bulk_rates_is_loading: false,
      }

      return prev
    }, {}),
  )
}

export function setBulkRatesLoading(labelInfoIDs, isLoading) {
  updateLabelInfos(
    labelInfoIDs.reduce((prev, labelInfoID) => {
      prev[labelInfoID] = {
        bulk_rates_is_loading: isLoading,
      }

      return prev
    }, {}),
  )
}

export function updateLabelRate(labelInfoID, shipperType, rate) {
  const labelConfig = labelConfigSelector(getState(), {labelInfoID})

  shipperType = rate.vendor || shipperType
  const packages = cloneDeep(labelConfig.packages || [])

  // box_type might be a comma separated list of all package box shapes
  let boxTypes = (rate.box_type || '')
    .split(',')
    .map((v) => v.trim())
    .filter((v) => v)
  const oldShippingMethod = shippingMethodSelector(getState(), {
    labelInfoID,
  })
  const boxShapeOptions = get(ShipperOptions, [shipperType, 'box_shape'], [])

  // if we didn't get anything then pull them from the packages array
  if (!boxTypes.length) {
    boxTypes = packages
      .map((p) => p[`${shipperType}__box_shape`])
      .filter((v) => v)
  }

  if (boxTypes.length) {
    for (const index of packages.keys()) {
      // if old shipping method was UPS next day air or there is not box type at this index
      // then use first box type. This resets packages to use all the same box types
      const boxType =
        (shipperType === UPS && oldShippingMethod === '01') || !boxTypes[index]
          ? boxTypes[0]
          : boxTypes[index]

      // only use box shapes that are valid and do not unset box_shape
      if (boxType && boxShapeOptions.find(({value}) => value === boxType)) {
        packages[index][`${shipperType}__box_shape`] = boxType
      }
    }
  }

  const updates = {
    [`${shipperType}__shipping_method`]: rate.service_type,
    [`${shipperType}__rate_key`]: rate.key,
    packages,
  }

  if (rate.shipper_id) {
    updates.shipper_id = rate.shipper_id
  }

  return updates
}

export async function setLabelRate(labelInfoID, rate) {
  if (!rate) {
    return
  }

  try {
    const shipperType =
      rate.vendor || labelShipperTypeSelector(getState(), {labelInfoID})

    const updates = updateLabelRate(labelInfoID, shipperType, rate)

    updateLabelConfig(labelInfoID, updates)
    updateLabelInfo(labelInfoID, {
      departed_shipping_method: null,
    })
  } catch (err) {
    updateLabelConfig(labelInfoID, {
      error_message: err.error_message || err.message,
    })
  }

  await saveLabelInfo(labelInfoID)
}

export async function updateShipperID(labelInfoID, shipperID) {
  try {
    updateLabelConfig(labelInfoID, {shipper_id: shipperID})

    const packages = packagesSelector(getState(), {labelInfoID})
    const shipperType = shipperTypeSelector(getState(), {shipperID})
    const maxParcelCount = getMaxParcelCount(shipperType)

    if (packages.length > maxParcelCount) {
      const newPackages = packages.slice(0, maxParcelCount)

      updateLabelConfig(labelInfoID, {packages: newPackages})
    }

    await getRates([labelInfoID])
  } catch (err) {
    updateLabelConfig(labelInfoID, {
      error_message: err.error_message || err.message,
    })
  }
}

const AmazonShippingMethods = ShipperOptions.amazon.shipping_method.map(
  (option) => option.value,
)

export function assertAmazonPrimeSanity(labelInfoID, labelRequestParams) {
  const canIgnoreAmazonPrimeShipperRestriction =
    canIgnoreAmazonPrimeShipperRestrictionSelector(getState())
  if (canIgnoreAmazonPrimeShipperRestriction) {
    return
  }

  const orderNumber = orderNumberFromLabelConfigSelector(getState(), {
    labelInfoID,
  })
  const shipperType = labelShipperTypeSelector(getState(), {labelInfoID})

  const isPrimeOrder = isPrimeOrderSelector(getState(), {orderNumber})

  if (isPrimeOrder && shipperType !== AMAZON_SFP) {
    throw new Error('Amazon Prime order has non-Amazon shipper')
  }

  if (
    isPrimeOrder &&
    !AmazonShippingMethods.includes(labelRequestParams.shipping_method)
  ) {
    throw new Error('Amazon Prime order has non-Amazon shipping method')
  }
}

export async function refreshShippingAndReturnRates(orderNumber) {
  try {
    await getRates([
      labelInfoIDForOrderAndLabelTypeSelector(getState(), {
        orderNumber,
        labelType: 'return',
      }),
      labelInfoIDForOrderAndLabelTypeSelector(getState(), {
        orderNumber,
        labelType: 'shipping',
      }),
    ])
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error refreshing shipping and return label rates.',
        details: err.message,
      },
      err,
    )
  }
}

export async function refreshReturnOrderRates(referenceID) {
  try {
    await getRates(labelInfoIDsFromReferenceID(getState(), {referenceID}))
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error refreshing return label rates.',
        details: err.message,
      },
      err,
    )
  }
}

export async function loadLabelImageBatch(batch) {
  const {json} = await apiverson.get('/label_info', {id: batch})

  setLabelInfos(json.label_info)
}

export async function ensureLabelInfosLoaded(labelInfoIDs, {reload} = {}) {
  try {
    const labelInfos = labelInfosSelector(getState())
    const stillNeeded = uniq(
      reload
        ? labelInfoIDs
        : labelInfoIDs.filter((labelInfoID) => !labelInfos[labelInfoID]),
    )
    if (!stillNeeded.length) {
      return
    }
    const batches = chunk(stillNeeded, 50)
    await Promise.all(batches.map((batch) => loadLabelImageBatch(batch)))
  } catch (err) {
    showGlobalError(
      {
        summary: 'Something went wrong while getting labelInfos.',
        details: err.message,
      },
      err,
    )
  }
}

export async function postOrderLabelInfo(labelInfo) {
  const orderNumber = labelInfo.orders[0]
  const data = pick(labelInfo, ['config', 'rates', 'error_message', 'type'])

  const {json} = await apiverson.post(
    `/label_info/order/${encodeURIComponent(orderNumber)}`,
    data,
  )

  return json
}

export async function saveLabelInfo(labelInfoID, {clearRates} = {}) {
  // If labelInfoID is not a number
  // then this isn't a real labelInfo
  // so don't save it
  if (isNaN(Number(labelInfoID))) {
    return
  }

  const labelInfo = labelInfoSelector(getState(), {labelInfoID})

  const {json} = await apiverson.put(`/label_info/${labelInfo.id}`, {
    config: labelInfo.config,
    rates: clearRates || !labelInfo.rates ? null : labelInfo.rates,
    error_message: labelInfo.error_message || null,
  })

  // Just update the updated value. The user might be changing lots of things and we don't want to
  // step on those changes by overriding with the whole payload
  // We need the updated value "updated" for websocket messages.
  updateLabelInfo(json.id, {updated: json.updated})
}

export async function applyShipFrom(labelInfoID, warehouseID) {
  if (warehouseID) {
    const orderType = orderTypeSelector(getState(), {labelInfoID})

    if (orderType === OT_V3_ORDER) {
      const orderNumber = orderNumberFromLabelConfigSelector(getState(), {
        labelInfoID,
      })

      await updateOrderWarehouse(orderNumber, warehouseID)
    }
  }

  const {packages, ...labelConfig} = customsDefaultsSelector(getState(), {
    labelInfoID,
  })

  for (
    let packagesIndex = 0;
    packagesIndex < packages.length;
    packagesIndex++
  ) {
    const parcel = packages[packagesIndex]

    updateParcel(labelInfoID, packagesIndex, parcel)
  }

  updateLabelConfig(labelInfoID, labelConfig)

  await getRates([labelInfoID])
}

export async function resetShipFrom(labelInfoIDs) {
  const labelConfigs = {}
  for (const labelInfoID of labelInfoIDs) {
    const {packages, ...labelConfig} = shipFromDefaultsSelector(getState(), {
      labelInfoID,
    })

    labelConfigs[labelInfoID] = labelConfig

    for (
      let packagesIndex = 0;
      packagesIndex < packages.length;
      packagesIndex++
    ) {
      const parcel = packages[packagesIndex]

      updateParcel(labelInfoID, packagesIndex, parcel)
    }
  }

  updateLabelConfigs(labelConfigs)

  await getRates(labelInfoIDs)
}

export async function updateWeight(labelInfoID, packagesIndex, weight) {
  try {
    const orderNumber = orderNumberFromLabelConfigSelector(getState(), {
      labelInfoID,
    })

    if (orderNumber) {
      const [shippingLabelInfoID, returnLabelInfoID] =
        labelInfoIDsForOrdersAndLabelTypesSelector(getState(), {
          orderNumbers: [orderNumber],
          labelTypes: ['shipping', 'return'],
        })
      const shippingPackages = packagesSelector(getState(), {
        labelInfoID: shippingLabelInfoID,
      })
      const returnPackages = packagesSelector(getState(), {
        labelInfoID: returnLabelInfoID,
      })

      // We need to forward the weight to the respective parcel in the other label config
      // We only do this if both label configs contain the same amount of parcels
      if (shippingPackages.length === returnPackages.length) {
        updateParcel(shippingLabelInfoID, packagesIndex, {weight})
        updateParcel(returnLabelInfoID, packagesIndex, {weight})
      } else {
        updateParcel(labelInfoID, packagesIndex, {weight})
      }
    } else {
      updateParcel(labelInfoID, packagesIndex, {weight})
    }

    getRates([labelInfoID])
  } catch (err) {
    updateLabelConfig(labelInfoID, {
      error_message: err.error_message || err.message,
    })
  }
}

export function updateParcel(labelInfoID, index, parcel) {
  updateFormObject(
    LABEL_INFOS,
    [labelInfoID, 'config', 'packages', index],
    parcel,
  )
}

export async function addParcel(labelInfoID) {
  try {
    const packages = packagesSelector(getState(), {labelInfoID})

    // clone the last element and add to the end
    addFormArrayElement(LABEL_INFOS, [labelInfoID, 'config', 'packages'], {
      ...packages[packages.length - 1],
    })

    getRates([labelInfoID])
  } catch (err) {
    updateLabelConfig(labelInfoID, {
      error_message: err.error_message || err.message,
    })
  }
}

export async function removeParcel(labelInfoID, packagesIndex) {
  try {
    removeFormArrayElement(
      LABEL_INFOS,
      [labelInfoID, 'config', 'packages'],
      packagesIndex,
    )

    getRates([labelInfoID])
  } catch (err) {
    updateLabelConfig(labelInfoID, {
      error_message: err.error_message || err.message,
    })
  }
}

export function updateCustomsLine(labelInfoID, index, customLine) {
  updateFormObject(
    LABEL_INFOS,
    [labelInfoID, 'config', 'customs_info', index],
    customLine,
  )
}

export function addCustomsLine(labelInfoID, customLine) {
  addFormArrayElement(
    LABEL_INFOS,
    [labelInfoID, 'config', 'customs_info'],
    customLine,
  )
}

export function removeCustomsLine(labelInfoID, index) {
  removeFormArrayElement(
    LABEL_INFOS,
    [labelInfoID, 'config', 'customs_info'],
    index,
  )
}

export async function getMissingRates(labelInfoIDs) {
  try {
    const missingRates = missingRatesSelector(getState(), {labelInfoIDs})

    if (!missingRates.length) {
      return
    }

    await getRates(missingRates)
  } catch (err) {
    showGlobalError(
      {
        summary: 'Error getting missing rates.',
        details: err.message,
      },
      err,
    )
  }
}

export function labelInfosFromLabelInfoIDsSelector(state, {labelInfoIDs}) {
  return labelInfoIDs.map((labelInfoID) =>
    labelInfoSelector(state, {labelInfoID}),
  )
}

export function labelConfigSelector(state, {labelInfoID}) {
  const labelInfo = labelInfoSelector(state, {labelInfoID})

  return get(labelInfo, 'config', {})
}

export function labelConfigsFromLabelInfoIDsSelector(state, {labelInfoIDs}) {
  return labelInfoIDs.map((labelInfoID) =>
    labelConfigSelector(state, {labelInfoID}),
  )
}

export function hasLabelConfigLoadedSelector(state, {labelInfoID}) {
  const labelInfos = labelInfosSelector(state)

  return !!get(labelInfos, labelInfoID)
}

export function labelInfoIDsFromOrderNumber(state, {orderNumber}) {
  const labelInfos = labelInfosSelector(state)

  return reduce(
    labelInfos,
    (prev, labelInfo) => {
      if (get(labelInfos, [labelInfo.id, 'orders'], []).includes(orderNumber)) {
        prev.push(labelInfo.id)
      }

      return prev
    },
    [],
  )
}

export function labelInfoIDsFromReferenceID(state, {referenceID}) {
  const labelInfos = labelInfosSelector(state)

  return reduce(
    labelInfos,
    (prev, labelInfo) => {
      if (
        get(labelInfos, [labelInfo.id, 'return_orders'], []).includes(
          referenceID,
        )
      ) {
        prev.push(labelInfo.id)
      }

      return prev
    },
    [],
  )
}

export const makeLabelInfoIDForOrderAndLabelTypeSelector = () =>
  createSelector(
    labelInfosSelector,
    (_, {orderNumber}) => orderNumber,
    (_, {labelType}) => labelType,
    (labelInfos, orderNumber, labelType) => {
      const matching = Object.values(labelInfos).find((labelInfo) => {
        return (
          get(labelInfos, [labelInfo.id, 'orders'], []).includes(orderNumber) &&
          getLabelType(labelInfo) === labelType
        )
      })
      return matching ? matching.id : null
    },
  )

export function labelInfoIDForOrderAndLabelTypeSelector(
  state,
  {orderNumber, labelType},
) {
  const labelInfoIDs = labelInfoIDsFromOrderNumber(state, {orderNumber})

  return (
    labelInfoIDs.find(
      (labelInfoID) => labelTypeSelector(state, {labelInfoID}) === labelType,
    ) || null
  )
}

export function labelInfoIDsForOrdersAndLabelTypesSelector(
  state,
  {orderNumbers, labelTypes},
) {
  const labelInfos = labelInfosSelector(state)

  return reduce(
    labelInfos,
    (prev, labelInfo) => {
      if (
        intersection(labelInfo.orders, orderNumbers).length > 0 &&
        labelTypes.includes(labelInfo.type)
      ) {
        prev.push(labelInfo.id)
      }

      return prev
    },
    [],
  )
}

export function orderTypeSelector(state, {labelInfoID}) {
  const orderNumber = orderNumberFromLabelConfigSelector(state, {labelInfoID})

  if (orderNumber) {
    return OT_V3_ORDER
  }

  const referenceID = referenceIDFromLabelConfigSelector(state, {labelInfoID})

  if (referenceID) {
    return OT_RETURN_ORDER
  }

  return OT_NONE
}

export function labelTypeSelector(state, {labelInfoID}) {
  const labelInfo = labelInfoSelector(state, {labelInfoID})

  return getLabelType(labelInfo)
}

export function getLabelType(labelInfo) {
  return labelInfo.type
}

export function shipperIDsSelector(state, {labelInfoID}) {
  const labelConfig = labelConfigSelector(state, {labelInfoID})

  return labelConfig.shipper_ids || shipperIDsSelector.default
}
shipperIDsSelector.default = []

export function labelShipperIDSelector(state, {labelInfoID}) {
  const labelConfig = labelConfigSelector(state, {labelInfoID})

  return labelConfig.shipper_id
}

export function labelShipperSelector(state, {labelInfoID}) {
  const shipperID = labelShipperIDSelector(state, {labelInfoID})
  const shippers = shippersSelector(state)

  return shipperID ? shippers[shipperID] : null
}

function getLabelShippers(labelUseRateShopping, shipper, shippers, shipperIDs) {
  if (!labelUseRateShopping) {
    return shipper ? [shipper] : []
  }

  return shipperIDs.map((shipperID) => shippers[shipperID])
}

export function labelShippersSelector(state, {labelInfoID}) {
  const labelUseRateShopping = labelUseRateShoppingSelector(state, {
    labelInfoID,
  })
  const shipper = labelShipperSelector(state, {labelInfoID})
  const shippers = shippersSelector(state)
  const shipperIDs = shipperIDsSelector(state, {labelInfoID})

  return labelShippersSelector.memFunc(
    labelUseRateShopping,
    shipper,
    shippers,
    shipperIDs,
  )
}
labelShippersSelector.memFunc = memOutcome(getLabelShippers)

export const makeLabelShipperNameSelector = () =>
  createSelector(labelShipperSelector, (shipper) => shipper && shipper.name)

export function labelShipperTypeSelector(state, {labelInfoID}) {
  const shipper = labelShipperSelector(state, {labelInfoID})

  return shipper ? shipper.vendor : undefined
}

export function shipperTypesSelector(state, {labelInfoID}) {
  const shippers = labelShippersSelector(state, {labelInfoID})

  return Object.keys(
    shippers.reduce((prev, shipper) => {
      prev[shipper.vendor] = true

      return prev
    }, {}),
  )
}

export const canAddPostageSelector = createSelector(
  labelShipperTypeSelector,
  (shipperType) => getCanAddPostage(shipperType),
)

export const labelBalanceSelector = createSelector(
  labelShipperSelector,
  labelShipperTypeSelector,
  shipperBalancesSelector,
  accountBalanceSelector,
  (shipper, shipperType, shipperBalances, accountBalance) => {
    if (shipperType === ENDICIA) {
      return (
        shipperBalances[shipper.id] &&
        shipperBalances[shipper.id].postage_balance
      )
    }
    if (shipperType === PITNEY_MERCHANT) {
      return shipperBalances[shipper.id] && shipperBalances[shipper.id].balance
    }
    if ([PITNEY, VISIBLE_USPS].includes(shipperType)) {
      return accountBalance
    }
    return null
  },
)

export const shippingAddressSelector = createSelector(
  labelConfigSelector,
  (labelConfig) => labelConfig.shipping_address,
)

export const shippingMethodSelector = createSelector(
  labelConfigSelector,
  labelShipperTypeSelector,
  (labelConfig, shipperType) => labelConfig[`${shipperType}__shipping_method`],
)

export const makeShippingMethodSelector = () => shippingMethodSelector

export function rateKeySelector(state, {labelInfoID}) {
  const labelConfig = labelConfigSelector(state, {labelInfoID})
  const shipperType = labelShipperTypeSelector(state, {labelInfoID})

  return labelConfig[`${shipperType}__rate_key`]
}

export function packagesSelector(state, {labelInfoID}) {
  const config = labelConfigSelector(state, {labelInfoID})

  return get(config, 'packages', [])
}

export function parcelSelector(state, {labelInfoID, packagesIndex}) {
  if (packagesIndex === undefined) {
    throw new Error('packagesIndex not provided in parcelSelector')
  }

  const packages = packagesSelector(state, {labelInfoID})

  return packages[packagesIndex] || {}
}

export function boxShapeSelector(
  state,
  {labelInfoID, shipperType, packagesIndex},
) {
  const parcel = parcelSelector(state, {labelInfoID, packagesIndex})

  return get(parcel, `${shipperType}__box_shape`)
}

export function boxShapesByShipperTypeSelector(state, {labelInfoID}) {
  const shipperTypes = shipperTypesSelector(state, {labelInfoID})
  const config = labelConfigSelector(state, {labelInfoID})
  const labelUseRateShopping = labelUseRateShoppingSelector(state, {
    labelInfoID,
  })

  if (shipperTypes.length === 0) {
    return boxShapesByShipperTypeSelector.default
  }

  if (!labelUseRateShopping) {
    const shipperType = shipperTypes[0]
    const boxShape = boxShapeSelector(state, {
      labelInfoID,
      shipperType,
      packagesIndex: 0,
    })

    return {
      [shipperType]: boxShape ? [boxShape] : [],
    }
  }

  return shipperTypes.reduce((prev, shipperType) => {
    prev[shipperType] = get(config, `${shipperType}__box_shapes`, [])

    return prev
  }, {})
}
boxShapesByShipperTypeSelector.default = {}

export function boxShapesForShipperTypeSelector(
  state,
  {labelInfoID, shipperType, packagesIndex},
) {
  const labelUseRateShopping = labelUseRateShoppingSelector(state, {
    labelInfoID,
  })

  if (!labelUseRateShopping) {
    return [boxShapeSelector(state, {labelInfoID, shipperType, packagesIndex})]
  }

  const config = labelConfigSelector(state, {labelInfoID})

  return get(config, `${shipperType}__box_shapes`, [])
}

export function labelShipFromAddressSelector(state, {labelInfoID}) {
  const orderType = orderTypeSelector(state, {labelInfoID})
  let address = {}

  if (orderType === OT_V3_ORDER) {
    const orderNumber = orderNumberFromLabelConfigSelector(state, {labelInfoID})
    const order = orderSelector(state, {orderNumber})

    if (order.alternate_ship_from_address) {
      return omit(order.alternate_ship_from_address, ['validation'])
    }

    const {do_not_ship_from_supplier} = labelConfigSelector(state, {
      labelInfoID,
    })
    const warehouse = orderWarehouseSelector(state, {orderNumber})
    const supplier = orderSupplierSelector(state, {orderNumber})
    const packingListID =
      labelPackingListIDSelector(state, {labelInfoID}) ||
      defaultPackingListIDFromOrderNumberSelector(state, {
        orderNumber,
      })
    address =
      (!do_not_ship_from_supplier && supplier ? supplier : warehouse || {})
        .address || {}

    address = switchShipFromNameSelector(state, {packingListID, address})
  } else if (orderType === OT_RETURN_ORDER) {
    const referenceID = referenceIDFromLabelConfigSelector(state, {labelInfoID})
    address = returnOrderCustomerAddressSelector(state, {
      referenceID,
    })
  }

  address = omit(address, ['validation'])

  return address
}

export function labelShipToAddressSelector(state, {labelInfoID}) {
  const orderType = orderTypeSelector(state, {labelInfoID})
  let address = {}

  if (orderType === OT_V3_ORDER) {
    const orderNumber = orderNumberFromLabelConfigSelector(state, {labelInfoID})
    address = orderShippingAddressSelector(state, {orderNumber})
  } else if (orderType === OT_RETURN_ORDER) {
    const referenceID = referenceIDFromLabelConfigSelector(state, {labelInfoID})
    address = returnOrderShipToAddressSelector(state, {
      referenceID,
    })
    const packingListID =
      labelPackingListIDSelector(state, {labelInfoID}) ||
      defaultPackingListIDFromRMASelector(state, {
        referenceID,
      })

    address = switchShipFromNameSelector(state, {packingListID, address})
  }

  address = omit(address, ['validation'])

  return address
}

function switchShipFromNameSelector(state, {packingListID, address}) {
  const packingList = packingListSelector(state, {packingListID})
  const shipFromAlias = get(packingList, 'ship_from_alias_name')
  const name = shipFromAlias || address.name

  return {
    ...address,
    name,
  }
}

export function shipperVendorConfigPropertySelector(
  state,
  {labelInfoID, shipperType, property},
) {
  const shippers = labelShippersSelector(state, {labelInfoID})

  // we need to look for this value in any shipper selected
  // this isn't the most perfect solution, just the only thing
  // that works with the information we have
  return shippers.reduce((prev, shipper) => {
    // once we get shipper we are done
    if (prev[1]) {
      return prev
    }

    // focus only on this shipperType (if present)
    if (shipperType && shipper.vendor !== shipperType) {
      return prev
    }

    const value = get(shipper, ['vendor_config', property])

    // first non empty value gets picked
    if (!isEmptyValue(value)) {
      prev = [value, shipper]
    }

    return prev
  }, [])
}

export function labelPackingListIDSelector(state, {labelInfoID}) {
  const labelConfig = labelConfigSelector(state, {labelInfoID})

  return get(labelConfig, 'packing_list_id') || null
}

export function declaredValueSelector(
  state,
  {labelInfoID, shipperType, packagesIndex},
) {
  const parcel = parcelSelector(state, {labelInfoID, packagesIndex})

  return get(parcel, `${shipperType}__declared_value`)
}

export const customsInfoSelector = createSelector(
  labelConfigSelector,
  (labelConfig) => labelConfig.customs_info || [],
)

export function hazmatItemsSelector(state, {labelInfoID, packageIndex}) {
  const config = labelConfigSelector(state, {labelInfoID})

  return get(config, ['packages', packageIndex, 'hazmat_items']) || []
}

export function canHaveHazMatItemsSelector(state, {labelInfoID, shipperType}) {
  const labelShippers = labelShippersSelector(state, {labelInfoID})
  const shippersOfShipperType = labelShippers.filter(
    ({vendor}) => vendor === shipperType,
  )
  // look for any UPS shippers that are NOT DAP
  const usesDiscountedRates = !shippersOfShipperType.find(
    (shipper) => !usesDiscountedRatesSelector(state, {shipperID: shipper.id}),
  )
  const boxShapes = boxShapesForShipperTypeSelector(state, {
    labelInfoID,
    shipperType,
    packagesIndex: 0,
  })
  const labelType = labelTypeSelector(state, {labelInfoID})

  return (
    !usesDiscountedRates &&
    shipperType === UPS &&
    !boxShapes.includes('01') && // Do not allow Letter to setup Hazmat
    labelType !== 'return'
  )
}

export function maxHazmatItemCountSelector(state, {labelInfoID, shipperType}) {
  if (canHaveHazMatItemsSelector(state, {labelInfoID, shipperType})) {
    return 3
  }

  return 0
}

export function canAddHazmatItemSelector(
  state,
  {labelInfoID, shipperType, packageIndex},
) {
  const hazmatItems = hazmatItemsSelector(state, {labelInfoID, packageIndex})
  const maxHazmatItemCount = maxHazmatItemCountSelector(state, {
    labelInfoID,
    shipperType,
  })

  return hazmatItems.length < maxHazmatItemCount
}

const RequiredKeys = ['shipper_id']
const RequiredKeysByShipper = {
  [UPS]: [...RequiredKeys, 'ups__box_shape'],
  [FEDEX]: [...RequiredKeys, 'fedex__box_shape'],
  [ENDICIA]: [...RequiredKeys, 'endicia__box_shape'],
  [PITNEY]: [...RequiredKeys, 'pitney__box_shape'],
  [PITNEY_MERCHANT]: [...RequiredKeys, 'pitney_merchant__box_shape'],
  [PITNEY_PRESORT]: [...RequiredKeys, 'pitney_presort__box_shape'],
  [VISIBLE_USPS]: [...RequiredKeys, 'visible_usps__box_shape'],
  [NEWGISTICS]: [...RequiredKeys, 'newgistics__box_shape'],
  [CANADA_POST]: [
    ...RequiredKeys,
    'canada_post__box_shape',
    'canada_post__reason_for_export',
    'canada_post__nondelivery_option',
  ],
  [DHL]: [...RequiredKeys, 'dhl__box_shape'],
  [AMAZON_SFP]: [...RequiredKeys, 'amazon__box_shape'],
}

export function missingKeysSelector(state, {labelInfoID}) {
  const labelUseRateShopping = labelUseRateShoppingSelector(state, {
    labelInfoID,
  })
  if (labelUseRateShopping) {
    return []
  }

  const labelConfig = labelConfigSelector(state, {labelInfoID})
  const shipperType = labelShipperTypeSelector(state, {labelInfoID})

  const required = RequiredKeysByShipper[shipperType] || RequiredKeys

  return required.reduce((missingKeys, key) => {
    const value = labelConfig[key] || get(labelConfig, ['packages', 0, key])

    if ([null, undefined].includes(value)) {
      return [...missingKeys, key]
    }

    return missingKeys
  }, [])
}

export function sumWeight(labelConfig) {
  return get(labelConfig, 'packages', []).reduce(
    (sum, {weight}) => sum + (weight || 0),
    0,
  )
}

export function weightSelector(state, {labelInfoID}) {
  const labelConfig = labelConfigSelector(state, {labelInfoID})

  return sumWeight(labelConfig)
}

export function maxWeightSelector(state, {labelInfoIDs}) {
  const labelConfigs = labelConfigsFromLabelInfoIDsSelector(state, {
    labelInfoIDs,
  })

  const weights = labelConfigs.map((labelConfig) => sumWeight(labelConfig))

  return Math.max(...weights, 0)
}

export const packagingWeightSelector = createSelector(
  labelConfigSelector,
  (labelConfig) => labelConfig.packaging_weight || 0,
)

export function getTotalPackageWeight(weight, labelConfig) {
  return weight + Number(labelConfig.packaging_weight || 0)
}

export const isInvalidWeightSelector = createSelector(
  weightSelector,
  (weight) => weight <= 0,
)

export function dimensionsSelector(state, {labelInfoID, packagesIndex = 0}) {
  const packages = packagesSelector(state, {labelInfoID})

  return pick(get(packages, packagesIndex, {}), ['length', 'width', 'height'])
}

export const formattedDimensionsSelector = createSelector(
  dimensionsSelector,
  ({length, width, height}) =>
    length && width && height ? `${length} x ${width} x ${height} in` : '',
)

export const attachCustomsInfoSelector = createSelector(
  labelConfigSelector,
  (config) => config.attach_customs_info,
)

export const attachHazmatInfoSelector = createSelector(
  labelConfigSelector,
  (config) => config.attach_hazmat_info,
)

export function taxInfoPropertySelector(state, {labelInfoID, shipperType}) {
  const labelConfig = labelConfigSelector(state, {
    labelInfoID,
  })

  return shipperType === DHL_ECOMMERCE ||
    ([DHL, UPS].includes(shipperType) &&
      labelConfig.receiver_tax_info__tax_id_number)
    ? 'receiver_tax_info'
    : 'sender_tax_info'
}

function isTerritoryValue(value) {
  return ['AS', 'GU', 'PR', 'VI', 'MH', 'FM', 'PW'].includes(toUpper(value))
}

function getIsTerritoryCrossBorder(
  shipToState,
  shipToCountry,
  warehouseState,
  warehouseCountry,
  shipperType,
) {
  const isTerritoryState = isTerritoryValue(shipToState)
  const isTerritoryCountry = isTerritoryValue(shipToCountry)

  if (!isTerritoryState && !isTerritoryCountry) {
    return false
  }

  if (shipToCountry === warehouseCountry && shipToState === warehouseState) {
    return false
  }

  const isTerritoryCrossBorder = isTerritoryState || isTerritoryCountry

  if (
    shipperType === ENDICIA &&
    isTerritoryCrossBorder &&
    [shipToState, shipToCountry].includes('PR')
  ) {
    return false
  }

  return isTerritoryCrossBorder
}

export function getIsInternationalLabel(
  shipToAddress,
  shipFromAddress,
  shipperType,
) {
  if (
    !shipToAddress ||
    !shipToAddress.country ||
    !shipFromAddress ||
    !shipFromAddress.country
  ) {
    return false
  }

  const shipToCity = toUpper(shipToAddress.city)
  const shipToState = toUpper(shipToAddress.state)
  const shipToCountry = toUpper(shipToAddress.country)
  const warehouseCountry = toUpper(shipFromAddress.country)
  const warehouseState = toUpper(shipFromAddress.state)

  const isDomestic = shipToCountry === warehouseCountry
  const isInternational = !isDomestic

  const isMilitaryCity = ['APO', 'FPO', 'DPO'].includes(shipToCity)
  const isMilitaryState = ['AE', 'AP', 'AA'].includes(shipToState)
  const isTerritoryCrossBorder = getIsTerritoryCrossBorder(
    shipToState,
    shipToCountry,
    warehouseState,
    warehouseCountry,
    shipperType,
  )

  return (
    isInternational ||
    isMilitaryCity ||
    isMilitaryState ||
    isTerritoryCrossBorder
  )
}

export function isInternationalLabelSelector(
  state,
  {labelInfoID, shipperType},
) {
  const shippingAddress = shippingAddressSelector(state, {labelInfoID})
  const labelShipFromAddress = labelShipFromAddressSelector(state, {
    labelInfoID,
  })

  return getIsInternationalLabel(
    shippingAddress,
    labelShipFromAddress,
    shipperType,
  )
}

export function getShouldAttachCustomsInfo(
  shipToAddress,
  shipFromAddress,
  shipperType,
) {
  return getIsInternationalLabel(shipToAddress, shipFromAddress, shipperType)
}

export function getCanHaveDeliveryConfirmation(
  shipToAddress,
  warehouseAddress,
) {
  if (!shipToAddress.country || !warehouseAddress.country) {
    return false
  }

  const shipToCountry = toUpper(shipToAddress.country)
  const warehouseCountry = toUpper(warehouseAddress.country)

  if (
    ['US', 'PR'].includes(shipToCountry) &&
    ['US', 'PR'].includes(warehouseCountry)
  ) {
    return true
  }

  if (shipToCountry === 'CA' && warehouseCountry === 'CA') {
    return true
  }

  return false
}

export function getShouldHaveCertificateOfOrigin(
  shipToAddress,
  warehouseAddress,
  declaredValue,
) {
  if (
    !shipToAddress.country ||
    !warehouseAddress.country ||
    !isNumeric(declaredValue)
  ) {
    return false
  }

  const shipToCountry = toUpper(shipToAddress.country)
  const warehouseCountry = toUpper(warehouseAddress.country)
  const value = Number(declaredValue)

  if (
    value >= 1000 &&
    shipToCountry === 'MX' &&
    ['US', 'CA'].includes(warehouseCountry)
  ) {
    return true
  }

  if (
    value >= 2500 &&
    shipToCountry === 'CA' &&
    ['US', 'MX'].includes(warehouseCountry)
  ) {
    return true
  }

  if (
    value >= 2500 &&
    shipToCountry === 'US' &&
    ['CA', 'MX'].includes(warehouseCountry)
  ) {
    return true
  }

  return false
}

export function isCommercialAddress(address) {
  if (
    !address ||
    !address.validation ||
    !address.validation.suggested ||
    !address.validation.suggested.length
  ) {
    return false
  }

  return address.validation.suggested[0].is_commercial
}

export function orderNumberFromLabelConfigSelector(state, {labelInfoID}) {
  const labelInfo = labelInfoSelector(state, {labelInfoID})

  return get(labelInfo, ['orders', 0])
}

export function orderFromLabelInfoIDSelector(state, {labelInfoID}) {
  const orderNumber = orderNumberFromLabelConfigSelector(state, {labelInfoID})

  return orderSelector(state, {orderNumber})
}

export function orderNumbersFromLabelConfigsSelector(state, {labelInfoIDs}) {
  return labelInfoIDs.map((labelInfoID) =>
    orderNumberFromLabelConfigSelector(state, {labelInfoID}),
  )
}

export function referenceIDFromLabelConfigSelector(state, {labelInfoID}) {
  const labelInfo = labelInfoSelector(state, {labelInfoID})

  return get(labelInfo, ['return_orders', 0])
}

export function returnOrderFromLabelInfoIDSelector(state, {labelInfoID}) {
  const referenceID = referenceIDFromLabelConfigSelector(state, {labelInfoID})

  return returnOrderSelector(state, {referenceID})
}

export function referenceIDsFromLabelConfigsSelector(state, {labelInfoIDs}) {
  return labelInfoIDs.map((labelInfoID) =>
    referenceIDFromLabelConfigSelector(state, {labelInfoID}),
  )
}

// HACK We have some situations that require having a single labelType for a group of labelInfoIDs
// Before we can refactor all things that need this (abode), we can just say whatever labelType
// is the first. In 100% of the cases as of now, all labelInfoIDs will have the
// same labelType (either shipping or return)
export function commonLabelTypeFromLabelConfigsSelector(state, {labelInfoIDs}) {
  const labelInfoID = labelInfoIDs[0]

  return labelInfoID ? labelTypeSelector(state, {labelInfoID}) : ''
}

function getValidShippers(
  activeShippers,
  isPrimeOrder,
  canIgnoreAmazonPrimeShipperRestriction,
  isAmazonOrder,
  canShipAllAmazonWithAmazon,
  labelType,
  orderType,
  isPresetLabelConfig,
) {
  if (isPresetLabelConfig) {
    return activeShippers
  }

  // Do not display AMAZON_SFP and AMAZON_SHIPPER at the same time
  if (isPrimeOrder) {
    if (canIgnoreAmazonPrimeShipperRestriction) {
      activeShippers = activeShippers.filter(
        (shipper) => shipper.vendor !== AMAZON_SHIPPER,
      )
    } else {
      activeShippers = activeShippers.filter(
        (shipper) => shipper.vendor === AMAZON_SFP,
      )
    }
  } else if (!isAmazonOrder || !canShipAllAmazonWithAmazon) {
    activeShippers = activeShippers.filter(
      (shipper) => shipper.vendor !== AMAZON_SFP,
    )
  }

  if (
    orderType === OT_RETURN_ORDER ||
    (orderType === OT_V3_ORDER && labelType === 'return')
  ) {
    activeShippers = activeShippers.filter(
      (shipper) =>
        ![X_DELIVERY, SENDLE, AMAZON_SHIPPER].includes(shipper.vendor),
    )
  }

  return activeShippers
}

export const validShippersSelector = createSelector(
  approvedShippersSelector,
  createSelector(
    (state) => state,
    (state, {labelInfoID, orderNumber}) =>
      orderNumber || orderNumberFromLabelConfigSelector(state, {labelInfoID}),
    (state, orderNumber) =>
      orderNumber ? isPrimeOrderSelector(state, {orderNumber}) : false,
  ),
  canIgnoreAmazonPrimeShipperRestrictionSelector,
  createSelector(
    (state) => state,
    (state, {labelInfoID, orderNumber}) =>
      orderNumber || orderNumberFromLabelConfigSelector(state, {labelInfoID}),
    (state, orderNumber) =>
      orderNumber ? isAmazonOrderSelector(state, {orderNumber}) : false,
  ),
  canShipAllAmazonWithAmazonSelector,
  labelTypeSelector,
  orderTypeSelector,
  isPresetLabelConfigSelector,
  getValidShippers,
)

export const validShipperIDsSelector = createSelector(
  validShippersSelector,
  (shippers) => shippers.map(({id}) => id),
)

export const validBulkShippersSelector = createSelector(
  approvedShippersSelector,
  createSelector(
    (state) => state,
    orderNumbersFromLabelConfigsSelector,
    (state, orderNumbers) =>
      orderNumbers ? arePrimeOrdersSelector(state, {orderNumbers}) : false,
  ),
  canIgnoreAmazonPrimeShipperRestrictionSelector,
  createSelector(
    (state) => state,
    orderNumbersFromLabelConfigsSelector,
    (state, orderNumbers) =>
      orderNumbers ? areAllAmazonOrdersSelector(state, {orderNumbers}) : false,
  ),
  canShipAllAmazonWithAmazonSelector,
  (state, {labelInfoIDs}) =>
    labelTypeSelector(state, {labelInfoID: labelInfoIDs[0]}),
  (state, {labelInfoIDs}) =>
    orderTypeSelector(state, {labelInfoID: labelInfoIDs[0]}),
  (state, {labelInfoIDs}) =>
    isPresetLabelConfigSelector(state, {labelInfoID: labelInfoIDs[0]}),
  getValidShippers,
)

export const validBulkShipperIDsSelector = createSelector(
  validBulkShippersSelector,
  (shippers) => shippers.map(({id}) => id),
)

export function packageCountSelector(state, {labelInfoID}) {
  const packages = packagesSelector(state, {labelInfoID})

  return packages.length
}

function getCanHaveMultibox(shipper) {
  const shipperType = shipper.vendor

  if (
    shipperType === AUSTRALIA_POST &&
    !get(shipper, ['vendor_config', 'allow_multi_pack'])
  ) {
    return false
  }

  return getMaxParcelCount(shipperType) > 1
}

export function multiboxShipperTypesSelector(state, {labelInfoID}) {
  const shippers = labelShippersSelector(state, {labelInfoID})

  return multiboxShipperTypesSelector.memFunc(shippers)
}
multiboxShipperTypesSelector.memFunc = memOutcome((shippers) =>
  uniq(
    shippers
      .filter((shipper) => getCanHaveMultibox(shipper))
      .map(({vendor}) => vendor),
  ),
)

export function canHaveMultiboxSelector(state, {labelInfoID, shipperType}) {
  // shipperType is optional
  // if shipperType is passed in it only ask that question
  // for those shippers of that type
  const shipperTypes = multiboxShipperTypesSelector(state, {labelInfoID})

  return shipperType
    ? shipperTypes.includes(shipperType)
    : shipperTypes.length > 0
}

export function isMultiboxSelector(state, {labelInfoID, shipperType}) {
  const canHaveMultibox = canHaveMultiboxSelector(state, {
    labelInfoID,
    shipperType,
  })

  const count = packageCountSelector(state, {labelInfoID})

  return canHaveMultibox && count > 1
}

export function configureIndividualBoxShapeSelector(
  state,
  {labelInfoID, shipperType},
) {
  const shippingMethod = shippingMethodSelector(state, {labelInfoID})

  return shipperType === UPS && shippingMethod === '01'
}

export function declaredValueWarningSelector(
  state,
  {labelInfoID, shipperType},
) {
  const packages = packagesSelector(state, {labelInfoID})

  if (shipperType === UPS) {
    const totalValue = packages.reduce(
      (prev, {ups__declared_value}) => prev + Number(ups__declared_value),
      0,
    )

    if (totalValue > 1000) {
      return 'This is a high-value package.'
    }
  }

  return ''
}

export function declaredValueErrorSelector(state, {labelInfoID, shipperType}) {
  const packagesIndex = 0
  const attachCustomsInfo = attachCustomsInfoSelector(state, {labelInfoID})

  if (
    shipperType === X_DELIVERY ||
    (shipperType === DHL_ECOMMERCE && attachCustomsInfo)
  ) {
    return isNonZeroPositiveNumeric(
      declaredValueSelector(state, {labelInfoID, shipperType, packagesIndex}),
    )
      ? ''
      : 'Declared Value must be a positive non-zero number'
  }

  return ''
}

export function shippingInfoSelector(state, {labelInfoID}) {
  const orderNumber = orderNumberFromLabelConfigSelector(state, {labelInfoID})

  if (orderNumber) {
    const labelType = labelTypeSelector(state, {labelInfoID})
    const order = orderSelector(state, {orderNumber})
    return get(
      order,
      labelType === 'return' ? 'return_shipping_info' : 'shipping_info',
    )
  } else {
    const referenceID = referenceIDFromLabelConfigSelector(state, {labelInfoID})
    const returnOrder = returnOrderSelector(state, {referenceID})

    return get(returnOrder, 'shipping_infos.0')
  }
}

export function shippingInfoCostSelector(state, {labelInfoID}) {
  return get(shippingInfoSelector(state, {labelInfoID}), 'cost')
}

export function departedShippingMethodSelector(state, {labelInfoID}) {
  const labelInfo = labelInfoSelector(state, {labelInfoID})

  return labelInfo.departed_shipping_method
}

export function labelLoadingSelector(state, {labelInfoID}) {
  const labelInfo = labelInfoSelector(state, {labelInfoID})

  return !!labelInfo.label_loading
}

export function hasLabelSelector(state, {labelInfoID}) {
  const order = orderFromLabelInfoIDSelector(state, {labelInfoID})
  const labelType = labelTypeSelector(state, {labelInfoID})

  if (labelType === 'shipping') {
    return order.shipping_info.has_label
  }
  if (labelType === 'return') {
    return order.return_shipping_info.has_label
  }
  return false
}

export function labelInfoIDsWithLabelsSelector(state, {labelInfoIDs}) {
  return labelInfoIDs.filter((labelInfoID) =>
    hasLabelSelector(state, {labelInfoID}),
  )
}

export function bulkRatesIsLoadingSelector(state, {labelInfoID}) {
  const labelInfo = labelInfoSelector(state, {labelInfoID})

  return !!labelInfo.bulk_rates_is_loading
}

export function shippingConfigurationSelector(
  state,
  {labelInfoID, shipperType},
) {
  const labelConfig = labelConfigSelector(state, {labelInfoID})
  const boxShape = boxShapeSelector(state, {
    labelInfoID,
    shipperType,
    packagesIndex: 0,
  })

  return {
    shipper_ids: [labelConfig.shipper_id],
    shipper_id: labelShipperIDSelector(state, {labelInfoID}),
    [`${shipperType}__shipping_method`]: shippingMethodSelector(state, {
      labelInfoID,
    }),
    [`${shipperType}__rate_key`]: rateKeySelector(state, {
      labelInfoID,
    }),
    is_mail_innovations: labelConfig.is_mail_innovations,
    include_insurance: labelConfig.include_insurance,
    insured_value: labelConfig.insured_value,
    pickup_date: labelConfig.pickup_date,
    [`${shipperType}__box_shapes`]: [boxShape],
    packages: [
      {
        [`${shipperType}__box_shape`]: boxShape,
      },
    ],
  }
}

export function getMaxParcelCount(shipperType) {
  if (shipperType === UPS) {
    return 20
  }
  if (shipperType === FEDEX) {
    return 25
  }
  if (shipperType === AUSTRALIA_POST) {
    return 20
  }

  return 1
}

export function canAddMoreParcelsSelector(state, {labelInfoID, shipperType}) {
  const packages = packagesSelector(state, {labelInfoID})
  const maxParcelCount = getMaxParcelCount(shipperType)

  return packages.length < maxParcelCount
}

export function bulkShipperCurrencySymbolSelector(state, {labelInfoIDs}) {
  const currencySymbol = currencySymbolSelector(state)

  const hasUSPSShipper = labelInfoIDs.reduce((prev, labelInfoID) => {
    if (prev) {
      return true
    }

    const shipperType = labelShipperTypeSelector(state, {labelInfoID})

    return [
      ENDICIA,
      PITNEY,
      PITNEY_MERCHANT,
      PITNEY_PRESORT,
      VISIBLE_USPS,
    ].includes(shipperType)
  }, false)

  if (hasUSPSShipper) {
    return '$'
  }

  return currencySymbol
}

function getShipFromOptions(orderSupplier, warehouses) {
  return [
    ...(orderSupplier
      ? [
          {
            value: `supplier-${orderSupplier.id}`,
            display: getSupplierName(orderSupplier),
          },
        ]
      : []),
    ...warehouses.map((warehouse) => ({
      value: `warehouse-${warehouse.id}`,
      display: getWarehouseName(warehouse),
    })),
  ]
}

export function formatShipFromValue(
  do_not_ship_from_supplier,
  supplierID,
  warehouseID,
) {
  return !do_not_ship_from_supplier && supplierID
    ? `supplier-${supplierID}`
    : `warehouse-${warehouseID}`
}

export function getShipFromFromValue(value) {
  const id = Number(value.replace('warehouse-', '').replace('supplier-', ''))
  const updates = {warehouse_id: null, supplier_id: null}

  if (value.includes('warehouse')) {
    updates.warehouse_id = id
  } else if (value.includes('supplier')) {
    updates.supplier_id = id
  }

  return updates
}

function shipFromOrderSupplierSelector(state, {labelInfoID}) {
  const orderNumber = orderNumberFromLabelConfigSelector(state, {labelInfoID})

  return orderNumber ? orderSupplierSelector(state, {orderNumber}) : undefined
}

export function shipFromOptionsSelector(state, {labelInfoID}) {
  const orderSupplier = shipFromOrderSupplierSelector(state, {
    labelInfoID,
  })

  const warehouses = warehousesSortedByNameSelector(state)

  return getShipFromOptions(orderSupplier, warehouses)
}

export function bulkShipFromOptionsSelector(state, {labelInfoIDs}) {
  const warehouses = warehousesSortedByNameSelector(state)
  const optionStats = {}

  // Collect stats about all the options possible for each labelInfoID
  for (const labelInfoID of labelInfoIDs) {
    const orderSupplier = shipFromOrderSupplierSelector(state, {
      labelInfoID,
    })

    const shipFromOptions = getShipFromOptions(orderSupplier, warehouses)

    shipFromOptions.forEach((option) => {
      const stats = optionStats[option.value] || {count: 0}

      optionStats[option.value] = {
        count: stats.count + 1,
        option,
      }
    })
  }

  // Produce an array of options that are common for all labelInfoIDs
  return Object.keys(optionStats).reduce((prev, value) => {
    const stats = optionStats[value]

    if (stats.count === labelInfoIDs.length) {
      prev.push(stats.option)
    }

    return prev
  }, [])
}

export function labelInfoIDsThatCanHaveShippingLabelsSelector(
  state,
  {orderNumbers},
) {
  const cache = (labelInfoIDsThatCanHaveShippingLabelsSelector.cache =
    labelInfoIDsThatCanHaveShippingLabelsSelector.cache || {})

  const labelInfoIDs = orderNumbers
    .map((orderNumber) =>
      labelInfoIDForOrderAndLabelTypeSelector(state, {
        orderNumber,
        labelType: 'shipping',
      }),
    )
    .filter((labelInfoID) => labelInfoID)

  if (isEqual(cache.labelInfoIDs, labelInfoIDs)) {
    return cache.labelInfoIDs
  }

  cache.labelInfoIDs = labelInfoIDs

  return labelInfoIDs
}

export function labelInfoIDsThatCanHaveReturnLabelsSelector(
  state,
  {orderNumbers},
) {
  const cache = (labelInfoIDsThatCanHaveReturnLabelsSelector.cache =
    labelInfoIDsThatCanHaveReturnLabelsSelector.cache || {})

  const labelInfoIDs = orderNumbers
    .map((orderNumber) =>
      labelInfoIDForOrderAndLabelTypeSelector(state, {
        orderNumber,
        labelType: 'return',
      }),
    )
    .filter((labelInfoID) => labelInfoID)

  if (isEqual(cache.labelInfoIDs, labelInfoIDs)) {
    return cache.labelInfoIDs
  }

  cache.labelInfoIDs = labelInfoIDs

  return labelInfoIDs
}

export function cleanUpLabelInfos() {
  const allOrders = allOrdersSelector(getState())
  const allRMAs = returnOrdersSelector(getState())
  const allLabelInfos = labelInfosSelector(getState())

  const labelInfoIDs = [...CONST_LABEL_IDS]

  for (let orderNumber in allOrders) {
    const labelInfos = get(allOrders, [orderNumber, 'label_infos']) || []

    labelInfos.forEach((labelInfoID) => labelInfoIDs.push(labelInfoID))
  }

  for (let referenceID in allRMAs) {
    const labelInfos = get(allRMAs, [referenceID, 'label_infos']) || []

    labelInfos.forEach((labelInfoID) => labelInfoIDs.push(labelInfoID))
  }

  const labelInfosToKeep = Object.values(
    labelInfoIDs.reduce((prev, labelInfoID) => {
      if (allLabelInfos[labelInfoID]) {
        prev[labelInfoID] = allLabelInfos[labelInfoID]
      }

      return prev
    }, {}),
  )

  setLabelInfos(labelInfosToKeep, {override: true})
}

export async function receivedLabelInfoUpdate(labelInfoPartials) {
  cleanUpLabelInfos()

  const labelInfos = labelInfosSelector(getState())

  const labelInfoIDs = labelInfoPartials
    .filter(
      ({id, updated}) =>
        labelInfos[id] &&
        isAfter(new Date(updated), new Date(labelInfos[id].updated)),
    )
    .map(({id}) => id)

  if (labelInfoIDs.length === 0) {
    return
  }

  await ensureLabelInfosLoaded(labelInfoIDs, {reload: true})
}

export function receivedLabelInfoUpdateThrottled(
  {id: labelInfoID, updated},
  timeout = 1000,
) {
  const func = receivedLabelInfoUpdateThrottled

  if (func.queue[labelInfoID]) {
    func.queue[labelInfoID].updated = updated
  } else {
    func.queue[labelInfoID] = {id: labelInfoID, updated}
  }

  if (func.promise) {
    return func.promise
  }

  func.promise = new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        const labelInfoPartials = Object.values(func.queue)
        func.queue = {}
        func.promise = null

        await receivedLabelInfoUpdate(labelInfoPartials)
      } catch (err) {
        reject(err)
      }

      resolve()
    }, timeout)
  })

  return func.promise
}
receivedLabelInfoUpdateThrottled.queue = {}
receivedLabelInfoUpdateThrottled.promise = null

export function getCustomsDefaults(
  shippingAddress,
  shipFromAddress,
  declaredValue,
) {
  const attachCustomsInfo = !shipFromAddress
    ? false
    : getShouldAttachCustomsInfo(shippingAddress, shipFromAddress)
  const canHaveDeliveryConfirmation = !shipFromAddress
    ? false
    : getCanHaveDeliveryConfirmation(shippingAddress, shipFromAddress)
  const shouldHaveCertificateOfOrigin = !shipFromAddress
    ? false
    : getShouldHaveCertificateOfOrigin(
        shippingAddress,
        shipFromAddress,
        declaredValue,
      )

  const defaults = {
    attach_customs_info: attachCustomsInfo,
    packages: [
      {
        dhl__declared_value: declaredValue,
        [`${X_DELIVERY}__declared_value`]: declaredValue,
        [`${DHL_ECOMMERCE}__declared_value`]: declaredValue,
      },
    ],
    can_have_delivery_confirmation: canHaveDeliveryConfirmation,
    certificate_origin: shouldHaveCertificateOfOrigin,
  }

  if (attachCustomsInfo) {
    defaults.endicia__contents_type = CONTENTS_TYPE_MERCHANDISE
    defaults.pitney__reason_for_export = REASON_FOR_EXPORT_MERCHANDISE
  }

  return defaults
}

export const customsDefaultsSelector = (state, {labelInfoID}) => {
  const shippingAddress = shippingAddressSelector(state, {labelInfoID})
  const shipFromAddress = labelShipFromAddressSelector(state, {labelInfoID})
  const insuredValue = insuredValueSelector(state, {labelInfoID})

  return getCustomsDefaults(shippingAddress, shipFromAddress, insuredValue)
}

const getShipFromAddress = (order, suppliers, warehouses) => {
  const warehouseID = getWarehouseID(order)
  const supplierID = getSupplierID(order)
  const isDropshipped = getIsDropshipped(order)

  const warehouse = warehouses[warehouseID] || {address: {}}
  const supplier = suppliers[supplierID] || {address: {}}
  const address = isDropshipped ? supplier.address : warehouse.address

  return address || {}
}

export function shipFromDefaultsSelector(state, {labelInfoID}) {
  const orderNumber = orderNumberFromLabelConfigSelector(state, {labelInfoID})
  const order = orderSelector(state, {orderNumber})
  const shippingAddress = shippingAddressSelector(state, {labelInfoID})

  const warehouses = warehousesSelector(state)
  const suppliers = suppliersSelector(state)
  const shipFromAddress = getShipFromAddress(order, suppliers, warehouses)

  const insuredValue = insuredValueSelector(state, {labelInfoID})

  return getCustomsDefaults(shippingAddress, shipFromAddress, insuredValue)
}

const PREFERRED_SHIPPING_METHODS = [
  'FCM',
  'UGA',
  'PM',
  'EM', // pitney
  'First',
  'Priority',
  'PriorityExpress', // endicia
]

export function sortByPreferredShippingMethod({service_type: serviceType}) {
  const serviceTypeIndex = PREFERRED_SHIPPING_METHODS.indexOf(serviceType)

  return serviceTypeIndex !== -1 ? serviceTypeIndex : Infinity
}

export function sortByDeliveryEstimate({
  estimated_delivery_date,
  estimated_delivery_days,
}) {
  if (estimated_delivery_date) {
    let date = parseISO(estimated_delivery_date)

    // for dumb dates from Endicia: month/day/year
    if (!isValid(date)) {
      date = parse(estimated_delivery_date, 'M/d/yyyy', new Date())
    }

    if (isValid(date)) {
      return differenceInBusinessDays(date, new Date())
    }
  } else if (estimated_delivery_days) {
    return Number(estimated_delivery_days)
  }

  return Infinity
}

export function sortRates(rates, {includeShipperID = true} = {}) {
  return sortBy(rates, [
    ...(includeShipperID ? ['shipper_id'] : []),
    sortByPreferredShippingMethod,
    'cost',
    'service_type',
  ])
}

export const labelErrorSelector = createSelector(
  labelInfoSelector,
  ({error_message}) => error_message,
)

const hasLabelErrorSelector = createSelector(
  labelErrorSelector,
  (errorMessage) => !!errorMessage,
)

export const hasLoadedRatesSelector = createSelector(
  labelInfosSelector,
  (state, {labelInfoID}) => labelInfoID,
  hasLabelErrorSelector,
  (labelInfos, labelInfoID, hasLabelErrors) =>
    get(labelInfos, [labelInfoID, 'rates']) !== null || hasLabelErrors,
)

export const missingRatesSelector = createSelector(
  labelInfosSelector,
  (state, {labelInfoIDs}) => labelInfoIDs,
  (labelInfos, labelInfoIDs) =>
    labelInfoIDs.filter((labelInfoID) => {
      // has at least one rate (and it has a key prop)
      let hasRates = !!get(labelInfos, [
        labelInfoID,
        'rates',
        'list',
        '0',
        'key',
      ])

      if (hasRates) {
        const expires = get(labelInfos, [
          labelInfoID,
          'rates',
          'list',
          '0',
          'expires',
        ])

        hasRates = expires ? isAfter(new Date(expires), new Date()) : true
      }

      const hasError = !!get(labelInfos, [labelInfoID, 'error_message'])

      return !hasRates && !hasError
    }),
)

export function ratesSelector(state, {labelInfoID}) {
  const labelInfo = labelInfoSelector(state, {labelInfoID})

  return sortRates(get(labelInfo, ['rates', 'list'], []))
}

export function selectedRateSelector(state, {labelInfoID}) {
  const rateKey = rateKeySelector(state, {labelInfoID})
  const rates = ratesSelector(state, {labelInfoID})

  return rates && rates.find((rate) => rate.key === rateKey)
}

export const getSelectedShippingRateCost = (
  rate,
  insuranceCost,
  shippingInfoCost,
) =>
  !isNil(shippingInfoCost)
    ? shippingInfoCost
    : rate && rate.cost + insuranceCost

export function selectedShippingRateCostSelector(state, {labelInfoID}) {
  const selectedRate = selectedRateSelector(state, {labelInfoID})
  const shipperType = labelShipperTypeSelector(state, {labelInfoID})
  const insuranceCost = insuranceCostSelector(state, {labelInfoID, shipperType})
  const shippingInfoCost = shippingInfoCostSelector(state, {labelInfoID})

  return getSelectedShippingRateCost(
    selectedRate,
    insuranceCost,
    shippingInfoCost,
  )
}

export const makeSelectedShippingRateCostSelector = () =>
  selectedShippingRateCostSelector

export function rateForMethodSelector(state, {labelInfoID, shippingMethod}) {
  const rates = ratesSelector(state, {labelInfoID})

  return rates.find((rate) => rate.service_type === shippingMethod)
}

export function validLabelInfoIDsSelector(state, {labelInfoIDs}) {
  return labelInfoIDs.filter((labelInfoID) => {
    const missingKeys = missingKeysSelector(state, {labelInfoID})
    const shippers = labelShippersSelector(state, {labelInfoID})

    let validationErrorCount = 0
    for (const shipper of shippers) {
      const validationErrors = validationErrorsSelector(state, {
        labelInfoID,
        shipperType: shipper.vendor,
      })
      validationErrorCount += validationErrors.length
    }

    const isPresetLabelConfig = isPresetLabelConfigSelector(state, {
      labelInfoID,
    })

    return !missingKeys.length && !validationErrorCount && !isPresetLabelConfig
  })
}

export function ratesLoadingSelector(state, {labelInfoID}) {
  const labelInfo = labelInfoSelector(state, {labelInfoID})

  return !!labelInfo.rates_loading
}

export function ratesUpdatedSelector(state, {labelInfoID}) {
  const labelInfo = labelInfoSelector(state, {labelInfoID})

  return !!labelInfo.rates_updated
}

export function shippingAddressErrorSelector(state, {labelInfoID}) {
  const orderNumber = orderNumberFromLabelConfigSelector(state, {labelInfoID})
  const referenceID = referenceIDFromLabelConfigSelector(state, {labelInfoID})

  const address = orderNumber
    ? orderShippingAddressSelector(state, {orderNumber})
    : referenceID
      ? returnOrderCustomerAddressSelector(state, {referenceID})
      : null

  if (!address) {
    return null
  }

  if (!address.city && !address.street1) {
    return 'Missing shipping address.'
  }

  return null
}

export function validateWeight(weight) {
  if (!isNonZeroPositiveNumeric(weight)) {
    return 'Invalid weight.'
  }
  return null
}

export function weightErrorMessageSelector(state, {labelInfoID, shipperType}) {
  const weight = weightSelector(state, {labelInfoID})

  const invalidWeightError = validateWeight(weight)

  if (invalidWeightError) {
    return invalidWeightError
  }

  const isSimpleRateBoxShape = isSimpleRateBoxShapeSelector(state, {
    labelInfoID,
    shipperType,
  })

  if (isSimpleRateBoxShape && weight > 50 * 16) {
    return 'Weight above 50lb is not valid for this Package Type'
  }

  return null
}

export function dimensionsAreRequired(
  shipperTypes = [],
  boxShapesByShipperType = {},
) {
  let didCheckBoxShapes = false
  for (const shipperType of shipperTypes) {
    const boxShapes = boxShapesByShipperType[shipperType] || []

    // not having box shapes means we aren't checking box shapes so take default
    // (probably true)
    if (boxShapes.length === 0) {
      continue
    }

    let boxShapesThatNeedDimensions = null
    if (shipperType === ENDICIA) {
      boxShapesThatNeedDimensions = ['Parcel', 'Softpack']
    }
    if ([PITNEY, PITNEY_MERCHANT].includes(shipperType)) {
      boxShapesThatNeedDimensions = [
        'PKG',
        'LETTER',
        'SOFTPACK',
        'FLAT',
        'LGENV',
        'NMLETTER',
      ]
    }
    if (shipperType === PITNEY_PRESORT) {
      boxShapesThatNeedDimensions = ['PKG', 'LGENV']
    }
    if (shipperType === VISIBLE_USPS) {
      boxShapesThatNeedDimensions = [
        'Package',
        'OversizedParcel',
        'Letter',
        'Softpack',
      ]
    }
    if (shipperType === FEDEX) {
      boxShapesThatNeedDimensions = ['YOUR_PACKAGING', 'FEDEX_BOX']
    }
    if (shipperType === UPS) {
      boxShapesThatNeedDimensions = ['02', '21', '59']
    }
    if (shipperType === CANADA_POST) {
      boxShapesThatNeedDimensions = ['Parcel', 'Tube']
    }
    if (shipperType === DHL) {
      boxShapesThatNeedDimensions = true
    }
    if (shipperType === AMAZON_SHIPPER) {
      boxShapesThatNeedDimensions = ['CUSTOM_PACKAGE']
    }

    if (boxShapesThatNeedDimensions) {
      didCheckBoxShapes = true

      if (
        boxShapesThatNeedDimensions === true ||
        includesSome(boxShapes, boxShapesThatNeedDimensions)
      ) {
        return true
      }
    }
  }

  // if we got through the whole loop of shipper types and none needed
  // dimensions but they did have box shapes to check then they do not require
  // dimensions. Only return true here if none of the shipper types have
  // specific box shapes to check
  // so dimensions are required if we did NOT check box shapes
  return !didCheckBoxShapes
}

export function showDimensionsSelector(state, {labelInfoID, shipperType}) {
  const shipperTypes = shipperType
    ? [shipperType]
    : shipperTypesSelector(state, {
        labelInfoID,
      })
  const boxShapesByShipperType = boxShapesByShipperTypeSelector(state, {
    labelInfoID,
  })

  return dimensionsAreRequired(shipperTypes, boxShapesByShipperType)
}

export function pitneySortedDimensions(length, width, height) {
  const sortedDimensionsDesc = sortBy([
    toNumber(length),
    toNumber(width),
    toNumber(height),
  ]).reverse()

  return {
    length: sortedDimensionsDesc[0],
    height: sortedDimensionsDesc[1],
    width: sortedDimensionsDesc[2],
  }
}

export function getDimensionsParams(parcel, boxShape, shipperType) {
  if (dimensionsAreRequired([shipperType], {[shipperType]: [boxShape]})) {
    const length = toNumber(parcel.length)
    const width = toNumber(parcel.width)
    const height = toNumber(parcel.height)

    if ([PITNEY, PITNEY_MERCHANT, PITNEY_PRESORT].includes(shipperType)) {
      return pitneySortedDimensions(length, width, height)
    }

    return {
      length,
      width,
      height,
    }
  }

  return {}
}

export function dimensionHasError(dimension) {
  return !dimension || !isNonZeroPositiveNumeric(dimension)
}

export function getUPSTotalDimensions(length = 0, width = 0, height = 0) {
  return Number(length) + (2 * Number(width) + 2 * Number(height))
}

export function getUPSDimensionWarningMessage({length, width, height}) {
  if (
    getUPSTotalDimensions(length, width, height) >
    ShipperOptions.ups.maximum_dimensions_warning_level
  ) {
    return 'A Large Package Surcharge has been set on the package and is included in the rate.'
  }

  return null
}

export function getDimensionsWarningMessage(
  {length, width, height},
  shipperTypes = [],
) {
  if (shipperTypes.includes(UPS)) {
    return getUPSDimensionWarningMessage({length, width, height})
  }

  return null
}

export const dimensionsWarningMessageSelector = createSelector(
  dimensionsSelector,
  // we want either the specific shipperType warning or selected rate warning
  (state, {labelInfoID, shipperType}) =>
    shipperType || labelShipperTypeSelector(state, {labelInfoID}),
  (dimensions, shipperType) =>
    getDimensionsWarningMessage(
      dimensions,
      shipperType ? [shipperType] : undefined,
    ),
)

export function getDimensionsErrorMessage(
  {length, width, height},
  shipperTypes = [],
) {
  if (
    dimensionHasError(length) ||
    dimensionHasError(width) ||
    dimensionHasError(height)
  ) {
    return 'Invalid dimension.'
  }

  if (shipperTypes.includes(UPS)) {
    if (
      getUPSTotalDimensions(length, width, height) >
      ShipperOptions.ups.maximum_dimensions_error_level
    ) {
      return 'Package exceeds the maximum size total constraints.'
    }
  }

  return null
}

export const dimensionsErrorMessageSelector = createSelector(
  dimensionsSelector,
  // we want either the specific shipperType error or selected rate error
  (state, {labelInfoID, shipperType}) =>
    shipperType || labelShipperTypeSelector(state, {labelInfoID}),
  showDimensionsSelector,
  (dimensions, shipperType, showDimensions) =>
    showDimensions
      ? getDimensionsErrorMessage(
          dimensions,
          shipperType ? [shipperType] : undefined,
        )
      : null,
)

export function dimensionsErrorMessagesSelector(
  state,
  {labelInfoID, shipperType},
) {
  const {packages} = labelConfigSelector(state, {labelInfoID})

  return (packages || []).map((_, index) =>
    dimensionsErrorMessageSelector(state, {
      labelInfoID,
      shipperType,
      packagesIndex: index,
    }),
  )
}

export const invalidDimensionsSelector = createSelector(
  dimensionsErrorMessagesSelector,
  (errorMessages) => compact(errorMessages).length > 0,
)

export const customsInfoRequiredSelector = createSelector(
  isInternationalLabelSelector,
  (_, {shipperType}) => shipperType,
  (isInternational, shipperType) =>
    isInternational &&
    [
      PITNEY_CBDS,
      ENDICIA,
      FEDEX,
      PITNEY,
      PITNEY_MERCHANT,
      PITNEY_PRESORT,
      UPS,
      VISIBLE_USPS,
    ].includes(shipperType),
)

export const customsErrorsSelector = createSelector(
  labelConfigSelector,
  (_, {shipperType}) => shipperType,
  labelTypeSelector,
  (labelConfig, shipperType, labelType) => {
    const attachCustomsInfo = get(labelConfig, 'attach_customs_info')
    const customsInfo = get(labelConfig, 'customs_info', [])
    const weight = sumWeight(labelConfig)

    if (
      !attachCustomsInfo ||
      [AMAZON_SFP, AMAZON_SHIPPER, NEWGISTICS, PITNEY_PRESORT].includes(
        shipperType,
      )
    ) {
      return null
    }

    if (DHL_ECOMMERCE === shipperType && labelType === 'return') {
      return 'Customs information is not supported'
    }

    const customsErrors = getAllCustomsErrors(shipperType, customsInfo, weight)
    if (customsErrors.length > 0) {
      return 'Customs information is invalid.'
    }

    return null
  },
)

export const customsWarningSelector = createSelector(
  labelConfigSelector,
  (_, {shipperType}) => shipperType,
  customsInfoRequiredSelector,
  (labelConfig, shipperType, customsInfoRequired) => {
    const attachCustomsInfo = get(labelConfig, 'attach_customs_info')
    const customsInfo = get(labelConfig, 'customs_info', [])

    if (customsInfoRequired && (!attachCustomsInfo || !customsInfo)) {
      return 'This looks like an international shipment but it has no customs information.'
    }

    if (!attachCustomsInfo) {
      return null
    }

    const customsWarnings = getAllCustomsWarnings(shipperType, customsInfo)
    if (customsWarnings.length > 0) {
      return 'Customs information may need to be adjusted.'
    }

    return null
  },
)

export const referenceNumberErrorMessageSelector = createSelector(
  labelConfigSelector,
  (_, {shipperType}) => shipperType,
  (labelConfig, shipperType) => {
    const referenceNumber = get(labelConfig, 'reference_number')
    const maxLength =
      SHIPPER_REFERENCE_NUMBER_MAX_LENGTH[shipperType] || Infinity

    if (!referenceNumber) {
      return null
    }

    if (referenceNumber.length > maxLength) {
      return `Reference Number is too long (max: ${maxLength} characters)`
    }

    if (shipperType === DHL_ECOMMERCE && referenceNumber.match(/\s/)) {
      return 'Reference Number can not contain spaces'
    }

    return null
  },
)

export const invoiceNumberErrorMessageSelector = createSelector(
  labelConfigSelector,
  (_, {shipperType}) => shipperType,
  (labelConfig, shipperType) => {
    const invoiceNumber = get(labelConfig, 'invoice_number')
    const maxLength = SHIPPER_INVOICE_NUMBER_MAX_LENGTH[shipperType]

    if (!invoiceNumber || !maxLength) {
      return null
    }

    return invoiceNumber.length > maxLength
      ? `Invoice Number is too long (max: ${maxLength} characters)`
      : null
  },
)

export const descriptionErrorMessageSelector = createSelector(
  labelConfigSelector,
  (_, {shipperType}) => shipperType,
  (labelConfig, shipperType) => {
    const description = get(labelConfig, 'packages.0.description')

    if (shipperType === X_DELIVERY && !description) {
      return 'Description is required'
    }

    return null
  },
)

export const alternateReturnToAddressErrorMessageSelector = createSelector(
  labelConfigSelector,
  (_, {shipperType}) => shipperType,
  (labelConfig, shipperType) => {
    const use_alternate_return_to_address = get(
      labelConfig,
      'use_alternate_return_to_address',
    )
    const alternate_return_to_address = get(
      labelConfig,
      'alternate_return_to_address',
    )

    if (
      [FEDEX, UPS].includes(shipperType) &&
      use_alternate_return_to_address &&
      !isAddressComplete(alternate_return_to_address, {
        name_or_company: true,
        street1: true,
        city: true,
        state: true,
        zip: true,
        country: true,
      })
    ) {
      return 'Alt. Return To Address is incomplete'
    }

    return null
  },
)

export const taxInfoErrorMessageSelector = createSelector(
  labelConfigSelector,
  (_, {shipperType}) => shipperType,
  taxInfoPropertySelector,
  (labelConfig, shipperType, property) => {
    if (
      ![DHL, DHL_ECOMMERCE, PITNEY, PITNEY_MERCHANT, UPS].includes(shipperType)
    ) {
      return null
    }

    const taxID = get(labelConfig, `${property}__tax_id_number`)
    const taxIDType = get(
      labelConfig,
      `${shipperType}__${property}__tax_id_type`,
    )
    const taxIssuerCountry = get(labelConfig, `${property}__tax_issuer_country`)

    const countryIsMissing =
      [DHL, DHL_ECOMMERCE, PITNEY, PITNEY_MERCHANT].includes(shipperType) &&
      !taxIssuerCountry

    if (taxID && (!taxIDType || countryIsMissing)) {
      return 'Tax Info is incomplete'
    }

    return null
  },
)

function maxInsuranceValueSelector(state, {labelInfoID, shipperType}) {
  const labelConfig = labelConfigSelector(state, {labelInfoID})
  const shippingMethod = shippingMethodSelector(state, {labelInfoID})
  const {insurance_type} = getInsuranceParams(labelConfig, shipperType)
  const shippingMethodType =
    labelConfig.is_mail_innovations && shipperType === UPS
      ? 'shipping_method_mail_innovations'
      : 'shipping_method'

  const shipperInsuranceMax = get(
    ShipperOptions,
    [shipperType, 'insurance'],
    [],
  ).reduce((prev, {value, max = Infinity}) => {
    if (value === insurance_type) {
      return Math.min(prev, max)
    }

    return prev
  }, Infinity)

  const shippingMethodMax = get(
    ShipperOptions,
    [shipperType, shippingMethodType],
    [],
  ).reduce((prev, {value, insurance}) => {
    const max = get(insurance, [insurance_type, 'max'], Infinity)

    if (value === shippingMethod) {
      return Math.min(prev, max)
    }

    return prev
  }, Infinity)

  return Math.min(shippingMethodMax, shipperInsuranceMax)
}

export function insuranceErrorMessageSelector(
  state,
  {labelInfoID, shipperType},
) {
  const includeInsurance = includeInsuranceSelector(state, {labelInfoID})
  const maxInsuranceValue = maxInsuranceValueSelector(state, {
    labelInfoID,
    shipperType,
  })
  const shipperCurrencySymbol = shipperCurrencySymbolSelector(state, {
    shipperType,
  })
  const labelConfig = labelConfigSelector(state, {labelInfoID})
  const {insured_value} = getInsuranceParams(labelConfig, shipperType)

  return includeInsurance && maxInsuranceValue < insured_value
    ? `Insured value can not be above ${formatCurrency(
        maxInsuranceValue,
        shipperCurrencySymbol,
      )}`
    : null
}

export function multiboxSimpleRateErrorMessageSelector(
  state,
  {labelInfoID, shipperType},
) {
  const isSimpleRateBoxShape = isSimpleRateBoxShapeSelector(state, {
    labelInfoID,
    shipperType,
  })
  const isMultibox = isMultiboxSelector(state, {labelInfoID, shipperType})

  return isSimpleRateBoxShape && isMultibox
    ? 'UPS Simple Rates do not support multiple packages'
    : null
}

export function validationErrorsSelector(state, {labelInfoID, shipperType}) {
  const shippingAddressError = shippingAddressErrorSelector(state, {
    labelInfoID,
  })
  const weightErrorMessage = weightErrorMessageSelector(state, {
    labelInfoID,
    shipperType,
  })
  const dimensionsErrorMessages = dimensionsErrorMessagesSelector(state, {
    labelInfoID,
    shipperType,
  })
  const customsErrorMessage = customsErrorsSelector(state, {
    labelInfoID,
    shipperType,
  })
  const referenceNumberErrorMessage = referenceNumberErrorMessageSelector(
    state,
    {labelInfoID, shipperType},
  )
  const invoiceNumberErrorMessage = invoiceNumberErrorMessageSelector(state, {
    labelInfoID,
    shipperType,
  })
  const listOfImporterOfRecordErrors = listOfImporterOfRecordErrorsSelector(
    state,
    {labelInfoID, shipperType},
  )
  const declaredValueError = declaredValueErrorSelector(state, {
    labelInfoID,
    shipperType,
  })
  const descriptionErrorMessage = descriptionErrorMessageSelector(state, {
    labelInfoID,
    shipperType,
  })
  const taxInfoErrorMessage = taxInfoErrorMessageSelector(state, {
    labelInfoID,
    shipperType,
  })
  const alternateReturnToAddressErrorMessage =
    alternateReturnToAddressErrorMessageSelector(state, {
      labelInfoID,
      shipperType,
    })
  const insuranceErrorMessage = insuranceErrorMessageSelector(state, {
    labelInfoID,
    shipperType,
  })
  const multiboxSimpleRateErrorMessage = multiboxSimpleRateErrorMessageSelector(
    state,
    {
      labelInfoID,
      shipperType,
    },
  )

  return compact([
    weightErrorMessage,
    shippingAddressError,
    ...dimensionsErrorMessages,
    customsErrorMessage,
    referenceNumberErrorMessage,
    invoiceNumberErrorMessage,
    ...listOfImporterOfRecordErrors,
    declaredValueError,
    descriptionErrorMessage,
    taxInfoErrorMessage,
    alternateReturnToAddressErrorMessage,
    insuranceErrorMessage,
    multiboxSimpleRateErrorMessage,
  ])
}

export function labelErrorsSelector(state, {labelInfoID, shipperType}) {
  // shipperType is optional, it will target that shipper if provided
  // otherwise it gets all errors for all selected shipper types
  // it dedupes errors
  const labelError = labelErrorSelector(state, {labelInfoID})
  const shipperTypes = shipperType
    ? [shipperType]
    : labelShippersSelector(state, {labelInfoID}).map(({vendor}) => vendor)

  const errors = new Set()
  for (const shipperType of shipperTypes) {
    const validationErrors = validationErrorsSelector(state, {
      labelInfoID,
      shipperType,
    })

    for (const error of validationErrors) {
      if (error) {
        errors.add(error)
      }
    }
  }

  if (labelError) {
    errors.add(labelError)
  }

  return [...errors]
}

export const hasLabelErrorsSelector = createSelector(
  labelErrorsSelector,
  (errors) => !!errors.length,
)

export const hasCustomsErrorsSelector = createSelector(
  customsErrorsSelector,
  (errors) => !!errors,
)

export function labelWarningsSelector(state, {labelInfoID, shipperType}) {
  // shipperType is optional, it will target that shipper if provided
  // otherwise it gets all warnings for all selected shipper types
  // it dedupes warnings
  const shipperTypes = shipperType
    ? [shipperType]
    : labelShippersSelector(state, {labelInfoID}).map(({vendor}) => vendor)

  const warnings = new Set()
  for (const shipperType of shipperTypes) {
    const customsWarning = customsWarningSelector(state, {
      labelInfoID,
      shipperType,
    })

    if (customsWarning) {
      warnings.add(customsWarning)
    }
  }

  return [...warnings]
}

export const hasLabelWarningsSelector = createSelector(
  labelWarningsSelector,
  (warnings) => !!warnings.length,
)

export const hasCustomsWarningSelector = createSelector(
  customsWarningSelector,
  (warning) => !!warning,
)

export function importerOfRecordErrorsSelector(
  state,
  {labelInfoID, shipperType},
) {
  const errors = {}

  const labelConfig = labelConfigSelector(state, {labelInfoID})

  if (!labelConfig) {
    return errors
  }

  if (![FEDEX].includes(shipperType)) {
    return errors
  }

  const {attach_customs_info, include_importer_of_record, importer_of_record} =
    labelConfig

  if (!attach_customs_info || !include_importer_of_record) {
    return errors
  }

  if (!importer_of_record) {
    errors.importer_of_record = 'Importer of Recorder needs to be setup'

    return errors
  }

  if (!isPresent(importer_of_record.tin.number)) {
    errors.tin__number = 'Tax ID Number is required'
  }

  if (!isPresent(importer_of_record.address.name)) {
    errors.address__name = 'Contact Name is required'
  }

  if (!isPresent(importer_of_record.address.street1)) {
    errors.address__street1 = 'Street 1 is required'
  }

  if (!isPresent(importer_of_record.address.city)) {
    errors.address__city = 'City is required'
  }

  if (!isPresent(importer_of_record.address.zip)) {
    errors.address__zip = 'Zipcode is required'
  }

  if (!isPresent(importer_of_record.address.country)) {
    errors.address__country = 'Country is required'
  }

  if (!isPresent(importer_of_record.address.phone)) {
    errors.address__phone = 'Phone is required'
  }

  return errors
}

export function listOfImporterOfRecordErrorsSelector(
  state,
  {labelInfoID, shipperType},
) {
  const errors = importerOfRecordErrorsSelector(state, {
    labelInfoID,
    shipperType,
  })

  return Object.values(errors)
}

export function getShipperOptions(shipperType, shipperOptions) {
  return shipperOptions[shipperType] || {}
}

export const shippingRateForShippingMethodSelector = createSelector(
  (state, props) => props.shippingMethod,
  ratesSelector,
  (shippingMethod, rates) =>
    rates.find((r) => r.service_type === shippingMethod),
)

export const shippingMethodDisplayNameSelector = createSelector(
  shippingRateForShippingMethodSelector,
  (rate) => (rate ? rate.service : ''),
)

function shippingMethodsSelector(state, {labelInfoID, shipperType}) {
  const shipperOptions = ShipperOptions[shipperType]

  if (!shipperOptions) {
    return []
  }

  const labelConfig = labelConfigSelector(state, {labelInfoID})

  if (shipperType === UPS && labelConfig.is_mail_innovations) {
    return shipperOptions.shipping_method_mail_innovations || []
  }

  return shipperOptions.shipping_method || []
}

function findShippingMethodName(shippingMethod, shippingMethods, rates = []) {
  const rate = rates.find((r) => r.service_type === shippingMethod)
  if (rate) {
    return rate.service
  }

  const option = shippingMethods.find(
    (method) => method.value === shippingMethod,
  )
  if (option) {
    return option.display
  }

  return ''
}

export const makeShippingMethodNameSelector = () =>
  createSelector(
    shippingMethodSelector,
    shippingMethodsSelector,
    ratesSelector,
    findShippingMethodName,
  )

export function labelShippingMethodNameSelector(state, {labelInfoID}) {
  const shipperType = labelShipperTypeSelector(getState(), {
    labelInfoID,
  })
  const shippingMethod = shippingMethodSelector(state, {labelInfoID})
  const shippingMethods = shippingMethodsSelector(state, {
    labelInfoID,
    shipperType,
  })
  const rates = ratesSelector(state, {labelInfoID})

  return findShippingMethodName(shippingMethod, shippingMethods, rates)
}

export const SIMPLE_RATES_BOX_SHAPES = ['XS', 'S', 'M', 'L', 'XL']

export function getBoxShapeOptions(
  config,
  shipperType,
  shipperOptions = {},
  orderType,
  labelType,
  hasFedExNewCred,
) {
  if (shipperType === UPS && config.is_mail_innovations) {
    return [
      ...(shipperOptions.box_shape_mail_innovations || []),
      ...(shipperOptions.box_shape || []),
    ]
  }

  if (
    shipperType === UPS &&
    ((orderType === OT_V3_ORDER && labelType === 'return') ||
      orderType === OT_RETURN_ORDER)
  ) {
    return shipperOptions.box_shape.filter(
      ({value}) => !SIMPLE_RATES_BOX_SHAPES.includes(value),
    )
  }

  // temp_use_fedex_auth
  // refactor to keep the one_rate part
  if (hasFedExNewCred && shipperType === FEDEX) {
    return shipperOptions.box_shape.filter(
      ({hide_for_csp, hide_for_one_rate}) =>
        !hide_for_csp && !(config.one_rate && hide_for_one_rate),
    )
  }

  return shipperOptions.box_shape || []
}

export function boxShapeOptionsSelector(state, {labelInfoID, shipperType}) {
  const labelConfig = labelConfigSelector(state, {labelInfoID, shipperType})
  const shipperOptions = ShipperOptions[shipperType]
  const orderType = orderTypeSelector(state, {labelInfoID, shipperType})
  const labelType = labelTypeSelector(state, {labelInfoID, shipperType})

  // temp_use_fedex_auth
  const [hasFedExNewCred] = shipperVendorConfigPropertySelector(state, {
    labelInfoID,
    shipperType,
    property: 'child_key',
  })

  return getBoxShapeOptions(
    labelConfig,
    shipperType,
    shipperOptions,
    orderType,
    labelType,
    hasFedExNewCred,
  )
}

export const makeBoxShapeNameSelector = () =>
  createSelector(
    boxShapeSelector,
    boxShapeOptionsSelector,
    (boxShape, boxShapeOptions) => {
      const option = boxShapeOptions.find((box) => box.value === boxShape)

      return option && option.display
    },
  )

export const makeCostSelector = () =>
  createSelector(selectedShippingRateCostSelector, (cost) => cost)

export function shippingMethodToRate(shippingMethod, shipperType) {
  return {
    service: shippingMethod.display,
    key: shippingMethod.value,
    service_type: shippingMethod.value,
    box_type: '',
    estimated_delivery_date: null,
    estimated_delivery_days: null,
    cost: null,
    vendor: shipperType,
  }
}

export function localRatesSelector(state, {labelInfoID}) {
  const labelUseRateShopping = labelUseRateShoppingSelector(state, {
    labelInfoID,
  })

  if (labelUseRateShopping && labelInfoID !== BULK_LABEL_ID) {
    return null
  }

  const config = labelConfigSelector(state, {labelInfoID})
  const shippers = labelShippersSelector(state, {labelInfoID})
  const labelType = labelTypeSelector(state, {labelInfoID})

  const options = []
  for (const shipper of shippers) {
    const shipperType = shipper.vendor
    const shipperOptions = ShipperOptions[shipperType]
    const hiddenShippingMethods = get(shipper, 'hidden_shipping_methods') || []

    if (shipperType === UPS && config.is_mail_innovations) {
      options.push(
        ...shipperOptions.shipping_method_mail_innovations
          .filter(
            ({value}) =>
              !hiddenShippingMethods.includes(`mail_innovations__${value}`),
          )
          .map((option) => shippingMethodToRate(option, shipperType)),
      )

      continue
    }

    if (
      [PITNEY_PRESORT, X_DELIVERY].includes(shipperType) ||
      (shipperType === DHL_ECOMMERCE && labelType === 'return')
    ) {
      options.push(
        ...shipperOptions.shipping_method
          .filter(({value}) => !hiddenShippingMethods.includes(value))
          // if DHL_ECOMMERCE then only return type shipping methods
          .filter(
            ({type}) =>
              shipperType !== DHL_ECOMMERCE ||
              (labelType === 'return' && type === 'return'),
          )
          .map((option) => shippingMethodToRate(option, shipperType)),
      )

      continue
    }
  }

  return options.length ? options : null
}

export function isSimpleRateBoxShapeSelector(
  state,
  {labelInfoID, shipperType},
) {
  const firstBoxShape = boxShapeSelector(state, {
    labelInfoID,
    shipperType,
    packagesIndex: 0,
  })

  return shipperType === UPS && SIMPLE_RATES_BOX_SHAPES.includes(firstBoxShape)
}

export const rateSelectDisabledSelector = createSelector(
  missingKeysSelector,
  hasLabelErrorsSelector,
  ratesLoadingSelector,
  (missingKeys, hasErrors, ratesLoading) =>
    missingKeys.length !== 0 || hasErrors || ratesLoading,
)

export const insufficientBalanceSelector = createSelector(
  selectedShippingRateCostSelector,
  labelBalanceSelector,
  (cost, balance) => {
    if (balance !== null) {
      return cost > balance
    }
    return null
  },
)

export function createLabelImageDisabledSelector(state, {labelInfoID}) {
  const missingKeys = missingKeysSelector(state, {labelInfoID})
  const hasErrors = hasLabelErrorsSelector(state, {labelInfoID})
  const ratesLoading = ratesLoadingSelector(state, {labelInfoID})
  const rate = selectedRateSelector(state, {labelInfoID})
  const rates = ratesSelector(state, {labelInfoID})
  const shipperType = labelShipperTypeSelector(state, {labelInfoID})
  const insufficientBalance = insufficientBalanceSelector(state, {labelInfoID})

  return (
    missingKeys.length !== 0 ||
    hasErrors ||
    ratesLoading ||
    !rate ||
    !(rates && rates.length) ||
    (SHIPPERS_WITH_POSTAGE_BALANCES.includes(shipperType) &&
      insufficientBalance === null)
  )
}

function combineRateGroup(rateGroup, count) {
  return rateGroup.reduce(
    (prev, rate) => ({
      ...(prev || rate),
      cost: !prev ? rate.cost : prev.cost + rate.cost,
      disabled: rateGroup.length !== count,
    }),
    null,
  )
}

export function sortCombinedRates(combinedRates) {
  return sortBy(sortRates(combinedRates), ['disabled'])
}

export const rawBulkRatesSelector = createSelector(
  labelInfoSelector,
  ({bulk_rates}) => bulk_rates || [],
)

export function bulkRatesSelector(state, {labelInfoIDs}) {
  const cache = (bulkRatesSelector.cache = bulkRatesSelector.cache || {})

  const labelInfos = labelInfosFromLabelInfoIDsSelector(state, {labelInfoIDs})
  const allRates = labelInfos.reduce((prev, labelInfo) => {
    const bulkRates = labelInfo.bulk_rates
    if (bulkRates) {
      return [...prev, ...uniqBy(bulkRates, 'key')]
    }

    return prev
  }, [])

  const rateGroups = groupBy(allRates, 'key')

  const combinedRates = map(rateGroups, (rateGroup) =>
    combineRateGroup(rateGroup, labelInfos.length),
  )

  const rates = sortCombinedRates(combinedRates)

  if (isEqual(rates, cache.rates)) {
    return cache.rates
  }

  cache.rates = rates
  return rates
}

export const allBulkRatesLoadingSelector = createSelector(
  (state, props) => props.labelInfoIDs,
  (state) => state,
  (labelInfoIDs, state) =>
    !!labelInfoIDs.find(
      (labelInfoID) =>
        bulkRatesIsLoadingSelector(state, {labelInfoID}) === true,
    ),
)

export const bulkRatesNumberLoadedSelector = createSelector(
  labelInfosFromLabelInfoIDsSelector,
  (labelInfos) =>
    labelInfos.reduce(
      (prev, labelInfo) => (labelInfo.bulk_rates ? prev + 1 : prev),
      0,
    ),
)

export function includeInsuranceSelector(state, {labelInfoID}) {
  return !!labelConfigSelector(state, {labelInfoID}).include_insurance
}

export const insuredValueSelector = createSelector(
  (...args) => labelConfigSelector(...args),
  (labelConfig) => get(labelConfig, 'insured_value', '0.00'),
)

export function getInsuranceParams(config, shipperType) {
  if (config[`${shipperType}__insured_value`]) {
    return {
      insured_value: Number(config[`${shipperType}__insured_value`]),
      insurance_type: shipperType,
    }
  }

  if (
    canHaveInsurance(shipperType) &&
    config.include_insurance &&
    config.insured_value
  ) {
    return {
      insured_value: Number(config.insured_value),
      insurance_type: 'discounted_insurance',
    }
  }

  return {}
}

function hasShipperInsuranceSelector(state, {labelInfoID, shipperType}) {
  const config = labelConfigSelector(state, {labelInfoID})

  return !!config[`${shipperType}__insured_value`]
}

export function getShipsuranceCost(shipperType, insuredValue) {
  const costPerBlock = [
    ENDICIA,
    PITNEY,
    PITNEY_MERCHANT,
    PITNEY_PRESORT,
    VISIBLE_USPS,
  ].includes(shipperType)
    ? 0.99
    : 0.79
  const blockValue = 100

  const value = toNumber(insuredValue)

  return round(
    Math.ceil(Math.abs(value || blockValue) / blockValue) * costPerBlock,
    2,
  )
}

export function getInsuranceCost(includeInsurance, shipperType, insuredValue) {
  if (!includeInsurance) {
    return 0
  }

  return getShipsuranceCost(shipperType, insuredValue)
}

export const insuranceCostSelector = createSelector(
  includeInsuranceSelector,
  (_, {shipperType}) => shipperType,
  insuredValueSelector,
  getInsuranceCost,
)

function canHaveInsurance(shipperType) {
  return [
    PITNEY,
    PITNEY_MERCHANT,
    PITNEY_PRESORT,
    VISIBLE_USPS,
    ENDICIA,
    UPS,
    FEDEX,
  ].includes(shipperType)
}

export function canHaveInsuranceSelector(state, {labelInfoID, shipperType}) {
  const hasShipperInsurance = hasShipperInsuranceSelector(state, {
    labelInfoID,
    shipperType,
  })

  if (shipperType === ENDICIA && hasShipperInsurance) {
    return false
  }

  return canHaveInsurance(shipperType)
}

export function customsLineErrors(
  shipperType,
  {
    description,
    quantity,
    weightLB,
    weightOZ,
    value,
    sku,
    harmonizationCode,
    harmonizationCodeCountry,
    country,
  },
) {
  const errors = {}

  if (!isPresent(description)) {
    errors.description = 'Product description is required'
  }

  const maxDescriptionLength = getMaxDescriptionLength(shipperType)
  if (
    [
      PITNEY_CBDS,
      DHL_ECOMMERCE,
      ENDICIA,
      PITNEY,
      PITNEY_MERCHANT,
      PITNEY_PRESORT,
      VISIBLE_USPS,
    ].includes(shipperType) &&
    description.length > maxDescriptionLength
  ) {
    errors.description = `Product description is over the limit of ${maxDescriptionLength} characters.`
  }

  const maxSKULength = getMaxSKULength(shipperType)
  if (sku && sku.length > maxSKULength) {
    errors.sku = `Product sku is over the limit of ${maxSKULength} characters.`
  }

  if (!isNonZeroPositiveNumeric(quantity)) {
    errors.quantity = 'Quantity must be greater than 0'
  }

  const combinedWeight = combineToOz(weightLB, weightOZ)
  if ([FEDEX, UPS].includes(shipperType)) {
    if (!isPositiveNumeric(combinedWeight)) {
      errors.weight = 'Weight must be at least 0'
    }
  } else if (!isNonZeroPositiveNumeric(combinedWeight)) {
    errors.weight = 'Weight must be greater than 0'
  }

  if ([FEDEX, UPS].includes(shipperType)) {
    if (!isPositiveNumeric(value)) {
      errors.value = 'Product value must be at least 0'
    }
  } else if (!isNonZeroPositiveNumeric(value)) {
    errors.value = 'Product value must be greater than 0'
  }

  if ([DHL_ECOMMERCE].includes(shipperType) && !isPresent(sku)) {
    errors.sku = 'Product SKU is required'
  }

  if ([DHL_ECOMMERCE].includes(shipperType) && !isPresent(harmonizationCode)) {
    errors.harmonizationCode = 'Harmonization code is required'
  }

  if (
    isPresent(harmonizationCode) &&
    getCanHaveHarmonizationCodeCountry(shipperType) &&
    !isPresent(harmonizationCodeCountry)
  ) {
    errors.harmonizationCodeCountry = 'Destination country is required'
  }

  if (!isPresent(country)) {
    errors.country = 'Origin country is required'
  }

  return errors
}

export function customsLineWarnings(shipperType, {description}) {
  const warnings = {}

  const maxDescriptionLength = getMaxDescriptionLength(shipperType)
  if (
    [UPS, CANADA_POST].includes(shipperType) &&
    description.length > maxDescriptionLength
  ) {
    warnings.description = `Product description is over the limit of ${maxDescriptionLength} characters. This information will be automatically shortened to fit in the customs form.`
  }

  return warnings
}

export const getTotalQuantity = (customsInfo) =>
  customsInfo.reduce(
    (sum, customsLine) => sum + Number(customsLine.quantity),
    0,
  )

export const getTotalValue = (customsInfo) =>
  customsInfo.reduce(
    (sum, customsLine) =>
      sum + Number(customsLine.value) * Number(customsLine.quantity),
    0,
  )

export const getTotalWeight = (customsInfo) => {
  const totalWeight = customsInfo.reduce(
    (sum, customsLine) =>
      sum + combineToOz(customsLine.weightLB, customsLine.weightOZ),
    0,
  )

  return round(totalWeight, 2)
}

export function getMaxDescriptionLength(shipperType) {
  if (shipperType === UPS) {
    return 35
  }
  if (shipperType === CANADA_POST) {
    return 45
  }
  if (
    [
      DHL_ECOMMERCE,
      ENDICIA,
      PITNEY,
      PITNEY_MERCHANT,
      PITNEY_PRESORT,
      VISIBLE_USPS,
    ].includes(shipperType)
  ) {
    return 50
  }
  if (shipperType === PITNEY_CBDS) {
    return 255
  }
  return Infinity
}

export function getMaxSKULength(shipperType) {
  if (shipperType === PITNEY_CBDS) {
    return 50
  }
  return Infinity
}

export const getMaxLines = (shipperType) => {
  if (shipperType === FEDEX) {
    return 20
  }
  if (shipperType === UPS) {
    return Infinity
  }
  if (shipperType === PITNEY) {
    return 30
  }
  return 5
}

export const getMaxLinesError = (shipperType, customsInfo) => {
  const maxLines = getMaxLines(shipperType)
  if (customsInfo.length > maxLines) {
    return `This shipment has more than ${maxLines} lines, so you must consolidate some of the declarations so that the lines do not exceed this limit. Try bucketing similar items into a category (ex. t-shirts, electronics, shoes, etc) and be sure to add their weights together.`
  }

  return null
}

export const getMinLinesError = (shipperType, customsInfo) => {
  if (shipperType !== UPS && customsInfo.length === 0) {
    return 'This customs form needs to have at least one line.'
  }

  return null
}

export const getMinTotalValueError = (shipperType, customsInfo) => {
  const totalValue = getTotalValue(customsInfo)
  if (shipperType === FEDEX && totalValue <= 0) {
    return 'The total $ value must be greater than 0.'
  }

  return null
}

export const getCustomsWeightLowerThanLabelWeightError = (
  shipperType,
  customsInfo,
  labelWeight,
) => {
  const customsTotalWeight = getTotalWeight(customsInfo)
  if (
    [
      ENDICIA,
      FEDEX,
      PITNEY,
      PITNEY_MERCHANT,
      PITNEY_PRESORT,
      VISIBLE_USPS,
      AUSTRALIA_POST,
    ].includes(shipperType) &&
    customsTotalWeight > labelWeight
  ) {
    return `The total customs weight (${formatWeight(
      splitOz(customsTotalWeight),
    )}) must be less than the shipping label weight (${formatWeight(
      splitOz(labelWeight),
    )}).`
  }

  return null
}

export const getGeneralCustomsErrors = (
  shipperType,
  customsInfo,
  labelWeight,
) =>
  compact([
    getMaxLinesError(shipperType, customsInfo),
    getMinLinesError(shipperType, customsInfo),
    getMinTotalValueError(shipperType, customsInfo),
    getCustomsWeightLowerThanLabelWeightError(
      shipperType,
      customsInfo,
      labelWeight,
    ),
  ])

export const getAllCustomsErrors = (shipperType, customsInfo, labelWeight) => {
  const lineErrors = flatten(
    customsInfo.map((line) => values(customsLineErrors(shipperType, line))),
  )

  return compact(
    flatten([
      lineErrors,
      getGeneralCustomsErrors(shipperType, customsInfo, labelWeight),
    ]),
  )
}

export const getAllCustomsWarnings = (shipperType, customsInfo) => {
  const lineWarnings = flatten(
    customsInfo.map((line) => values(customsLineWarnings(shipperType, line))),
  )

  return compact(flatten([lineWarnings]))
}

export const totalQuantitySelector = createSelector(
  customsInfoSelector,
  getTotalQuantity,
)

export const totalValueSelector = createSelector(
  customsInfoSelector,
  getTotalValue,
)

export const totalWeightSelector = createSelector(
  customsInfoSelector,
  getTotalWeight,
)

export function customsLineSelector(state, {labelInfoID, customsInfoIndex}) {
  const customsInfo = customsInfoSelector(state, {labelInfoID})

  return customsInfo[customsInfoIndex]
}

export const customsLineErrorsSelector = createSelector(
  (_, {shipperType}) => shipperType,
  customsLineSelector,
  (shipperType, line) => customsLineErrors(shipperType, line),
)

export const customsLineWarningsSelector = createSelector(
  (_, {shipperType}) => shipperType,
  customsLineSelector,
  (shipperType, line) => customsLineWarnings(shipperType, line),
)

export const generalCustomsErrorsSelector = createSelector(
  (_, {shipperType}) => shipperType,
  customsInfoSelector,
  weightSelector,
  getGeneralCustomsErrors,
)

export const maxLinesSelector = createSelector(
  (_, {shipperType}) => shipperType,
  getMaxLines,
)

export const canAddLineSelector = createSelector(
  maxLinesSelector,
  customsInfoSelector,
  (maxLines, customsInfo) => maxLines > customsInfo.length,
)

export function getCanHaveHarmonizationCode(shipperType) {
  return [
    CANADA_POST,
    DHL,
    DHL_ECOMMERCE,
    ENDICIA,
    FEDEX,
    NEWGISTICS,
    PITNEY,
    PITNEY_CBDS,
    PITNEY_MERCHANT,
    PITNEY_PRESORT,
    SENDLE,
    UPS,
    VISIBLE_USPS,
  ].includes(shipperType)
}

export function getCanHaveHarmonizationCodeCountry(shipperType) {
  return [PITNEY_CBDS].includes(shipperType)
}

export function getCanHaveSKU(shipperType) {
  return [DHL_ECOMMERCE, PITNEY_CBDS].includes(shipperType)
}

// Special property that describes the properties that are used in a label config
// Used for editing presets
// We don't send this to the API
export function labelPropertiesSelector(state, {labelInfoID}) {
  const labelInfo = labelInfoSelector(state, {labelInfoID})

  if (!labelInfo.properties) {
    return labelPropertiesSelector.default
  }

  return labelInfo.properties
}
labelPropertiesSelector.default = []

export function isPresetLabelConfigSelector(state, {labelInfoID}) {
  return !!labelInfoSelector(state, {labelInfoID}).properties
}

export function isLabelPropertyEnabledSelector(
  state,
  {labelInfoID, labelProperty},
) {
  const properties = labelPropertiesSelector(state, {labelInfoID})

  return properties.includes(labelProperty)
}

export function areLabelPropertiesEnabledSelector(
  state,
  {labelInfoID, labelProperties = []},
) {
  const properties = labelPropertiesSelector(state, {labelInfoID})

  return labelProperties
    .map((labelProperty) => properties.includes(labelProperty))
    .every((isEnabled) => isEnabled)
}

export function updateLabelProperties(labelInfoID, labelProperties, enabled) {
  let properties = labelPropertiesSelector(getState(), {labelInfoID})

  properties = properties.filter((p) => !labelProperties.includes(p))

  if (enabled) {
    properties.push(...labelProperties)
  }

  updateLabelInfo(labelInfoID, {properties})
}

export function updateLabelProperty(labelInfoID, labelProperty, enabled) {
  updateLabelProperties(labelInfoID, [labelProperty], enabled)
}

/**
 * Convenient function to either get an existing label property chart or build one
 *
 * @param {object} chart
 * @param {string} labelProperty
 * @returns {{
 *  dependantPropertiesFunc: func,
 *  labelPropertiesFunc: func,
 *  validLabelProperties: Array,
 *  childSet: Set.<object>,
 *  reEvalOnChangeSet: Set,
 * }}
 */
function getPropChart(chart, labelProperty) {
  return (
    chart[labelProperty] || {
      dependantPropertiesFunc: null,
      labelPropertiesFunc: null,
      validLabelProperties: null,
      childSet: new Set(),
      reEvalOnChangeSet: new Set(),
    }
  )
}

/**
 * Store related functions and arrays need to build chart
 *
 * This is meant to be a fast function that just caches things. We will use
 * `buildChart()` to process these arrays and functions for real use.
 *
 * @param {string} labelInfoID
 * @param {string} labelProperty Primary label property for a given level
 * @param {Array} validShipperTypes Array of valid shipper type that this property applies to
 * @param {func} labelPropertiesFunc Returns an array of properties that applies given the current state
 * @param {func} dependantPropertiesFunc Returns an array of dependant (parent) properties that applies given the current state
 * @param {Array} validLabelProperties Array of all possible properties at this level
 * @returns {null}
 */
export function updateLabelPropertyChart(
  labelInfoID,
  labelProperty,
  validShipperTypes,
  labelPropertiesFunc,
  dependantPropertiesFunc,
  validLabelProperties,
) {
  const labelPropertySetup =
    labelInfoSelector(getState(), {labelInfoID}).labelPropertySetup || {}

  labelPropertySetup[labelProperty] = {
    validShipperTypes,
    labelPropertiesFunc,
    dependantPropertiesFunc,
    validLabelProperties,
  }

  updateLabelInfo(labelInfoID, {
    labelPropertySetup,
  })
}

/**
 * Build the chart of functions and arrays needed to determine valid properties
 *
 * We call this whenever we need the info because the arrays and functions
 * might change over time and instead of trying to memo this structure we just
 * build it as we need it. We should only need it on property enable/disable
 * and whenever a dependant value changes.
 *
 * @param {string} labelInfoID
 * @returns {null}
 */
function buildChart(labelInfoID) {
  const labelPropertySetup =
    labelInfoSelector(getState(), {labelInfoID}).labelPropertySetup || {}

  if (!labelPropertySetup) {
    return
  }

  const chart = {}

  for (const {
    validShipperTypes,
    labelPropertiesFunc,
    dependantPropertiesFunc,
    validLabelProperties,
  } of Object.values(labelPropertySetup)) {
    for (const shipperType of validShipperTypes) {
      const dependantProperties = dependantPropertiesFunc(
        shipperType,
        labelInfoID,
      )

      for (const labelProperty of validLabelProperties) {
        const propChart = getPropChart(chart, labelProperty)

        propChart.dependantPropertiesFunc = dependantPropertiesFunc
        propChart.labelPropertiesFunc = labelPropertiesFunc
        propChart.validLabelProperties = validLabelProperties

        chart[labelProperty] = propChart
      }

      for (const labelProperty of dependantProperties) {
        const propChart = getPropChart(chart, labelProperty)

        propChart.childSet.add({labelPropertiesFunc, validLabelProperties})

        chart[labelProperty] = propChart
      }
    }
  }

  return chart
}

/**
 * Called directly by checkbox to enable label property.
 *
 * If checked is `true` then it and it's dependant (parent) properties are
 * enabled.
 *
 * If checked is `false` then it's properties are disabled.
 *
 * It will remove any properties that don't apply any longer.
 *
 * @param {string} labelInfoID
 * @param {string} shipperType
 * @param {string} labelProperty
 * @param {boolean} checked
 * @returns {null}
 */
export function onLabelPropertyChange(
  labelInfoID,
  shipperType,
  labelProperty,
  checked,
) {
  const chart = buildChart(labelInfoID)
  const {properties} = labelInfoSelector(getState(), {labelInfoID})

  if (!chart || !properties) {
    return
  }

  const otherShipperTypes = SHIPPER_VENDORS.filter((st) => st !== shipperType)

  const propChart = chart[labelProperty]

  if (!propChart) {
    return
  }

  let newProperties = [...properties]

  if (checked) {
    // collect all possible properties that are related (directly or parent)
    const exclude = [
      ...propChart.validLabelProperties,
      ...uniq(
        otherShipperTypes.reduce(
          (prev, shipperType) => [
            ...prev,
            ...propChart.dependantPropertiesFunc(shipperType, labelInfoID),
          ],
          [],
        ),
      ),
    ]

    // remove the excluded properties to make way for the possibly different new properties
    newProperties = newProperties.filter(
      (labelProperty) => !exclude.includes(labelProperty),
    )

    // redetermine the properties for this label property
    // enable the dependant (parent) properties too
    newProperties.push(
      ...propChart.labelPropertiesFunc(shipperType, labelInfoID),
      ...propChart.dependantPropertiesFunc(shipperType, labelInfoID),
    )
  } else {
    // collect all possible properties that are related (directly or children)
    const exclude = [
      ...propChart.validLabelProperties,
      ...uniq(
        [...propChart.childSet.values()].reduce(
          (prev, {validLabelProperties}) => [...prev, ...validLabelProperties],
          [],
        ),
      ),
    ]

    // remove the excluded properties
    newProperties = newProperties.filter(
      (labelProperty) => !exclude.includes(labelProperty),
    )
  }

  newProperties = uniq(newProperties)

  if (!isEqual(properties, newProperties)) {
    updateLabelInfo(labelInfoID, {properties: newProperties})
  }
}

/**
 * Called whenever a value of a property changes that might affect the outcome
 * of a `labelPropertiesFunc()` outcome.
 *
 * This function will remove any property that no longer applies and apply
 * properties that now apply.
 *
 * @param {string} labelInfoID
 * @param {string} shipperType
 * @param {string} labelProperty Label property who's value changed
 * @returns {null}
 */
export function onLabelPropertyValueChange(
  labelInfoID,
  shipperType,
  labelProperty,
) {
  const chart = buildChart(labelInfoID)
  const {properties} = labelInfoSelector(getState(), {labelInfoID})

  if (!chart || !properties) {
    return
  }

  const propChart = chart[labelProperty]

  if (!propChart) {
    return
  }

  let newProperties = [...properties]

  // run through all associated properties
  for (const {labelPropertiesFunc, validLabelProperties} of [
    propChart,
    ...propChart.childSet.values(),
    ...propChart.reEvalOnChangeSet.values(),
  ]) {
    // labelPropertiesFunc() returns all properties that should be enabled based on current state
    const propertiesThatCouldEnable = labelPropertiesFunc(
      shipperType,
      labelInfoID,
    )

    // validLabelProperties is an array of all possible properties at this level
    // collect all current enabled properties into two buckets
    const [relatedProperties, unrelatedProperties] = newProperties.reduce(
      (prev, labelProperty) => {
        if (validLabelProperties.includes(labelProperty)) {
          // relatedProperties
          prev[0].push(labelProperty)
        } else {
          // unrelatedProperties
          prev[1].push(labelProperty)
        }
        return prev
      },
      [[], []],
    )

    // keep the unrelated properties
    newProperties = unrelatedProperties

    // if there are currently enabled related properties then enable this levels properties
    if (relatedProperties.length) {
      newProperties.push(...propertiesThatCouldEnable)
    }
  }

  newProperties = uniq(newProperties)

  if (!isEqual(properties, newProperties)) {
    updateLabelInfo(labelInfoID, {properties: newProperties})
  }
}
