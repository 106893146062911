import className from '../../../common/className.js'
import {PACKING_LIST_SETTINGS_LINK} from '../../../common/constants/SettingsPanels.js'
import {useSelector} from '../../../store.js'
import Form from './Form.js'
import {
  selectedProfileTabSelector,
  settingsPackingListsIDSelector,
} from './packingListsFunctions.js'

export default function EditForm({packingList}) {
  const id = useSelector(settingsPackingListsIDSelector)
  const selectedProfileTab = useSelector(selectedProfileTabSelector)

  const isExpanded = packingList.id === id
  const isDefault = packingList.is_default

  return (
    <li
      className={className`settings-list-item clearfix ${{
        expanded: isExpanded,
      }}`}
    >
      <div className="clearfix">
        <strong
          className={className`settings-list-item-title medium-5 columns ${{
            default: isDefault,
          }}`}
        >
          <span className="make-block warehouse-name-wrap">
            <span
              className="icon warehouse-status-icon x-sm margin-right-1"
              aria-hidden="true"
            />
            <span>{packingList.name}</span>
          </span>
          {isDefault && (
            <span className="make-block default-callout">
              <span>DEFAULT</span>
            </span>
          )}
        </strong>
        <span className="medium-2 columns end right-aligned-text right">
          <a
            className="inline-text-button settings-list-item-button edit-url"
            href={`${PACKING_LIST_SETTINGS_LINK}/${selectedProfileTab}/${packingList.id}`}
          >
            <span
              className="icon icon-edit icon-block x-sm margin-right-2"
              aria-hidden="true"
            />
            <span className="button-text">Edit</span>
          </a>
        </span>
      </div>
      {isExpanded && <Form />}
    </li>
  )
}
