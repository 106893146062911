import {useEffect} from 'react'
import classNames from 'classnames'
import addBusinessDays from 'date-fns/addBusinessDays'

import {
  IS_DEV_ENV,
  IS_PROD_ENV,
  NEW_ID,
} from '../../../common/constants/index.js'
import {
  companyIDSelector,
  companyNameSelector,
  currencySymbolSelector,
  hasUseReturnOrdersFeatureSelector,
  useBrandedTrackingSelector,
} from '../../../data/company.js'
import Checkbox from '../../../common/components/Checkbox.js'
import TextInput from '../../../common/components/TextInput.js'
import TextArea from '../../../common/components/TextArea.js'
import LogoUpload from './LogoUpload.js'
import analytics from '../../../common/analytics/index.js'
import {formSelector, getState, useSelector} from '../../../store.js'
import {
  PROFILES_BRANDED_TRACKING,
  PROFILES_GENERAL,
  PROFILES_PACKING_LIST,
  PROFILES_RETURN_ORDER,
  PROFILES_SHIP_FROM_ALIAS,
  errorsSelector,
  packingListsFormSelector,
  savePackingList,
  selectedProfileTabSelector,
  settingsPackingListsAddSelector,
  settingsPackingListsIDSelector,
  toggleBrandedTracking,
  updatePackingListsForm,
} from './packingListsFunctions.js'
import {PACKING_LIST_SETTINGS_LINK} from '../../../common/constants/SettingsPanels.js'
import ButtonPrimary from '../../../common/components/Button/ButtonPrimary.js'
import LinkButton from '../../../common/components/Link/LinkButton.js'
import {showConfirmMessageModal} from '../../../common/components/Modal/ConfirmMessageModal.js'
import Zelect from '../../../common/components/Zelect.js'
import {isValidURL} from '../../../common/utils.js'
import openNewTab from '../../../common/openNewTab.js'
import ButtonLink from '../../../common/components/Button/ButtonLink.js'

function RMAFeaturePage() {
  useEffect(() => {
    analytics.trackEvent('rma_feature_lock')
  }, [])

  return (
    <div className="alert alert--lg alert--warning-lt margin-top-20 margin-bottom-20 align-center">
      <div
        className="i-lock-closed fs-03 op-30 lh-sm margin-bottom-0"
        aria-hidden="true"
      />
      <p className="align-center fs-01 lh-md  margin-bottom-15">
        <strong>Returns Management is not enabled.</strong>
      </p>
      <p className="fs-00 margin-bottom-20">
        To access this feature,{' '}
        <a
          className="btn--link mid border-underline meta-feature-lock-settings-return-email"
          href="https://www.ordoro.com/product-expert-consultation"
          target="_blank"
          rel="noopener noreferrer"
        >
          reach out to us
        </a>
        .
      </p>
      <div className="margin-bottom-10">
        <a
          className="btn btn--primary btn--primary-alt btn--sm meta--feature-lock-settings-return-email"
          href="https://www.ordoro.com/product-expert-consultation"
          target="_blank"
          rel="noopener noreferrer"
        >
          Talk to a Product Expert
        </a>
      </div>
    </div>
  )
}

const SOCIAL_LINK_OPTIONS = [
  {value: 'fb', display: 'Facebook'},
  {value: 'insta', display: 'Instagram'},
  {value: 'linkedin', display: 'LinkedIn'},
  {value: 'x-twitter', display: 'X (formerly Twitter)'},
  {value: 'youtube', display: 'Youtube'},
]

async function editSocialLink(index) {
  const form = packingListsFormSelector(getState())
  const existingLinks = form.branded_tracking__social_links.map(
    ({icon}) => icon,
  )
  const link = form.branded_tracking__social_links[index]
  const isNew = !link
  const socialLinkOptions = SOCIAL_LINK_OPTIONS.filter(
    ({value}) => value === link?.icon || !existingLinks.includes(value),
  )

  const results = await showConfirmMessageModal({
    title: isNew ? 'Add Social Media Account' : 'Edit Social Media Account',
    Message({form, setModalFormValue}) {
      return (
        <ul className="list list--no-style">
          <li className="list list__item margin-bottom-15">
            <Zelect
              label="Social Media Network"
              id="icon"
              value={form.confirmValue.icon}
              onChange={(option) => {
                setModalFormValue('confirmValue.icon', option.value)
              }}
              options={socialLinkOptions}
            />
          </li>
          <li className="list list__item margin-bottom-15">
            <TextInput
              label="Social Media Page URL"
              id="url"
              required
              className="input--med-h"
              value={form.confirmValue.url}
              onChange={(value) => setModalFormValue('confirmValue.url', value)}
            />
          </li>
          <li className="list list__item margin-bottom-15">
            <TextInput
              label="Hover Text for Social Icon"
              id="title"
              className="input--med-h"
              value={form.confirmValue.title}
              onChange={(value) =>
                setModalFormValue('confirmValue.title', value)
              }
            />
          </li>
        </ul>
      )
    },
    confirmText: 'Save',
    cancelText: 'Cancel',
    errorsSelector(state, {formName}) {
      const errors = {}
      const {
        confirmValue: {url},
      } = formSelector(state, {formName})

      if (!isValidURL(url)) {
        errors.url = 'URL is required'
        errors.preventSave = true
      }

      return errors
    },
    confirmValue: {
      url: link?.url || '',
      title: link?.title || '',
      icon: link?.icon || SOCIAL_LINK_OPTIONS[0].value,
    },
  })

  if (results === false) {
    return
  }

  const socialLinks = [...form.branded_tracking__social_links]

  if (isNew) {
    socialLinks.push(results)
  } else {
    socialLinks[index] = results
  }

  updatePackingListsForm({
    branded_tracking__social_links: socialLinks,
  })
}

function removeSocialLink(index) {
  const form = packingListsFormSelector(getState())
  const socialLinks = form.branded_tracking__social_links.filter(
    (_, i) => i !== index,
  )

  updatePackingListsForm({
    branded_tracking__social_links: socialLinks,
  })
}

function previewBrandedTracking() {
  const form = packingListsFormSelector(getState())
  const show_images = form.branded_tracking__show_images
  const show_prices = form.branded_tracking__show_prices
  const show_ordoro_product_name =
    form.branded_tracking__show_ordoro_product_name
  const show_zero_quantity_lines =
    form.branded_tracking__show_zero_quantity_lines

  const payload = {
    company: {
      name: companyNameSelector(getState()),
      currency_symbol: currencySymbolSelector(getState()),
    },
    packing_list: {
      _linklogo: form._linklogo,
      logo_hash: form.logo_hash,
      website: form.website,
      branded_tracking: {
        phone: form.branded_tracking__phone,
        email: form.branded_tracking__email,
        return_policy_url: form.branded_tracking__return_policy_url,
        additional_info: form.branded_tracking__additional_info,
        social_links: form.branded_tracking__social_links,
        show_images,
        show_prices,
        show_ordoro_product_name,
        show_zero_quantity_lines,
        logo_hash: form.branded_tracking__logo_hash,
      },
    },
    tracking: {
      company_id: companyIDSelector(getState()),
      estimated_delivery_date: addBusinessDays(new Date(), 4).toISOString(),
      tracking_url: 'https://www.google.com/search?q=9434640109563001643729',
      tracking: '9434640109563001643729',
      carrier_name: 'pitney',
      carrier_updated_date: new Date().toISOString(),
      status: 'in_transit',
    },
    order_number: '3-192881-9182',
    lines: [
      {
        product_name: 'Teddy Bear',
        product_image_url: show_images
          ? 'https://abode.ordoro.com/cache_public/demo/P6bqF_2YoI5O_QPtDfiCHbOCv69c8SNA/teddy_bear_w70.png'
          : null,
        quantity: 1,
        total_price: show_prices ? 10 : null,
      },
      ...(show_zero_quantity_lines
        ? [
            {
              product_name: 'ABC Book',
              product_image_url: null,
              quantity: 0,
              total_price: show_prices ? 2.34 : null,
            },
          ]
        : []),
      {
        product_name: 'Toy Blocks',
        product_image_url: show_images
          ? 'https://abode.ordoro.com/cache_public/demo/P6bqF_2YoI5O_QPtDfiCHbOCv69c8SNA/toy_blocks_w70.png'
          : null,
        quantity: 2384,
        total_price: show_prices ? 4.34 : null,
      },
      {
        product_name: 'Fire Trucks',
        product_image_url: show_images
          ? 'https://abode.ordoro.com/cache_public/demo/P6bqF_2YoI5O_QPtDfiCHbOCv69c8SNA/fire_truck_w70.png'
          : null,
        quantity: 1,
        total_price: show_prices ? 15.74 : null,
      },
      {
        product_name: 'Doll House',
        product_image_url: show_images
          ? 'https://abode.ordoro.com/cache_public/demo/P6bqF_2YoI5O_QPtDfiCHbOCv69c8SNA/doll_house_w70.png'
          : null,
        quantity: 23,
        total_price: show_prices ? 1089.99 : null,
      },
    ],
  }

  const url = new URL(
    IS_PROD_ENV
      ? 'https://tracking.ordoro.com/demo'
      : IS_DEV_ENV
        ? 'https://tracking.ordoro-dev.com/demo'
        : 'https://localhost:8443/tracking/demo',
  )

  url.search = new URLSearchParams({_p: JSON.stringify(payload)})

  openNewTab(url)
}

export default function Form() {
  const form = useSelector(packingListsFormSelector)
  const errors = useSelector(errorsSelector)
  const selectedProfileTab = useSelector(selectedProfileTabSelector)
  let id = useSelector(settingsPackingListsIDSelector)
  const isNew = useSelector(settingsPackingListsAddSelector)
  const hasUseReturnOrdersFeature = useSelector(
    hasUseReturnOrdersFeatureSelector,
  )
  const useBrandedTracking = useSelector(useBrandedTrackingSelector)

  id = isNew ? NEW_ID : id

  return (
    <>
      <div className="wrap--row margin-top-10 margin-bottom-25">
        <div className="medium-12 columns">
          <div className="wrap--sub-nav">
            <nav className="list--sub-nav">
              <div className="list__item--sub-nav wider">
                <a
                  className={`sub-nav__link margin-left-0 ${
                    selectedProfileTab === PROFILES_GENERAL ? 'active' : ''
                  }`}
                  href={`${PACKING_LIST_SETTINGS_LINK}/${PROFILES_GENERAL}/${id}`}
                >
                  General Info
                </a>
              </div>
              <div className="list__item--sub-nav wider">
                <a
                  className={`sub-nav__link ${
                    selectedProfileTab === PROFILES_PACKING_LIST ? 'active' : ''
                  }`}
                  href={`${PACKING_LIST_SETTINGS_LINK}/${PROFILES_PACKING_LIST}/${id}`}
                >
                  Packing List Content
                </a>
              </div>
              <div className="list__item--sub-nav wider">
                <a
                  className={`sub-nav__link ${
                    selectedProfileTab === PROFILES_RETURN_ORDER ? 'active' : ''
                  }`}
                  href={`${PACKING_LIST_SETTINGS_LINK}/${PROFILES_RETURN_ORDER}/${id}`}
                >
                  Return Email Content
                </a>
              </div>
              <div className="list__item--sub-nav wider">
                <a
                  className={`sub-nav__link ${
                    selectedProfileTab === PROFILES_SHIP_FROM_ALIAS
                      ? 'active'
                      : ''
                  }`}
                  href={`${PACKING_LIST_SETTINGS_LINK}/${PROFILES_SHIP_FROM_ALIAS}/${id}`}
                >
                  Ship From Alias
                </a>
              </div>
              {useBrandedTracking && (
                <div className="list__item--sub-nav wider">
                  <a
                    className={`sub-nav__link ${
                      selectedProfileTab === PROFILES_BRANDED_TRACKING
                        ? 'active'
                        : ''
                    }`}
                    href={`${PACKING_LIST_SETTINGS_LINK}/${PROFILES_BRANDED_TRACKING}/${id}`}
                  >
                    Branded Tracking Page
                  </a>
                </div>
              )}
            </nav>
          </div>
        </div>
      </div>
      <div className="wrap--row">
        <div className="medium-6 columns end">
          <form>
            <fieldset>
              <ul className="list list--no-style margin-bottom-20">
                {selectedProfileTab === PROFILES_GENERAL && (
                  <>
                    <li className="list__item list__item--no-style fs-00 lh-md margin-bottom-20">
                      This information will appear on packing lists, shipping
                      labels and emails that this profile is assigned to.
                    </li>
                    <li className="list__item list__item--no-style margin-bottom-15">
                      <TextInput
                        id="packing_list_name"
                        label="Profile Name"
                        className="input--tall"
                        value={form.name}
                        onChange={(name) =>
                          updatePackingListsForm({name, hasChanged__name: true})
                        }
                        errorMessage={form.hasChanged__name && errors.name}
                        required
                      />
                    </li>
                    <li className="list__item list__item--no-style margin-bottom-15">
                      <TextInput
                        id="email"
                        label="Email"
                        className="input--tall"
                        value={form.email}
                        onChange={(email) =>
                          updatePackingListsForm({
                            email,
                            hasChanged__email: true,
                          })
                        }
                        errorMessage={form.hasChanged__email && errors.email}
                      />
                    </li>
                    <li className="list__item list__item--no-style margin-bottom-15">
                      <TextInput
                        id="phone"
                        label="Phone Number"
                        className="input--tall"
                        value={form.phone}
                        onChange={(phone) =>
                          updatePackingListsForm({
                            phone,
                            hasChanged__phone: true,
                          })
                        }
                        errorMessage={form.hasChanged__phone && errors.phone}
                      />
                    </li>
                    <li className="list__item list__item--no-style margin-bottom-15">
                      <TextInput
                        id="website"
                        label="Website URL"
                        className="input--tall"
                        value={form.website}
                        onChange={(website) =>
                          updatePackingListsForm({
                            website,
                            hasChanged__website: true,
                          })
                        }
                        errorMessage={
                          form.hasChanged__website && errors.website
                        }
                      />
                    </li>
                    <li className="list__item list__item--no-style">
                      <LogoUpload property="logo_hash" />
                    </li>
                    <li className="list__item list__item--no-style divider--bottom dark margin-bottom-25 padding-bottom-20">
                      <Checkbox
                        id="is_default"
                        label="Make this the default profile"
                        checked={form.is_default}
                        onChange={(is_default) =>
                          updatePackingListsForm({
                            is_default,
                            hasChanged__is_default: true,
                          })
                        }
                        errorMessage={
                          form.hasChanged__is_default && errors.is_default
                        }
                        disabled={form.original_is_default}
                      />
                    </li>
                  </>
                )}
                {selectedProfileTab === PROFILES_PACKING_LIST && (
                  <>
                    <li className="list__item list__item--no-style fs-00 lh-md margin-bottom-20">
                      Along with the general profile and related order
                      information, this content will appear on all packing lists
                      that this profile is assigned to.
                    </li>
                    <li className="list__item list__item--no-style margin-bottom-15">
                      <TextInput
                        id="customer_notes_label"
                        label="Label for Customer Notes Section"
                        className="input--tall"
                        placeholder="Customer Notes"
                        value={form.customer_notes_label}
                        onChange={(customer_notes_label) =>
                          updatePackingListsForm({
                            customer_notes_label,
                            hasChanged__customer_notes_label: true,
                          })
                        }
                        errorMessage={
                          form.hasChanged__customer_notes_label &&
                          errors.customer_notes_label
                        }
                      />
                    </li>
                    <li className="list__item list__item--no-style margin-bottom-15">
                      <TextArea
                        id="custom_text"
                        label="Custom Text"
                        className="textarea textarea-md"
                        placeholder="101 River St. Marshalltown, IA 50158"
                        value={form.custom_text}
                        onChange={(custom_text) =>
                          updatePackingListsForm({
                            custom_text,
                            hasChanged__custom_text: true,
                          })
                        }
                        errorMessage={
                          form.hasChanged__custom_text && errors.custom_text
                        }
                      />
                    </li>
                    <li className="list__item list__item--no-style divider--bottom dark margin-bottom-25 padding-bottom-20">
                      <TextArea
                        id="footer"
                        label="Footer Message"
                        className="textarea textarea-md"
                        value={form.footer}
                        onChange={(footer) =>
                          updatePackingListsForm({
                            footer,
                            hasChanged__footer: true,
                          })
                        }
                        errorMessage={form.hasChanged__footer && errors.footer}
                      />
                    </li>
                  </>
                )}
                {selectedProfileTab === PROFILES_RETURN_ORDER &&
                  (hasUseReturnOrdersFeature ? (
                    <>
                      <li className="list__item list__item--no-style fs-00 lh-md margin-bottom-20">
                        Along with the general profile and related order info,
                        this content will appear in all return order emails that
                        this profile is assigned to.
                      </li>
                      <li className="list__item list__item--no-style margin-bottom-15">
                        <TextInput
                          id="return_email_subject"
                          label="Subject Line"
                          className="input--tall"
                          value={form.return_email_subject}
                          onChange={(return_email_subject) =>
                            updatePackingListsForm({
                              return_email_subject,
                              hasChanged__return_email_subject: true,
                            })
                          }
                          errorMessage={
                            form.hasChanged__returnSubjectLine &&
                            errors.returnSubjectLine
                          }
                        />
                      </li>
                      <li className="list__item list__item--no-style divider--bottom dark margin-bottom-25 padding-bottom-20">
                        <TextArea
                          id="return_email_content"
                          label="Return Email Content"
                          placeholder="This is typically used for instructions regarding a return"
                          className="textarea textarea--md"
                          value={form.return_email_content}
                          onChange={(return_email_content) =>
                            updatePackingListsForm({
                              return_email_content,
                              hasChanged__return_email_content: true,
                            })
                          }
                          errorMessage={
                            form.hasChanged__returnInstructions &&
                            errors.returnInstructions
                          }
                        />
                      </li>
                    </>
                  ) : (
                    <RMAFeaturePage />
                  ))}
                {selectedProfileTab === PROFILES_SHIP_FROM_ALIAS && (
                  <>
                    <li className="list__item list__item--no-style fs-00 lh-md margin-bottom-20">
                      Text entered here will replace the “Warehouse Name“ above
                      the Ship From address on shipping labels printed with this
                      profile. This is particularly useful if you have multiple
                      sales channels that use the same warehouse for storing
                      inventory.{' '}
                      <strong>
                        <a
                          href="https://support.ordoro.com/how-the-ship-from-alias-works/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Learn more
                        </a>
                      </strong>{' '}
                      <span className="op-50">→</span>
                    </li>
                    <li className="list__item list__item--no-style divider--bottom dark margin-bottom-25 padding-bottom-20">
                      <TextInput
                        id="ship_from_alias_name"
                        label="Ship From Alias Name"
                        className="input--tall"
                        value={form.ship_from_alias_name}
                        onChange={(ship_from_alias_name) =>
                          updatePackingListsForm({
                            ship_from_alias_name,
                            hasChanged__ship_from_alias_name: true,
                          })
                        }
                        errorMessage={
                          form.hasChanged__ship_from_alias_name &&
                          errors.ship_from_alias_name
                        }
                      />
                    </li>
                  </>
                )}
                {useBrandedTracking &&
                  selectedProfileTab === PROFILES_BRANDED_TRACKING && (
                    <>
                      <li className="list__item list__item--no-style fs-00 lh-md margin-bottom-30">
                        If enabled, your customers will be linked to this
                        customizable Branded Tracking Page — instead of the
                        standard carrier tracking page — from tracking emails
                        sent by Ordoro.
                      </li>
                      <li className="list__item list__item--no-style flex align-items-center margin-bottom-30">
                        <div className="w-10 margin-right-7">
                          <Checkbox
                            checked={form.hasBrandedTracking}
                            mode="switch"
                            id="has_branded_tracking"
                            onChange={toggleBrandedTracking}
                          />
                        </div>

                        <label
                          className={classNames('fs-01 lh-md margin-bottom-0', {
                            'op-50': !form.hasBrandedTracking,
                          })}
                          htmlFor="has_branded_tracking"
                        >
                          Branded Tracking Page{' '}
                          {!form.hasBrandedTracking ? 'Disabled' : 'Enabled'}
                        </label>
                      </li>
                      {form.hasBrandedTracking && (
                        <>
                          <li className="list__item list__item--no-style margin-bottom-20">
                            <ButtonPrimary
                              isOutlined
                              size="sm"
                              className="flex align-items-center"
                              onClick={() => previewBrandedTracking()}
                            >
                              <span
                                className="i-show fs-04 lh-0 margin-right-3"
                                aria-hidden="true"
                              />
                              <span className="fs-01 lh-md">
                                Preview Your Page →
                              </span>
                            </ButtonPrimary>
                            <div className="fs-n1 lh-md margin-top-10 op-50">
                              <em>
                                The background watermark will not appear on live
                                tracking pages.
                              </em>
                            </div>
                          </li>
                          <li className="list__item list__item--no-style wrap--tracking-logo-upload margin-bottom-20">
                            <LogoUpload property="branded_tracking__logo_hash" />
                          </li>
                          <li className="list__item list__item--no-style margin-bottom-15">
                            <h3 className="fs-01 lh-md margin-bottom-0">
                              <strong>Shipment Details</strong>
                            </h3>
                          </li>

                          <li className="list__item list__item--no-style margin-bottom-10">
                            <Checkbox
                              id="branded_tracking__show_images"
                              label="Show Product Images"
                              checked={form.branded_tracking__show_images}
                              onChange={(branded_tracking__show_images) =>
                                updatePackingListsForm({
                                  branded_tracking__show_images,
                                  hasChanged__branded_tracking__show_images: true,
                                })
                              }
                              errorMessage={
                                form.hasChanged__branded_tracking__show_images &&
                                errors.branded_tracking__show_images
                              }
                            />
                          </li>
                          <li className="list__item list__item--no-style margin-bottom-10">
                            <Checkbox
                              id="branded_tracking__show_prices"
                              label="Show Product Costs"
                              checked={form.branded_tracking__show_prices}
                              onChange={(branded_tracking__show_prices) =>
                                updatePackingListsForm({
                                  branded_tracking__show_prices,
                                  hasChanged__branded_tracking__show_prices: true,
                                })
                              }
                              errorMessage={
                                form.hasChanged__branded_tracking__show_prices &&
                                errors.branded_tracking__show_prices
                              }
                            />
                          </li>
                          <li className="list__item list__item--no-style margin-bottom-10">
                            <Checkbox
                              id="branded_tracking__show_ordoro_product_name"
                              label="Show Ordoro Product Name"
                              checked={
                                form.branded_tracking__show_ordoro_product_name
                              }
                              onChange={(
                                branded_tracking__show_ordoro_product_name,
                              ) =>
                                updatePackingListsForm({
                                  branded_tracking__show_ordoro_product_name,
                                  hasChanged__branded_tracking__show_ordoro_product_name: true,
                                })
                              }
                              errorMessage={
                                form.hasChanged__branded_tracking__show_ordoro_product_name &&
                                errors.branded_tracking__show_ordoro_product_name
                              }
                            />
                          </li>
                          <li className="list__item list__item--no-style margin-bottom-10">
                            <Checkbox
                              id="branded_tracking__show_zero_quantity_lines"
                              label="Show Lines with Zero Quantity"
                              checked={
                                form.branded_tracking__show_zero_quantity_lines
                              }
                              onChange={(
                                branded_tracking__show_zero_quantity_lines,
                              ) =>
                                updatePackingListsForm({
                                  branded_tracking__show_zero_quantity_lines,
                                  hasChanged__branded_tracking__show_zero_quantity_lines: true,
                                })
                              }
                              errorMessage={
                                form.hasChanged__branded_tracking__show_zero_quantity_lines &&
                                errors.branded_tracking__show_zero_quantity_lines
                              }
                            />
                          </li>
                          <li className="list__item list__item--no-style divider--top dark margin-top-30 padding-top-20 margin-bottom-15">
                            <h3 className="fs-01 lh-md margin-top-0 margin-bottom-0">
                              <strong>Store Info</strong>
                            </h3>
                          </li>
                          <li className="list__item list__item--no-style margin-bottom-15">
                            <TextInput
                              id="branded_tracking__email"
                              label="Customer Support Email"
                              className="input--tall"
                              value={form.branded_tracking__email}
                              onChange={(branded_tracking__email) =>
                                updatePackingListsForm({
                                  branded_tracking__email,
                                  hasChanged__branded_tracking__email: true,
                                })
                              }
                              errorMessage={
                                form.hasChanged__branded_tracking__email &&
                                errors.branded_tracking__email
                              }
                              required
                            />
                          </li>
                          <li className="list__item list__item--no-style margin-bottom-15">
                            <TextInput
                              id="branded_tracking__phone"
                              label="Customer Support Phone"
                              className="input--tall"
                              value={form.branded_tracking__phone}
                              onChange={(branded_tracking__phone) =>
                                updatePackingListsForm({
                                  branded_tracking__phone,
                                  hasChanged__branded_tracking__phone: true,
                                })
                              }
                              errorMessage={
                                form.hasChanged__branded_tracking__phone &&
                                errors.branded_tracking__phone
                              }
                            />
                          </li>
                          <li className="list__item list__item--no-style margin-bottom-15">
                            <TextInput
                              id="branded_tracking__return_policy_url"
                              label="Return Policy URL"
                              className="input--tall"
                              value={form.branded_tracking__return_policy_url}
                              onChange={(branded_tracking__return_policy_url) =>
                                updatePackingListsForm({
                                  branded_tracking__return_policy_url,
                                  hasChanged__branded_tracking__return_policy_url: true,
                                })
                              }
                              errorMessage={
                                form.hasChanged__branded_tracking__return_policy_url &&
                                errors.branded_tracking__return_policy_url
                              }
                            />
                          </li>
                          <li className="list__item list__item--no-style margin-bottom-10">
                            <TextArea
                              id="branded_tracking__additional_info"
                              label="Additional Message or Info"
                              className="textarea"
                              placeholder="Leave a quick note to your customers..."
                              value={form.branded_tracking__additional_info}
                              onChange={(branded_tracking__additional_info) =>
                                updatePackingListsForm({
                                  branded_tracking__additional_info,
                                  hasChanged__branded_tracking__additional_info: true,
                                })
                              }
                              errorMessage={
                                form.hasChanged__branded_tracking__additional_info &&
                                errors.branded_tracking__additional_info
                              }
                            />
                          </li>
                          <li className="list__item list__item--no-style divider--top dark margin-top-30 padding-top-20 margin-bottom-10">
                            <h3 className="fs-01 lh-md margin-top-0 margin-bottom-0">
                              <strong>
                                Connect Your Social Media Accounts
                              </strong>
                            </h3>
                          </li>
                          <li className="list__item list__item--no-style fs-n1 lh-md margin-bottom-15">
                            Display links to your Facebook, Instagram, LinkedIn,
                            X (formerly Twitter), and YouTube pages.
                          </li>
                          <li className="list__item list__item--no-style margin-bottom-20">
                            <ul className="list list--no-style">
                              {form.branded_tracking__social_links.map(
                                (link, index) => (
                                  <li
                                    key={index}
                                    className="list__item divider--bottom flex--justify align-items-center margin-bottom-10"
                                  >
                                    <div className="flex align-items-center">
                                      <span
                                        className={`i-${link.icon} fs-01 lh-0 margin-right-7`}
                                        aria-hidden="true"
                                      />
                                      <a
                                        className="btn btn--link fs-n0 lh-md"
                                        href={link.url}
                                        title={link.title}
                                      >
                                        {link.url}
                                      </a>
                                    </div>
                                    <div className="flex align-items-center">
                                      <ButtonPrimary
                                        isOutlined
                                        size="x-sm"
                                        onClick={() => editSocialLink(index)}
                                      >
                                        Edit
                                      </ButtonPrimary>
                                      <ButtonLink
                                        size="x-sm"
                                        onClick={() => removeSocialLink(index)}
                                      >
                                        Remove
                                      </ButtonLink>
                                    </div>
                                  </li>
                                ),
                              )}
                              {form.branded_tracking__social_links.length <
                                SOCIAL_LINK_OPTIONS.length && (
                                <li className="list__item list__item--no-style margin-top-15 margin-bottom-15">
                                  <ButtonPrimary
                                    isOutlined
                                    size="x-sm"
                                    onClick={() => editSocialLink()}
                                  >
                                    Add a Social Media Link
                                  </ButtonPrimary>
                                </li>
                              )}
                            </ul>
                          </li>
                          <li className="list__item list__item--no-style divider--top margin-top-30 padding-top-20 divider--bottom dark margin-bottom-25 padding-bottom-20">
                            <ButtonPrimary
                              isOutlined
                              size="sm"
                              className="flex align-items-center"
                              onClick={() => previewBrandedTracking()}
                            >
                              <span
                                className="i-show fs-04 lh-0 margin-right-3"
                                aria-hidden="true"
                              />
                              <span className="fs-01 lh-md">
                                Preview Your Page →
                              </span>
                            </ButtonPrimary>
                            <div className="fs-n1 lh-md margin-top-10 op-50">
                              <em>
                                The background watermark will not appear on live
                                tracking pages.
                              </em>
                            </div>
                          </li>
                        </>
                      )}
                    </>
                  )}
                {form.serverError && (
                  <li className="form-list-item fs-00 margin-bottom-20 alert alert--error">
                    {form.serverError}
                  </li>
                )}
                <li className="list__item list__item--no-style">
                  <ButtonPrimary
                    onClick={savePackingList}
                    isDisabled={errors.preventSave}
                    isLoading={form.isSaving}
                  >
                    {isNew ? 'Add' : 'Save'}
                  </ButtonPrimary>
                  <LinkButton
                    mode="secondary"
                    href={`${PACKING_LIST_SETTINGS_LINK}/${selectedProfileTab}`}
                  >
                    Cancel
                  </LinkButton>
                </li>
              </ul>
            </fieldset>
          </form>
        </div>
      </div>
    </>
  )
}
