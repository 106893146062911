import {IS_LOCAL_ENV} from '../../common/constants/index.js'
import {USER_VALIDITY_UNKNOWN} from '../../common/constants/User.js'

export default {
  data: {
    hasLoaded: {
      apiKeys: false,
      packingLists: false,
    },
    apiKeys: {},
    commonAppMessage: {
      appMessages: [],
      dismissedAppMessageIDs: [],
    },
    shipperOptions: {},
    productKits: {
      kitGraphs: {},
      skuMappings: {},
    },
    products: {},
    tasks: {},
    isRunningTasks: false,
    serverInfo: {
      version: null,
      needsAppReload: false,
    },
    purchaseOrders: {},
    returnOrders: {},
    returnOrderCodes: {},
    currentDate: null,
  },
  ui: {
    isLocalhost: IS_LOCAL_ENV,
    route: {
      pathComponents: [],
      query: {},
    },
    currentPage: '',
    isStarted: false,
    isLoading: true,
    currentDropdown: null,
    globalError: {
      summary: null,
      details: null,
    },
    endiciaAccountError: null,
    notification: null,
    modal: {
      current: null,
    },
    isRequesting: false,
    apiLockedLatch: false,
    zuckCompanyID: null,
    zuckSuppressAppMessage: false,
    location: {
      pathComponents: [],
      query: {},
    },
    userValidity: USER_VALIDITY_UNKNOWN,
    modals: {
      editTagModal: {
        link: '',
        text: '',
        color: '',
        hasChanged: {},
        isSaving: false,
      },
      exportOrdersModal: {
        startDate: null,
        endDate: null,
        detailLevel: '',
        status: '',
        cart: '',
        supplierID: '',
        tags: [],
        dateType: 'created_date',
        allocationStatus: '',
        isRequesting: false,
        serverError: null,
      },
      exportInsuranceHistoryModal: {
        startDate: null,
        endDate: null,
        isRequesting: false,
        serverError: null,
      },
      splitOrderModal: {
        orderNumber: null,
        targetLines: [],
        hasChanged: {},
        isSaving: false,
      },
      confirmAttemptToAllocateModal: {
        orders: [],
        isSaving: false,
      },
      confirmDeallocateModal: {
        orders: [],
        isSaving: false,
      },
      confirmTrackingNumberDeleteModal: {
        orderNumber: null,
        notifyCart: false,
        isDeleting: false,
      },
      confirmDropshipProductModal: {
        productCount: 0,
      },
      confirmFBAToggleModal: {
        isChangingToFBA: false,
      },
      endiciaCreditCardModal: {
        isSaving: false,
        shipperID: null,
        serverError: null,
        form: {
          cardNumber: '',
          cardCVV: '',
          street1: '',
          city: '',
          state: '',
          zip: '',
          expirationMonth: '',
          expirationYear: '',
        },
        hasChanged: {},
      },
      updateCreditCardModal: {
        isSaving: false,
        serverError: null,
        form: {
          name: '',
          isCardComplete: false,
        },
        hasChanged: {},
      },
      appMessageModal: {
        needsRefresh: false,
      },
      listOnAmazonModal: {
        skus: [],
      },
      createPOModal: {
        skus: [],
        serverError: null,
        isSaving: false,
        rows: {},
      },
      confirmArchiveProductModal: {
        skus: [],
        willArchive: true,
      },
      addPurchaseOrderCommentModal: {
        poID: '',
        comment: '',
        isAdding: false,
        serverError: null,
      },
      editInstructionsModal: {
        poID: '',
        instructions: '',
        isSaving: false,
        serverError: null,
      },
    },
    forms: {},
    dataImport: {
      upload: {
        isDragHover: false,
        error: '',
      },
      current: 'Create Addresses',
      columns: [],
      rows: [],
      data: [],
      isProcessingCSV: false,
      isValidatingData: false,
      isSaving: false,
      stopBlock: false,
      columnsToDataMap: {},
      columnErrors: {},
      currentDataIndex: 0,
      willShowOnlyErrors: true,
      willStopSavingOnErrors: true,
    },
    settings: {
      currentSettingsPanel: 'general',
      accounting: {
        editID: null,
      },
      users: {
        isSaving: false,
        isChangingPassword: false,
        editLink: '',
        isNew: false,
        serverError: null,
        hasChanged: {},
        userForm: {
          link: '',
          name: '',
          email: '',
          password: '',
          passwordConfirm: '',
          warehouseIDs: [],
          permissions: {},
        },
      },
      shippers: {
        editID: '',
        forms: {},
        hasChanged: {},
      },
      payments: {
        editID: null,
      },
    },
    dashboard: {
      showSetupGuide: true,
    },
    tasks: {
      lastViewedTime: null,
      exportsClearedTime: null,
    },
  },
}
